.language-selector {
    position: relative;
    display: inline-block;
    font-family: Arial, sans-serif;
  }
  
  .language-selector-button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.2s, box-shadow 0.2s;
  }

  .language-selector-button {
    color: #00316f;
  }
  
  .language-selector-button:hover {
    background-color: #f5f5f5;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .language-dropdown {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    min-width: 160px;
    overflow: hidden;
  }
  
  .language-option {
    display: flex;
    align-items: center;
    padding: 10px 14px;
    cursor: pointer;
    font-size: 0.95rem;
    color: #333;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .language-option:hover {
    background-color: #eaeaea;
    color: #000;
  }
  
  .language-option.selected {
    font-weight: bold;
    background-color: #f8f8f8;
  }
  
  .flag-icon {
    margin-right: 10px;
    width: 20px;
    height: 15px;
    display: inline-block;
  }
  
  .current-language {
    margin-left: 8px;
    font-size: 1rem;
    color: #555;
  }
  