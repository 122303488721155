.encryption-options {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.encryption-card {
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
  width: 200px;
}

.encryption-card:hover {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
}

.selected {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.2);
}

.encryption-options {
  display: flex;
  gap: 20px;
}

.encryption-card {
  border: 2px solid #ccc;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  width: 100%;
  transition: 0.3s;
}

.encryption-card.selected {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
  color: #ffffff !important;
}

.encryption-card p.selected {
  border-color: #007bff;
  background-color: rgba(0, 123, 255, 0.1);
  color: #ffffff !important;
}


.encryption-card h3 {
  font-size: 1.3rem;
  margin-bottom: 10px;
}

.encryption-card p {
  margin-bottom: 10px;
}

.custom-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.custom-list li {
  display: flex;
  align-items: center;
  font-size: 1rem;
  margin-bottom: 8px;
  padding: 6px 0;
}

.icon {
  margin-right: 10px;
  color: #007bff;
}

.warning {
  color: #ff9800;
}

.disabled {
  color: #aaa;
}

.coming-soon {
  font-size: 0.85rem;
  color: #ff5722;
  font-weight: bold;
}
