/* Background styles that can be added without affecting existing classes */
.bg-dark.skewed-section#api-integrations {
    position: relative;
    overflow: hidden;
  }
  
  .bg-dark.skewed-section#api-integrations::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      135deg,
      rgba(41, 0, 102, 0.9) 0%,
      rgba(0, 49, 111, 0.9) 50%,
      rgba(0, 87, 158, 0.9) 100%
    );
    z-index: -2;
  }
  
  .bg-dark.skewed-section#api-integrations::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at 1px 1px, rgba(255, 255, 255, 0.2) 3px, transparent 0);
    background-size: 24px 24px;
    opacity: 0.5;
    z-index: -1;
  }

 .node-connections {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* Remove or comment out the temporary background */
  /* background-color: rgba(0, 0, 0, 0.1); */
  z-index: 0; /* Adjusted to be above ::after */
  pointer-events: none;
}

.bg-dark.skewed-section#api-integrations::after {
  z-index: -1; /* Ensure this is below the canvas */
}
