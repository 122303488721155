.newClinic {
  margin-top: 1rem;
  margin-left: 2rem;
}

.clinicsList {
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-x: auto;
}

.url-container {
  display: flex;
  align-items: center;
  padding: 2px;
}

#clinicNameURL {
  border: none; /* To remove default input borders */
  outline: none; /* To remove the default focus outline */
  flex: 1; /* To take remaining space in the flex container */
}

.addClinicButton {
  padding: 10px;
  border: none;
  width: 100%;
  background-color: #00316f;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  min-width: 8rem;
  display: flex;
  align-items: left;
  margin-top: 6px;
  margin-bottom: 20px;
  margin-left: 0;
  margin-right: 0;
}

.stepperContainerClinics {
  padding-top: 2rem;
}

.selectedSlot {
  border: 3px solid black;
  padding: 5px;
  display: inline-block;
  border-radius: 10px;
}

.continueButton {
  font-weight: 800;
  margin-left: 10px;
  cursor: pointer;
}

.clinicAddModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f1f1f1;
  padding: 2rem;
  border-radius: 16px;
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  width: 80vw;
  max-height: 80vh;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  color: #f1f1f1 !important;
  background-color: #00316f !important;
  margin-left: 10px !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
  margin-left: auto !important;
}

.MuiButtonBase-root .Mui-disabled {
  background: none !important;
  border: none !important;
  cursor: pointer !important;
  color: #00316f !important;
  background-color: #f1f1f1 !important;
  margin-left: 10px !important;
  box-shadow: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border-radius: 10px !important;
}

.Mui-active {
  color: #00316f !important;
}

.Mui-completed {
  color: #00316f !important;
}

.TitleInput {
  /* display: none !important; */
}

.FormCanvasContainer {
  margin-right: 20px !important;
}

.step-content {
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 1rem;
}

.intake .FormCanvasContainer {
  width: 100% !important;
  margin-right: 20px;
  margin-left: 20px
}

.intake .PaletteContainer {
  margin-top: 15px !important
} 

.PaletteContainer {
  height: 61.803vh;
  overflow: scroll;
}

.checkbox-row {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-row label {
  margin-right: 10px;
}

.appointments-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.enable-appointments {
  grid-column: 1 / -1;
}

.time-zone {
  grid-column: 1 / span 2;
}

.working-hours {
  grid-column: 1 / span 2;
}

.working-hours h3 {
  margin-bottom: 10px;
}

.day-hours {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
}

.day-label {
  display: inline-block;
  width: 10rem;
  margin-right: 10px; 
}
.custom-checkbox {
  margin-right: 10px;
}

.appointment-types {
  grid-column: 3 / -1;
}

.appointment-type {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}



.delete {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.add-appointment-type {
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
}


.form-label {
  display: block;
  margin-bottom: 5px;
}

.form-input {
  width: 10rem;
}

.company-name-placeholder {
  display: flex;
  font-size: 24px;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 2px solid #ccc;
  border-radius: 8px;
  background-color: #f8f9fa;
  font-weight: bold;
  color: #333333;
  margin-bottom: 20px;
  text-align: center;
}

.text-center-payer {
  text-align: center;
}

.twocolumngrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

@media (max-width: 768px) {
  .twocolumngrid {
    grid-template-columns: 1fr;
  }
}

/* Dropdown container */
.customDropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown button */
.customDropdownButton {
  background-color: #00316f;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px; /* Spacing between icons */
}

.customDropdownButton:hover {
  background-color: #0147df;
}

/* Dropdown content */
.customDropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 150px;
  border-radius: 5px;
  overflow: hidden;
}

/* Dropdown items */
.customDropdownContentButton {
  background: none;
  border: none;
  padding: 10px 15px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.customDropdownContentButton:hover {
  background-color: #f1f1f1;
  color: #0147df;
}

/* Show the dropdown content when hovering */
.customDropdown:hover .customDropdownContent {
  display: block;
}

@media (max-width: 708px) {
  .mobileClinicRow {
    display: flex;
    flex-direction: column;
    gap: 8px;
    /* additional mobile styling */
  }
}

.clinicNameMobile {
  font-size: 18px;
}