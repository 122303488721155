/* Background container with grid */
.encrypted-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -10;
    pointer-events: none;
    overflow: hidden;
    padding: 0.25rem;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(48ch, 1fr));
    grid-auto-rows: min-content;
    gap: 0;
    font-family: 'Courier New', Courier, monospace;
    font-size: 14px;
    line-height: 1;
  }
  
  /* Individual hash styling */
  .encrypted-background span {
    color: #00ff6a;
    text-shadow: 0 0 2px #00ff6a, 0 0 2px #00ff6a;
    opacity: 0.6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
    margin: 0;
  }
  
  /* Blue overlay */
  .blue-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 49, 111, 0.8);
    z-index: -5;
  }
  
  /* Content container */
  .contentEncrption {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 100px;
  }
  
  /* HIPAA image */
  .hipaa-image {
    position: absolute;
    bottom: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    z-index: 1;
    opacity: 0.8;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .encrypted-background {
      grid-template-columns: repeat(auto-fill, minmax(32ch, 1fr));
      font-size: 12px;
      padding: 0.125rem;
    }
    
    .contentEncrption {
      padding: 1rem;
    }
  }