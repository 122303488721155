/* Website.css */

body {
  overflow-x: hidden;
  max-width: 100%;
  margin: 0px;
}

header {
  background-color: white;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 6rem;
  border-bottom: none;
}

/* Full container class */
.container {
  width: 80%;
  /* Adjust this value as needed */
  max-width: 1140px;
  /* Adjust this value as needed */
  padding: 1.5rem;
  margin: auto;
}

/* Full width class */
.full-width {
  width: 100%;
  padding: 0;
  /* Remove padding if needed */
  margin: 0;
  /* Remove margin if needed */
}

.content-container {
  display: flex;
  height: 100%;
  margin-top: 3rem;
}

.features-content-container {
  margin-top: 0rem;
}

.landing-page {
  display: flex;
  flex-direction: column;
}

.nav-links {
  display: flex;
  justify-content: right;
  list-style: none;
  margin-top: -2.75rem;
  padding: 0;
}

.nav-links li a {
  color: #f7f7f7;
  display: flex;
  padding: 0.5rem;
  text-decoration: none;
  right: 0;
  font-weight: 100;
}


.hero {
  padding: 4rem 1rem;
  text-align: center;
  align-items: center;
}

.hero h1 {
  color: #543f86;
  font-size: 50px;
}

.hero p {
  color: #ffffffc0;
  font-size: 20px;
}

.boxes {
  display: flex;
  justify-content: space-around;
  padding: 2rem 1rem;
  text-align: center;
}

.box {
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  padding: 1.5rem;
  width: 45%;
}

/* Website.css */
.login-btn {
  background-color: white;
  border: 1px solid #00316f;
  border-radius: 25px;
  color: #00316f;
  padding: 0.5rem;
  padding-bottom: .6rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  font-weight: 700;
}

.signup-btn {
  border: none;
  border-radius: 25px;
  color: white;
  padding: 0.5rem;
  font-weight: 800;
  background-color: #00316f;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  white-space: nowrap;
  text-decoration: none;
  list-style: none;
  font-size: 14px;
  align-items: center;
  min-width: 8rem;
  text-align: center;
}

.signup-btn-header {
  border: none;
  border-radius: 25px;
  color: white;
  padding: 0.5rem;
  font-weight: 700;
  background-color: #00316f;
  margin: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  white-space: nowrap;
  text-decoration: none;
  list-style: none;
  font-size: 14px;
  align-items: center;
  display: flex;
}

.signup-btn:hover,
.signup-btn-header:hover,
.login-btn:hover {
  background-image: linear-gradient(135deg, #00316f, #0147df);
  color: white;
}

.icon {
  margin-right: 5px;
}

.clinics-sidebyside {
  display: flex;
  flex-direction: row;
}

.center-login-btn {
  display: absolute;
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0px 0px 0px 0px #000000;
  margin-left: 0rem;
  margin-top: 2rem;
}

.center-login-btn:hover {
  background-color: #000000;
  color: #ffffff;
}

.logoHome {
  align-items: center;
  height: 32px;
  width: auto;
  margin-top: 10px;
}

.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.privacy-policy-container h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.privacy-policy-container h2 {
  font-size: 1.8rem;
  margin-top: 40px;
  margin-bottom: 20px;
}

.privacy-policy-container p {
  font-size: 1.2rem;
  line-height: 1.5;
  margin-bottom: 20px;
}

.privacy-policy-container ul {
  list-style: disc;
  margin-left: 20px;
}

.privacy-policy-container a {
  color: #543f86;
  text-decoration: underline;
}

@media (max-width: 600px) {
  .privacy-policy-container h1 {
    font-size: 2rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.5rem;
  }

  .privacy-policy-container p {
    font-size: 1rem;
  }
}

.container {
  margin: 0 auto;
  max-width: 800px;
  padding: 40px;
}

ul {
  margin-bottom: 16px;
  margin-left: 5%;
}

strong {
  font-weight: bold;
}

.support-container-image {
  max-width: 600px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 10vh;
}

.support-container input,
.support-container select,
.support-container textarea {
  width: 70%;
  padding: 0.618em;
  margin-bottom: 1.5em;
  font-size: 1em;
  border: 1px solid #ccc;
}

.support-container button {
  background-color: #543f86;
  color: white;
  border: none;
  padding: 0.8em 1.5em;
  font-size: 1em;
  cursor: pointer;
}

.support-container button:hover {
  background-color: #1e62d0;
}

.block {
  background-color: white;
  padding: 2rem 1rem;
  text-align: center;
  height: auto;
}

.websiteblock {
  margin-left: auto;
  margin-right: auto;
  width: 61.8vw;
  /* width: 980px; */
  padding-top: 60px;
  padding-bottom: 60px;
}

.pricing-block {
  padding: 50px 0;
  background-color: #f8f8f8;
  /* You can change the background color to your preference */
}

.videoSection {
  position: relative;
}

.fullBleedVideo {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.desktopVideo {
  display: block;
}

.mobileVideo {
  display: none;
}

/* Media query for screens less than or equal to 768px */
@media (max-width: 1150px) {

  .section-features {
    width: 90% !important;
    padding: 0px !important;
    /* margin: 10px; */
  }

  .plan-container {
    width: 90% !important;
    padding: 0px !important;
  }

  .plan-card {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .tile-content-image {
    min-height: 700px;
  }

  .typography-headline {
    font-size: 32px !important;
  }

  .desktopVideo {
    display: none;
  }

  .mobileVideo {
    display: block;
  }
}

@media (max-width: 1350px) {
  .cta-buttons {
    flex-direction: column;
    gap: 10px;
  }
}

.laptopVideo {
  width: 65%;
  height: 100%;
  object-fit: cover;
}

.videoHeaderClaims {
  margin-bottom: 1rem;
}

.heroLanding {
  /* padding-left: 100px;
  padding-right: 100px; */
  width: 61.8%;
  margin: auto;
}

.heroTextDemo {
  position: absolute;
  bottom: -85%;
  left: 20%;
  width: 88vh;
  text-align: left;
  color: #ffffff;
}

.heroText {
  text-align: center;
  /* width: 100%; */
}

.heroText h1 {
  font-weight: lighter;
  margin-bottom: 1rem;
}

.heroText h3 {
  font-weight: lighter;
}

.prooflogo {
  width: auto;
  height: 3rem;
  /* Adjust the height as needed */
  margin-right: 4rem;
  /* Adjust the spacing between logos */
  margin-top: 1rem;
}

.containerHex {
  padding: 2rem 1rem;
  text-align: center;
  height: auto;
}

.title {
  display: block;
  text-align: center;
  font-size: 25px;
  margin-bottom: 60px;
  /* Separate top and bottom margins */
  font-weight: 600;
  padding: 16px;
}

.blue-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  border-radius: 20px;
  text-align: left;
}

.left-content {
  color: white;
  padding-right: 0%;
  padding-left: 2%;
  padding-top: 0%;
  width: 50%;
}

.right-content {
  padding-right: 2%;
  padding-left: 2%;
  padding-bottom: 1rem;
  padding-top: 0%;
  width: 50%;
}

.Home_cloud__bvdCQ {
  height: 30rem;
}

.block-tall h2 {
  font-size: 36px;
  margin-bottom: 20px;
}

.block-tall p {
  font-size: 24px;
  margin-bottom: 40px;
}

.join-now-button {
  background-color: white;
  color: #00316f;
  border: none;
  padding: 100px;
  font-size: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.block-content {
  text-align: center;
  margin-bottom: 70px;
}

.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10%;
  margin-right: 10%;
}

.icon-container>div {
  text-align: center;
  margin: 0 1rem;
}

.icon-container>div>h3 {
  margin-top: 24px;
}

.icon-container>div>p {
  margin-top: 10px;
  font-size: 18px;
  color: #666;
}

.icon-container>div>svg {
  width: 50px;
  height: 50px;
  color: #543f86;
}

.pricing-table p {
  text-align: center;
  font-size: 14px;
  margin-bottom: 10px;
  opacity: 0.5;
}

.pricing-table h1 {
  text-align: center;
  margin-bottom: 20px;
}

.pricing-table th,
.pricing-table td {
  border: 0px solid rgba(204, 204, 204, 0.5);
  padding: 15px;
}

.thread {
  background-color: #007bff00;
}

.pricing-table {
  font-family: Arial, sans-serif;
  padding: 0rem;
  position: block;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: 0;
  width: 100%;
  padding: 0;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: 1200px;
}

.plan-card {
  background-color: #00316f;
  padding: 10px;
  color: #fff;
}


.plan-card ul {
  color: #fff;
  font-weight: bold;
}

.plan-card ul li {
  margin-bottom: 0.5rem;
  color: #f1f1f1;
  font-size: 0.9rem;
}

.plan-description {
  color: #fff;

  height: 7rem;
  overflow: hidden;
}

.price {
  color: #f1f1f1;
}

.pricing-table p {
  color: #f1f1f1;
}

.pricing-table button {
  color: #00316f;
  background-color: #fff;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 20px;
  box-shadow: none;
  position: relative;
}

.pricing-table p {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  opacity: 0.5;
}

.pricing-table h1 {
  text-align: center;
  margin-bottom: 40px;
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-spacing: 0;
  border: none;
}

.pricing-table thead th {
  font-weight: bold;
}

.pricing-table button {
  color: #543f86;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 20px;
  box-shadow: none;
  position: relative;
}

.pricing-table button:hover {
  background-color: #0056b3;
  color: white;
  text-decoration: none;
}

.pricing-table thread td:first-child,
.pricing-table tbody td:first-child {
  font-weight: bold;
  text-align: right;
}

.pricing-table thead th p {
  font-size: 18px;
  font-weight: 500;
  display: inline;
  margin-bottom: 10px;
}

/* Infill the button in column 3 */
.pricing-table thead th:nth-child(3) button {
  background-color: #00316f;
  color: white;
}

.pricing-table thead th:nth-child(3) button:hover {
  background-color: #ffffff;
  color: #00316f;
}

.pricing-table .table-container {
  overflow-x: hidden;
  position: relative;
  width: 100%;
}

.pricing-table .price {
  font-size: 50px;
  font-weight: 250;
  margin-top: 10px;
  justify-content: left;
}

.pricing-table .month {
  font-size: 18px;
  opacity: 0.5;
  margin-left: 5px;
}

.pricing-table .price-container {
  margin-top: 20px;
}

.footer {
  background-color: #00316f;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  flex-wrap: wrap;
  width: 100%;
}

.logo-container {
  margin-right: 1rem;
}

.logoFooter {
  height: 2rem;
  align-items: center;
}

.HIPAA {
  height: 100px;
  margin-top: 100px;
}

.footer-links {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: 100px;
  color: #f8f8f8;
}

.footer-links li {
  margin-right: 1rem;
}

.all-rights-reserved {
  margin-top: 1rem;
  text-align: center;
  flex-basis: 100%;
}

.join-now-button {
  display: inline-block;
  padding: 15px;
  border-radius: 10px;
  text-decoration: none;
}

.block .blue-block .right-content .ai-screenshot {
  max-width: 100%;
  height: auto;
  display: block;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  /* Adds a shadow effect */
  border-radius: 10px;
  /* Rounds the corners of your image */
  transition: transform 0.2s;
  /* Animation for hover effect */
}

.try-gpt-box {
  padding-left: 1rem;
  margin-left: 1rem;
  padding-right: 1rem;
  margin-right: 1rem;
}

.block .blue-block .right-content .try-gpt-box .ai-screenshot:hover {
  transform: scale(1.2);
  /* Slightly enlarges the image when hovered */
}

.centered-text {
  text-align: center;
}

.bigger-image {
  width: 100%;
  height: auto;
  margin: 0 auto;
  /* Add auto margins to center the image block */
}

.patientsImage {
  display: flex;
  width: 100%;
  justify-content: center;
  /* Add this line to center the image */
}

.differentBlock {
  background: linear-gradient(135deg, #00316f, #0147df);
  text-align: left;
  padding: 200px;
  margin: 200px;
  border-radius: 20px;
}

.AMA {
  height: 5rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
}

.payerSelectorContainer {
  width: 100%;
  height: 100%;
}

.ballContainer {
  width: 100%;
  height: 100%;
}

.payer-content {
  text-align: left;
  width: 100%;
}

.full-bleed {
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.leftTele {
  width: 100%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
  .leftTele {
    width: 90%;
    margin-left: 5%;
  }
}

.teleBlueBox {
  position: relative;
  align-items: center;
  justify-content: center;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  border-radius: 10px;
  margin: 0;
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
}

.videoHeaderClaims {
  margin-left: 5%;
  margin-right: 5%;
}

.tele-text-content {
  display: flex;
  text-align: left;
  width: 50%;
  margin-bottom: 1rem;
}

.teleText {
  margin-left: 5%;
}

.payerText {
  margin-right: 10%;
}

.full-bleed-codes {
  width: 30%;
  height: auto;
}

.codes-content {
  text-align: left;
  right: 0;
}

.codesText {
  margin-right: 20%;
  margin-left: 10%;
}

.codeSearch {
  margin-left: 10%;
  justify-content: center;
  height: 20rem;
  padding-left: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
}

.codeHeader {
  text-align: left;
}

.logo-container {
  display: flex;
  align-items: center;
}

.zoomlogo {
  width: 8rem;
  height: auto;
  margin-right: 2rem;
  border-radius: 0.5rem;
}

.vertical-line {
  width: 1px;
  height: 5rem;
  background-color: black;
  margin-right: 1rem;
  margin-left: 1rem;
}

.clinics {
  width: 25%;
  margin-right: 10rem;
  display: flex;
  object-fit: cover;
}

.clinicsLaptop {
  width: 75%;
}

.clinicsBlock {
  background-color: white;
  text-align: center;
  height: auto;
  display: flex;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 3rem;
}

.clinicText {
  padding-right: 2rem;
  text-align: left;
}

.Support-featured-articles-container {
  margin-left: 0% !important;
  padding: 0rex !important;
}

.FAQ-container {
  margin-left: 5%;
  margin-right: 5%;
  padding: 3rex;
  border-radius: 10px;
}

.FAQ-container h1 {
  text-align: left;
  margin-bottom: 20px;
}

.FAQ-container h2 {
  cursor: pointer;
  margin: 10px;
  /* margin-bottom: 28px; */
  text-align: left;
  font-weight: 300;
  margin: 15px 50px 15px;
}

.FAQ-container p {
  margin: 10px 0;
  margin-left: 1.5rem;
  padding: 10px;
  border-radius: 10px;
  text-align: left;
  font-size: 18px;
}

.Features-container {
  margin-left: 5%;
  margin-right: 5%;
  padding: 20px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  border-radius: 1rem;
  background-color: #f5f5f5;
}

.Features-container h1 {
  text-align: center;
  margin-bottom: 20px;
  border-radius: 1rem;
}

.Features-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.Features-item {
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.websiteICD10 {
  display: flex;
  margin-left: 0rem;
}

.footer {
  background-color: #00316f;
  color: #ffffff;
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.logo-container {
  margin-bottom: 2rem;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0 0 2rem 0;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.footer-link {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.create-account-button {
  border: 2px solid white;
  color: white;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  top: 10px;
  right: 10px;
  margin-bottom: 2rem;
  border-radius: 0.5rem;
}

.create-account-button:hover {
  background: linear-gradient(135deg, #00316f, #0147df);
}

.footer {
  position: relative;
}

.account-button-container {
  position: absolute;
  bottom: 0;
  right: 0;
}

.footer-link:hover {
  color: #0147df;
}

.all-rights-reserved {
  font-size: 0.8rem;
}

.footer .social-links {
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  padding: 10px;
  gap: 1.5rem;
}

.footer .social-links a {
  color: white;
  font-size: 24px;
  transition: color 0.5s;
}

.footer .social-links a:hover {
  color: #ddd;
}

.modal {
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  /* Black with opacity */
}

.modal-content {
  background-color: #fefefe;
  margin-left: 35%;
  margin-top: 2rem;
  margin-right: 35%;
  position: fixed;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #888;
  width: 80%;
  /* Could be more or less, depending on screen size */
  overflow-y: auto;
  height: 80%;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  flex: 1 0 auto;
  margin-bottom: 50px;
  /* adjust as needed */
}

/* General styles for the select dropdown */
select {
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  appearance: none;
  -webkit-appearance: none;
  /* Override default select box in webkit browsers */
  -moz-appearance: none;
  /* Override default select box in Mozilla Firefox */
  cursor: pointer;
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

/* Styles for the textarea */
#message {
  width: 100%;
  /* Full width of the container */
  font-size: 16px;
  border: 1px solid #ccc;
  /* Light gray border */
  border-radius: 10px;
  /* Slight roundness */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Subtle box shadow for depth */
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
  /* Smooth transition for hover and focus effects */
  resize: vertical;
  /* Allows user to resize textarea vertically, not horizontally */
  background-color: #ffffff;
  line-height: 1.5;
  /* Spacing between lines */
  color: #000000;
}

/* Hover effect for textarea */
#message:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* Slightly more pronounced shadow on hover */
}

/* Focus effect for textarea */
#message:focus {
  box-shadow: 0 0 0 3px rgba(100, 150, 255, 0.5);
  /* Blue shadow for accessibility */
  outline: none;
  /* Remove default outline in some browsers */
  border-color: #0147df;
  /* Matching blue border color */
}

/* If you want a specific style for when the textarea is in a "required" state and empty (and invalid) */
#message:required:invalid {
  border-color: #e74c3c;
  /* Red border */
  box-shadow: none;
  /* Remove shadow */
}

.ln-content {
  height: 100%;
  width: 100%;
  max-width: 67vw;
  margin: 0 auto;
  padding: 0 22px;
  position: relative;
  z-index: 2;
}

.logoContainerWebsite {
  margin-top: -0.4rem;
  left: 0;
}

.menu-Tray {
  float: right;
  padding-top: 8px;
  position: sticky;
  top: 0;
}

.menu-Items {
  letter-spacing: inherit;
  margin-top: 0.5rem;
  font-size: 12px;
  display: flex;
}

.menu-Item {
  margin-left: 24px;
  float: left;
  list-style: none;
  white-space: nowrap;
}

.menu-link {
  color: #000000;
  display: inline-block;
  line-height: 22px;
  white-space: nowrap;
  opacity: 0.88;
  text-decoration: none;
}

.menu-link:hover {
  color: #0147df;
}

.menu-link.current {
  opacity: 0.55;
}

.hide-logo .logoContainerWebsite {
  display: none;
}

.proof-logo-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1rem;
}

.laptopImage {
  justify-content: center;
  display: flex;
  align-items: center;
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.Glance-Grid-container {
  grid-template-columns: 33.33% 33.33% 33.33%;
  grid-column-gap: 0;
  width: 100%;
  display: grid;
  padding: 0 24px;
  grid-row-gap: 30px;
  max-width: 1380px;
  padding-top: 2rem;
}

.Glance-Grid-item {
  position: relative;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.glance-section-header {
  text-align: center;
}

.grid-p-class {
  font-weight: 600;
  max-width: 50%;
}

.grid-p-class-overhead {
  font-weight: 600;
  max-width: 50%;
  opacity: 75%;
  margin-top: -1rem;
  margin-bottom: 0rem;
}

.prooflogoHIPAA {
  height: 5rem;
}

.section-header-techspecs {
  float: left;
  width: 22%;
}

.tech-specs-column-row {
  width: 75%;
}

.tech-specs-list {
  margin-left: 0;
  list-style: none;
  font-size: 17px;
  margin-top: 0;
}

.section-tech-specs {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #d2d2d7;
  display: flex;
  align-items: flex-start;
}

.glance-countainer {
  padding-top: 60px;
  padding-bottom: 60px;
  border-top: 1px solid #d2d2d7;
}

.section-content {
  margin-left: auto;
  margin-right: auto;
}

.section-headline {
  font-size: 24px;
  line-height: 1.16;
  font-weight: 600;
  letter-spacing: 0.009em;
  margin-top: 0;
  font-family: SF Pro Display, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

.tech-specs-list li:not(:first-child) {
  margin-top: 1rem;
}

.tech-specs-grid {
  grid-template-columns: 43% 43%;
  width: 100%;
  margin-left: 0px;
  float: none;
  padding: 0 24px;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: 1380px;
}

.tech-specs-grid-item {
  background-color: unset;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  margin-right: 0;
}

.tech-specs-grid-3wide {
  grid-template-columns: 29.3% 29.3% 29.3%;
  width: 100%;
  margin-left: 0px;
  float: none;
  padding: 0 24px;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  max-width: 1380px;
}

.globalNav {
  background: rgba(251, 251, 253, 0.8);
}

.globalNav-content {
  display: block;
  width: 100%;
  max-width: 1024px;
  box-sizing: border-box;
  margin: 0 auto;
  margin-bottom: 0;
}

.globalNav-list {
  cursor: default;
  margin: 0 -8px;
  height: 44px;
  display: flex;
  justify-content: space-between;
  -webkit-user-select: none;
  user-select: none;
  list-style: none;
  /* z-index: 9999999; */
}

.globalNav-submenu-item {
  list-style: none;
  height: 54px;
}

.globalNav-link {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.01em;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  height: 44px;
  transition: color 0.32s cubic-bezier(0.4, 0, 0.6, 1);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  white-space: nowrap;
  text-decoration: none;
  background: no-repeat;
  padding: 0 calc(8px / var(--r-globalnav-text-zoom-scale, 1));
}

.local-nav {
  top: 0;
  left: 0;
  width: 100%;
  height: 52px;
  min-width: 1024px;
  z-index: 2;
  overflow: hidden;
  position: sticky;
  margin-top: 0;
  border-bottom: 1px solid #d2d2d7;
}

.local-nav.sticky {
  position: -webkit-sticky;
  /* For Safari */
  position: fixed;
  z-index: 99;
  color: 1px solid #f1f1f1;
  top: 0;
  margin-bottom: 1rem;
  height: 52px;
}

.ln-background {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.ln-background.scrolled {
  background-color: #f1f1f1;
}

.menu-toggle {
  display: none;
  /* Hidden by default */
  position: fixed;
  top: -0.25rem;
  left: 0;
  z-index: 101;
  /* Above the nav layers */
  background-color: #00316f;
  color: #f1f1f1;
  padding: 10px;
  border: none;
  align-items: center;
  justify-content: center;
}

.sign-in-button {
  float: right;
  padding: 0px;
}

.sign-in-button-support {
  float: none;
  width: 6rem;
  margin-left: auto;
  margin-right: auto;
}

.placeholderStyle {
  height: 52px;
}

.features-hero-text {
  padding-bottom: 1rem;
  margin-top: -3rem;
  padding-top: 150px;
  padding-bottom: 216px;
}

.swagger-container {
  display: flex;
  padding: 4%;
  border-radius: 20px;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  box-shadow: 0px 0px 5px 0px #888888;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.typography-specs-subheadline {
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -0.009em;
  text-align: center;
  justify-content: center;
}

.features-content {
  margin-top: 4rem;
}

.AI-header-text {
  margin: 1rem;
  margin-top: -2rem;
  text-align: center;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.AI-subheader-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7rem;
  margin-right: 7rem;
}

.SignInIconButton {
  margin-right: 0.25rem;
}

.websiteblock-black-first-block {
  background-color: #000000;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  padding-top: 60px;
  border-bottom: none;
  border-top: none;
}

.websiteblock-black {
  background-color: #000000;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  padding-bottom: 60px;
  padding-top: 60px;
  border-bottom: none;
  border-top: none;
}

.websiteblock-black-no-padding {
  background-color: #000000;
  color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  width: 980px;
  border-bottom: none;
  border-top: none;
}

.IPA-page {
  background-color: #000000;
  color: #ffffff;
}

.IPA-typography-specs-subheadline {
  font-size: 64px;
  line-height: 1.0625;
  font-weight: 600;
  letter-spacing: -0.009em;
  text-align: center;
  justify-content: center;
  color: #ffffff;
}

.section-IPA-specs {
  padding-bottom: 60px;
  display: flex;
  align-items: flex-start;
  padding-left: 14px;
  padding-right: 14px;
}

.dr-note-block {
  align-items: center;
  text-align: center;
}

.dr-note-input-image {
  display: flex;
  flex-direction: row;
}

.dr-note-image {
  border-radius: 0.5rem;
  width: 100%;
  height: 100%;
}

.dr-note-typingText {
  max-width: 600px;
  /* Adjust as needed */
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-left: 3rem;
}

.dr-note-content {
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
}

.dr-note-typingText pre {
  overflow-x: auto;
  /* Enables horizontal scrolling */
  white-space: pre-wrap;
  /* Ensures long lines wrap if possible */
  word-wrap: break-word;
  /* Breaks long words to prevent overflow */
}

.activitiesImage {
  display: flex;
  max-width: 800px;
  height: 100%;
  justify-content: center;
  /* Add this line to center the image */
  margin-top: 0.5rem;
}

.typography-label-alt {
  font-size: 24px;
  line-height: 1.16666;
  font-weight: 500;
  letter-spacing: 0.009em;
  color: #000000;
  text-align: left;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.typography-label-alt-white {
  font-size: 18px;
  line-height: 1.16666;
  font-weight: 500;
  letter-spacing: 0.009em;
  color: #ffffff;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-label-alt-dark {
  font-size: 24px;
  line-height: 1.16666;
  font-weight: 500;
  letter-spacing: 0.009em;
  color: #000000;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #00316f;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-yellow {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #adb800;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-orange {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #00de9f;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-pink {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #00d1de;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-red {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #586A08;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-blue {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #0147df;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-dark-blue {
  font-size: 48px;
  line-height: 1.08;
  letter-spacing: -0.003em;
  font-weight: 600;
  color: #00316f;
  text-align: left;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.typography-headline-super {
  font-size: 80px;
  line-height: 1.05;
  font-weight: 600;
  letter-spacing: -0.015em;
  text-align: center;
  display: flex;
}

.hero-container {
  align-items: center;
  text-align: center;
}

.heroAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(80vh + 80px);
  position: relative;
  top: -40px;
}

.cta-buttons.hidden {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.cta-buttons.visible {
  opacity: 1;
  visibility: visible;
  animation: fadeIn 3.5s ease;
}

.cta-buttons {
  display: flex;
  margin: 0px;
  flex-direction: row;
  padding-left: 0vw;
  padding-right: 0vw;
  justify-content: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.HistoryImage {
  display: flex;
}

.side-by-side {
  display: flex;
  flex-direction: row;
}

.tile-setup {
  grid-area: setup;
}

.tile-tools {
  grid-area: tools;
}

.tile-patients {
  grid-area: patients;
}

.tile-billing {
  grid-area: billing;
}

.tile-providers {
  grid-area: providers;
  height: 500px;
}

.tile-patientpic {
  grid-area: patientpic;
  height: 500px;
}

.sideByTilesContainer {
  margin-right: 5%;
  margin-left: 5%;

}

.tile-glamour {
  grid-area: glamour;
}

.tile-security {
  grid-area: security;
}

.tile-metal {
  grid-area: metal;
}

.tile-IPA {
  grid-area: IPA;
}

.tile-gateway {
  grid-area: gateway;
}

.tile-continuity {
  grid-area: continuity;
}

.tile-support {
  margin-bottom: 20px;
  margin-top: 20px;
}

.tile-dark {
  color: #f5f5f7;
  background: #00316f;
}

.tile-white {
  background: #f1f1f1;
}

.tile-black {
  color: #f5f5f7;
  background: #000000;
}

.tile {
  --button-size: 40px;
  --button-position: 30px;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.tile-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding: 25px !important;
}

.tile-content-hextials {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  margin-bottom: 0rem;
}

.section-features {
  width: 61.8%;
  max-width: 1140px;
  margin: auto;
}

.tiles-contents {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1390px;
}

.typography-design-headline {
  font-size: 32px;
  line-height: 1.0625;
  font-weight: 800;
  letter-spacing: -0.009em;
  color: #ffffff;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}

.typography-design-headline-dark {
  font-size: 32px;
  line-height: 1.0625;
  font-weight: 800;
  letter-spacing: -0.009em;
  color: #000000;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: left;
}

.features-apps-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Adjust based on your layout */
  gap: 20px;
  /* Adjust the gap as needed */
  justify-items: center;
  align-items: start;
  padding: 0 24px;
  grid-row-gap: 30px;
  max-width: 1380px;
  padding-top: 2rem;
}

.grid-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.patients-made-easy-image {
  width: 90%;
  display: block;
  bottom: 0;
}


.patients-wrapper {
  padding: 0 0px 0px;
  height: 100%;
  width: 100%;
  display: flex;
}

.security-wrapper {
  margin: 25px;
  text-align: center;
}

.metal-wrapper {
  max-width: 700px;
  margin-left: 55px;
}

.split-wrapper {
  max-width: 500px;
}

.providers-wrapper {
  margin-top: 0px;
}

.patientpic-wrapper {
  margin-top: 0px;
}

.glamour-wrapper {
  height: 100%;
}

.messages-wrapper {
  padding-top: 0rem;
}

.support-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.support-icon-features {
  color: #010101;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3rem;
  margin-bottom: 1rem;
}

.split-continuity {
  max-width: 500px;
}

.split-support {
  max-width: 500px;
}

.tile-design {
  padding: 0 85px 613px;
}

.copy-wrapper {
  margin: 0 auto;
}

.macbook-image-wrapper {
  align-items: center;
  display: flex;
  height: 70%;
}

.macbook-corner-image {
  scale: 80%;
  width: 100%;
  padding-left: 30%;
  bottom: 0;
  height: 100%;
  margin: 0 auto;
  display: flex;
}

.full-card-image {
  display: flex;
}

.tile-visible {
  opacity: 1;
}

.footer-dark {
  background-color: #000000;
}

.support-modals {
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin-top: 2rem;
  text-align: center;
}

.featuredArticles-li {
  list-style: none;
  margin-bottom: 0.75rem;
}

.support-button-text {
  cursor: pointer;
}

.ipa-join-button {
  background-color: #ffffff;
  color: #000000;
  width: 5rem;
  border-radius: 20px;
}

.ipa-followup-buttons {
  width: 15rem;
}

.join-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.features-super-header {
  padding-left: 10rem;
}



@media (max-width: 1200px) {

  .heroLanding {
    width: 92%;
  }

  .section-features {
    width: 92%;
  }

  .heroLanding {
    margin-top: 80px;
    /* padding: 15px; */
  }

  .heroText h1 {
    font-size: 32px;
    margin-bottom: 0.5rem;
  }

  .section-content {
    margin-left: auto;
    margin-right: auto;
  }

  

  .heroText h3 {
    font-size: 18px;
    margin-top: 0rem;
  }

  .heroText {
    top: 32%;
    left: 5%;
  }

  .prooflogo {
    height: 1.5rem;
    margin-right: 2rem;
  }

  .box {
    width: 100%;
  }

  .footer-links li {
    margin-bottom: 0.5rem;
  }

  .logoFooter {
    margin-left: 2rem;
  }

  .HIPAA {
    margin-top: 50px;
  }

  .bigger-image {
    width: 100%;
  }

  .content-container {
    display: block;
  }

  .codes-content {
    width: auto;
  }

  .full-bleed-codes {
    width: 100%;
  }

  .payerText {
    margin-left: 1rem;
  }

  .pricing-table .table-container {
    overflow-x: scroll;
  }

  .codeSearch {
    padding: 0%;
  }

  .blue-block {
    display: block;
  }

  .try-gpt-box {
    margin-top: 1rem;
  }

  .clinicsBlock {
    display: block;
  }

  .clinics {
    margin-left: 1rem;
    width: 80%;
  }

  .local-nav {
    min-width: 100px;
  }

  .menu-toggle {
    display: block;
    /* Show toggle button on small screens */
  }

  .heroAnimation {
    display: none;
  }

  .globalNav {
    display: none;
    /* Hide global navigation by default on small screens */
  }

  .mobileMenuOpen .globalNav {
    display: block;
    /* Show when menu is toggled */
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #00316f;
    z-index: 100;
    flex-direction: column;
  }

  .globalNav-list {
    flex-direction: column;
    height: auto;
  }

  .globalNav-link {
    padding: 15px;
    /* Larger hit area for links */
    color: #000000;
    border-top: 1px solid #000000;
    font-size: 18px;
  }

  .Glance-Grid-container {
    grid-template-columns: 43% 48%;
    grid-column-gap: 20px;
    text-align: center;
    grid-row-gap: 20px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }

  .glance-section-header {
    margin-bottom: 30px;
  }

  .glance-p {
    width: 100%;
    text-align: center;
  }

  .section-tech-specs {
    flex-direction: column;
    padding-top: 45px;
    padding-bottom: 0px;
  }

  .tech-specs-grid-3wide {
    padding: 0;
    width: auto;
  }

  .websiteblock {
    width: 87.5%;
    margin-left: auto;
    margin-right: auto;
  }

  .typography-headline-super {
    font-size: 48px;
  }

  .clinics-sidebyside {
    flex-direction: column;
  }

  .side-by-side {
    flex-direction: column;
  }

  .menu-Item {
    margin-left: 14px;
  }

  .menu-Items {
    margin-right: 1rem;
  }

  .ln-content {
    max-width: fit-content;
  }

  .tech-specs-grid {
    width: auto;
  }

  .typography-specs-subheadline {
    font-size: 48px;
  }

  .tech-specs-list {
    margin-left: -5%;
  }

  .AI-header-text {
    margin: 0;
  }

  .AI-subheader-text {
    margin: auto;
  }

  .try-gpt-box {
    padding: 0rem;
    margin: 0rem;
  }

  .websiteblock-black-first-block {
    width: auto;
    padding: 1rem;
  }


  .features-super-header {
    padding-left: 2rem;
  }
}

@media (max-width: 480px) {
  header {
    width: 100%;
    max-height: 6rem;
  }

  .nav-links li a {
    padding: 0.5rem;
    margin-right: 0.5rem;
  }

  .hero h1 {
    font-size: 25px;
  }

  .hero p {
    font-size: 14px;
  }

  .box {
    width: 100%;
  }

  .privacy-policy-container h1 {
    font-size: 1.5rem;
  }

  .privacy-policy-container h2 {
    font-size: 1.2rem;
  }

  .privacy-policy-container p {
    font-size: 0.8rem;
  }

  .footer-links {
    margin-right: 0px;
  }

  .logoFooter {
    margin-bottom: 2rem;
  }

  .HIPAA {
    margin-top: 50px;
  }

  .bigger-image {
    width: 100%;
  }

  .payerSelectorContainer {
    margin-left: 0;
  }

  .zoomlogo {
    width: 7rem;
    margin-right: -2rem;
  }

  .icon-container {
    display: contents;
  }

  h1 {
    font-size: 40px;
  }

  .AMA {
    height: 6rem;
    margin-bottom: 0rem;
  }

  .logo-container {
    display: flex;
    gap: 2rem;
    margin-bottom: 0rem;
    flex-direction: row;
  }

  .vertical-line {
    width: 0;
    height: 1rem;
  }

  .blue-block {
    display: block;
    margin-left: 0.5%;
    margin-right: 0.5%;
  }

  .try-gpt-box {
    margin-top: 1rem;
  }

  .right-content {
    padding-left: 0.5%;
    padding-right: 0.5%;
  }

  .hero {
    padding: 2rem 1rem;
    text-align: center;
  }

  .clinicsBlock {
    display: block;
  }

  .clinics {
    width: 80%;
  }

  .clinicText {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
    margin-bottom: 2rem;
  }

  .codesText {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .teleBlueBox {
    margin-bottom: 5%;
    width: 100%;
    margin-left: 0%;
    flex-direction: column;
  }

  .payerContainer {
    width: auto;
  }

  .create-account-button {
    position: block;
    margin-right: 0rem;
  }

  .codeSearch {
    height: 25rem;
  }

  .dr-note-content {
    flex-direction: column;
  }

  .glanceIcons {
    height: 40px !important;
  }

  .prooflogoHIPAA {
    height: 4rem !important;

  }
  .grid-p-class {
    max-width: 90%;
  }

  .tech-specs-grid{
    padding: 0px;
    grid-template-columns: 100%;

  }

  .tech-specs-grid-3wide {
    grid-template-columns: 100%;
  }
}

.logoContainerWebsite {
  margin-top: 1rem;
}


.loading-circle-container {
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 63vh;
  /* Adjust to make sure it centers vertically */
}

.fadeInText {
  animation: fadeIn 2s forwards;
  /* Animation to fade in */
}

.fadeOutText {
  animation: fadeOut .5s forwards;
  /* Animation to fade out */
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.welcomeText {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  margin-top: 4rem;
}

.background-search-container {
  position: relative;
}

.background-container canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  /* Ensure the canvas is behind other elements */
  width: 100%;
  height: 100%;
}

.demoForm {
  padding-top: 1rem;
}

.menu-toggle {
  display: none;
}

.menu-Tray {
  display: flex;
  justify-content: flex-end;
}

.globalNav-list {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.globalNav-submenu-item {
  margin-left: 1rem;
}

.globalNav-link {
  text-decoration: none;
  color: inherit;
}

.mobile-menu {
  display: none;
}

@media (max-width: 1200px) {
  .menu-toggle {
    display: block;
    display: flex;
    gap: 10px;
  }

  .desktop-nav {
    display: none;
  }

  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-200%);
    transition: transform 0.3s ease-in-out;
    z-index: 999;
  }

  .mobile-menu.open {
    transform: translateY(0);
  }

  .mobile-nav {
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    padding: 2rem;
    border-radius: 0.5rem;
    width: 100%;
    max-width: 400px;
    position: relative;
  }

  .globalNav-submenu-item {
    margin: 1rem 0;
  }

  .menu-toggle-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 1.5rem;
    color: #333;
    background: none;
    border: none;
    cursor: pointer;
  }

}

.claims-page {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: #1d1d1f;
  background-color: #f5f5f7;
  -webkit-font-smoothing: antialiased;
}

.golden-container {
  width: 980px;
  margin: 0 auto;
  padding: 0 22px;
}

.hero-section {
  background-color: #ffffff;
  color: #00316f;
  padding-bottom: 2%;
  text-align: center;
}

.hero-section-sub-page {
  background-color: #00316f;
  color: #ffffff;
  padding: 120px 0;
  text-align: center;
}

.hero-title-main {
  font-size: 72px;
  line-height: 1.2;
  /* Increased line height */
  font-weight: 600;
  letter-spacing: -.005em;
  margin-bottom: 0px;
  background: linear-gradient(135deg, #00316f, #0147df);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* Fallback for browsers that don't support background-clip */
  color: #00316f;
  margin-top: 0px;
}

.hero-title {
  font-size: 56px;
  line-height: 1.2;
  /* Increased line height */
  font-weight: 600;
  letter-spacing: -.005em;
  margin-bottom: 20px;
  background: #00316f;
  color: #ffffff;
  margin-top: 10px;
}

.hero-text {
  padding-bottom: 1rem;
  padding-left: 10vw;
  padding-right: 10vw;
  display: flex;
  justify-content: center;
}


.hero-subtitle {
  font-size: 28px;
  line-height: 1.10722;
  font-weight: 400;
  letter-spacing: .004em;
  max-width: 800px;
  margin: 0 auto 40px;
}



.hero-disclaimer {
  color: rgb(204, 201, 201);
  font-size: 14px;
  font-style: italic;

}

.hero-disclaimer-container {
  position: relative;
  width: 100%;
  text-align: center;
}

.cta-button {
  display: inline-block;
  background-color: #f1f1f1;
  /* Changed to white */
  color: #00316f;
  /* Text color changed to dark blue */
  font-size: 17px;
  line-height: 1.17648;
  font-weight: 600;
  letter-spacing: -.022em;
  padding: 12px 24px;
  border-radius: 980px;
  text-decoration: none;
  transition: all 0.3s ease;
  min-width: 170px;
}

.cta-button:hover {
  background-color: #f5f5f7;
  transform: scale(1.05);
}

.section-title {
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -.003em;
  margin-bottom: 20px;
  text-align: center;
}

.section-description {
  font-size: 21px;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: .011em;
  margin-bottom: 40px;
  text-align: center;
}

.payer-selector-wrapper {
  max-width: 680px;
  margin: 0 auto;
}

.process-section {
  background-color: #fafafa;
}

.process-steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 60px;
}

.step {
  flex-basis: calc(25% - 20px);
  text-align: center;
  margin-bottom: 40px;
}

.step-3 {
  flex-basis: calc(33% - 20px);
  text-align: center;
  margin-bottom: 40px;
}

.step-number {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #00316f;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.step-number-claims {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  background-color: #00316f;
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0px;
  text-align: center;
}

.step-title {
  font-size: 24px;
  line-height: 1.16667;
  font-weight: 600;
  letter-spacing: .009em;
  margin-bottom: 10px;
  color: #1d1d1f;
}

.step-description {
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  color: #86868b;
}

.step-title-dark {
  font-size: 24px;
  line-height: 1.16667;
  font-weight: 600;
  letter-spacing: .009em;
  margin-bottom: 10px;
  color: #ffffff;
}

.step-description-dark {
  font-size: 18px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  color: #ffffff;
}


.feature.reverse {
  flex-direction: row-reverse;
}

.feature-content {
  flex: 1;
  padding-right: 60px;
}

.feature.reverse .feature-content {
  padding-right: 0;
  padding-left: 60px;
}

.feature-title {
  font-size: 40px;
  line-height: 1.1;
  font-weight: 600;
  letter-spacing: 0;
  margin-bottom: 20px;
  color: #1d1d1f;
}

.feature-description {
  font-size: 21px;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: .011em;
  margin-bottom: 20px;
  color: #86868b;
}

.feature-list {
  list-style-type: none;
  padding: 0;
}

.feature-list li {
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  color: #1d1d1f;
}

.feature-list li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #00316f;
  font-weight: 600;
}

.feature-image {
  flex: 1;
}

.feature-img {
  max-width: 100%;
  border-radius: 18px;
}

.cta-section {
  background-color: #00316f;
  color: #f5f5f7;
  padding: 100px 0;
  text-align: center;
}

.cta-title {
  font-size: 48px;
  line-height: 1.08349;
  font-weight: 600;
  letter-spacing: -.003em;
  margin-bottom: 20px;
}

.cta-description {
  font-size: 21px;
  line-height: 1.381;
  font-weight: 400;
  letter-spacing: .011em;
  margin-bottom: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(20px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

.hero-title.animate,
.hero-subtitle.animate,
.cta-button.animate,
.section-title.animate,
.section-description.animate,
.payer-selector-wrapper.animate,
.cta-title.animate,
.cta-description.animate {
  animation: fadeInUp 0.6s ease-out forwards;
  opacity: 0;
  transform: translateY(20px);
}

.step.animate {
  animation: scaleIn 0.5s ease-out forwards;
  opacity: 0;
  transform: scale(0.9);
}

.feature.animate {
  animation: fadeInLeft 0.6s ease-out forwards;
  opacity: 0;
  transform: translateX(-20px);
}

.feature.reverse.animate {
  animation: fadeInRight 0.6s ease-out forwards;
  opacity: 0;
  transform: translateX(20px);
  margin-top: 2rem;
}

.feature-image {
  overflow: hidden;
}

.feature-img {
  transition: transform 0.3s ease-in-out;
}

.feature:hover .feature-img {
  transform: scale(1.05);
}

.cta-button {
  position: relative;
  overflow: hidden;
  float: left;
}

.cta-button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(0, 49, 111, 0.3);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.cta-button:focus:not(:active)::after {
  animation: ripple 1s ease-out;
}

@media only screen and (min-width: 1441px) {
  .golden-container {
    width: 980px;
  }
}

@media only screen and (max-width: 1068px) {
  .golden-container {
    width: 692px;
  }

  .hero-title {
    font-size: 48px;
  }

  .hero-subtitle {
    font-size: 24px;
  }

  .section-title {
    font-size: 40px;
  }

  .feature-title {
    font-size: 32px;
  }

  .feature-description {
    font-size: 19px;
  }
}

@media only screen and (max-width: 734px) {
  .golden-container {
    width: 87.5%;
  }

  .cta-buttons {
    flex-direction: column;
  }

  .hero-title {
    font-size: 40px;
  }

  .hero-subtitle {
    font-size: 22px;
  }

  .section-title {
    font-size: 32px;
  }

  .section-description {
    font-size: 19px;
  }

  .step {
    flex-basis: 100%;
  }

  .feature {
    flex-direction: column;
  }

  .feature.reverse {
    flex-direction: column;
  }

  .feature-content {
    padding-right: 0;
    margin-bottom: 30px;
  }

  .feature.reverse .feature-content {
    padding-left: 0;
  }

  .feature-title {
    font-size: 28px;
  }

  .feature-description {
    font-size: 17px;
  }

  .cta-title {
    font-size: 32px;
  }

  .cta-description {
    font-size: 19px;
  }

  .twobygrid {
    flex-direction: column;
    gap: 5px;
  }

  .twoby-grid-item {
    width: 90% !important;
    height: 250px !important;
  }

  .vertical-line {
    display: none;
  }


  .heroGrid {
    display: block !important;
    grid-template-rows: 2;
    /* Adjust the first column to be slightly bigger */
    gap: 16px;
    margin-left: 0%;
    margin-right: 0%;
    width: 100%;
    padding: 0px;
  }

  .heroImage {
    position: flex;
    align-items: center;
    justify-content: center;
    width: 90% !important;
    margin-bottom: auto !important;
    margin-top: auto !important;
  }

  .hero-section {
    height: auto !important;
  }

}

.features-section {
  padding: 20px 0;
  background-color: #fafafa;
}

.features-section-sales {
  padding: 0px 0;
  background-color: #fafafa;
}


.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-top: 60px;
}

.feature-item {
  text-align: center;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
}

.feature-icon {
  font-size: 48px;
  color: #00316f;
  margin-bottom: 20px;
}

.feature-item p {
  font-size: 18px;
  line-height: 1.4;
  color: #1d1d1f;
}

.twobygrid {
  display: flex;
  gap: 20px;
  margin-top: 1.5rem;
}

.twoby-grid-item {
  width: 50%;
  text-align: left;
  max-height: 600px;
  overflow-y: auto;
  overflow-x: auto;
  height: 400px;
  position: relative;
}

.twoby-grid-item-icon {
  text-align: left;
  max-height: auto;
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  position: relative;
}

.twoby-grid-item-text {
  width: 80%;
  text-align: left;
  max-height: 600px;
  overflow-y: hidden;
  overflow-x: hidden;
  height: auto;
  position: relative;
}

.transition {
  opacity: 0;
  transition: opacity .25s ease-in-out;
}

.twoby-grid-item .active {
  opacity: 1;
}

.vertical-line {
  width: 1.1px;
  background-color: white;
  height: 400px;
  margin: 0 10px;
  z-index: 999;
}

.active {
  opacity: 1;
  transition: opacity .25s ease-in-out;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.patientsBallsLabel {
  display: flex;
  align-items: center;
  list-style-type: none;
  margin-bottom: 20px;
  font-size: 28px;
  position: relative;
  width: 20rem;
  white-space: wrap;
}

.PatientsBallsContainer {
  position: block;
}

.PatientsBallsContainerUL {
  padding-top: 10px;
}

.batchClaimsImage {
  width: 70%;
  border-radius: 10px;
  margin-top: 1rem;
}


.customFormHeader {
  position: fixed;
  top: 0;
  margin-top: 3rem;
  padding-top: 1rem;
}

.intakeFormMargin {
  margin-top: 8rem;
  margin-bottom: -4rem;
}

.landingCustomForm {
  margin-top: 5rem;
}

.heroGrid {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  /* Adjust the first column to be slightly bigger */
  gap: 16px;
  margin-left: 10%;
  margin-right: 10%;
}

.heroImage {
  position: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  max-width: 95%;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
}

.heroImageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.patients-balls-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  justify-content: center;
}

@media (max-width: 600px) {
  .patients-balls-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 500px) {
  .patients-balls-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 350px) {
  .patients-balls-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.patient-timeline-section {
  height: 400px;
}

.competitors-table {
  border-collapse: collapse;
}

.competitiors-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 2px solid #ccc;
}

.logo {
  font-size: 1.5em;
  font-weight: bold;
}

.titleCompetitiors {
  font-size: 1.2em;
  font-weight: bold;
}

.row {
  display: flex;
  justify-content: space-between;
  padding: 2px;
  border-bottom: 1px solid #ccc;
}

.feature {
  flex: 2;
  display: flex;
  align-items: center;
  font-size: 1em;
}

.check {
  flex: 1;
  text-align: center;
  font-size: 1.5em;
}

.checked {
  color: green;
}

.providers-container {
  height: 700px;
  margin-left: 5%;
  margin-right: 5%;
}

.competitorsTable {
  margin-top: 3rem;
}

.heroSignIn {
  height: 32px;
  min-width: 92px;
  font-size: 19px;
  border-radius: 22px;
  margin-left: auto;
  margin-right: auto;
  padding-inline: 35px;
}

@media (max-width: 800px) {
  .left-content {
    width: auto;
    padding-top: 0.5rem;
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
}

.animated-hero {
  top: -14px;
  bottom: 0;
  left: 1px;
  right: 0;
  z-index: 2;
}

.hero-signin {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: 2vh;
  padding-bottom: 2vh;
}

.login-btn-hero {
  width: 15vw;
  min-width: 150px;
  max-width: 200px;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  color: #f1f1f1;
  padding: 10px;
  font-size: 19px;
  font-weight: 600;
  border-radius: 30px;
}

.login-btn-hero:hover {
  opacity: .7;
}

.hero-description {
  max-width: 478px;
  color: #1d1d1f;
  font-family: SF Pro Display,Helvetica Neue,sans-serif;
  font-size: 40px;
  font-weight: 600;
  line-height: 44px;
  -webkit-margin-before: 20px;
  margin-block-start: 20px;
  -webkit-margin-after: 20px;
  margin-block-end: 20px;
  max-inline-size: 478px;
}

.cta-container-center {
  width: 100%;
  display: grid;
  justify-content: center;
}

/* Main Section Styles */
.parallax-section {
  padding-top: 20px;
  position: relative;
  overflow: visible; /* Allow overflow of dynamic content */
}

.golden-container {
  margin: 0 auto;
  padding: 20px;
  overflow: visible;
}

.all-in-one-content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: auto; /* Ensure content can grow dynamically */
}

.all-in-one-item {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  margin: 40px 0;
  position: relative;
  height: auto; /* Ensure each item has flexible height */
  padding-left: 0;
  position: relative;
}

/* Blue line between numbers that adjusts based on section height */
.all-in-one-item::before {
  content: '';
  position: absolute;
  left: 27px; /* Align the line with the number */
  top: 25%; /* Start below the number */
  width: 2px;
  height: calc(100% - 70px); /* Adjust the height dynamically */
  background-color: var(--line-color, #0147df); /* Use CSS variable for the line color */
  z-index: 1;
}

/* Special case for the first section */
.all-in-one-item:first-child::before {
  content: ''; /* Show the line only below the first number */
  position: absolute;
  top: 25%; /* Start the blue line at the bottom of the first item */
  left: 27px;
  width: 2px;
  height: 300px; /* Adjust the height of the line between the first and second item */
  background-color: var(--line-color, #0147df); /* Use CSS variable for the line color */
  z-index: 1;
}

/* No line below the last section */
.all-in-one-item:last-child::before {
  display: none; /* Hide the line below the last section */
}


@media (max-width: 1000px) {

.all-in-one-item::before {
  top: 15%;
  height: calc(70% - 70px); /* Adjust the height dynamically */
}
.all-in-one-item::before {
  top: 15%;
}
}


/* Number and Capsule alignment */
.number-capsule {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-left: 0;
  gap: 10px;
  position: relative;
}

.large-number {
  font-size: 2.5em;
  font-weight: bold;
  margin-right: 10px;
  align-self: flex-start;
  position: relative;
}

.capsule-label {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 8px;
  border-radius: 12px;
  margin-top: 14px;
  display: inline-block;
}

.gradient-capsule {
  position: relative;
  overflow: hidden;
}

.gradient-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background: conic-gradient(from 180deg at 50% 50%, transparent 299deg, rgb(140, 12, 245) 335deg, transparent 369deg);
  animation: rotate-gradient 6s infinite linear;
  opacity: 0.5;
}

@keyframes rotate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Section Content to be below the capsule */
.section-content {
  display: flex;
  flex-direction: column;
}

.AIO-section-title {
  font-size: 26px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.section-description-left {
  font-size: 20px;
  color: #666;
  text-align: left;
}



.testimonial-section {
  background-color: #f1f1f1;
  color: #00316f;
  text-align: center;
  padding-left: 5vw;
  padding-right: 5vw;
}

.process-section {
  padding: 20px 20px;
  background-color: #f1f1f1;
  color: #1d1d1f;
  text-align: center;
}

.process-steps {
  display: flex;
  justify-content: space-around;
  gap: 40px;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.process-steps-claims {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create a 2x2 grid */
  gap: 40px; /* Adjust the gap between grid items */
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 40px;
}

.step-claims {
  background-color: #fff; /* Optional: Add a background color for each step */
  padding: 20px; /* Optional: Padding around each step */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow effect */
  border-radius: 8px; /* Optional: Rounded corners */
  text-align: left; /* Align text to the left */
}

@media (max-width: 450px) {
  .process-steps-claims {
    grid-template-columns: 1fr; /* Change to 1 column */
    gap: 20px; /* Reduce the gap between items */
  }
  
  .step-claims {
    text-align: center; /* Center-align the text for a better mobile view */
  }
}

.step-3 {
  flex: 1 1 25%; /* Ensures 3 steps per row */
  margin: 5px;
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
  text-align: center;
}

.step-icon {
  font-size: 50px;
  height: 40px;
  margin-bottom: 0px;
  color: #00316f; /* Icon color */
}

.step-icon-dark {
  font-size: 50px;
  height: 40px;
  margin-bottom: 20px;
  color: #ffffff; /* Icon color */
}

.step-title {
  font-size: 24px;
  margin: 20px 0 10px;
}

.step-description {
  font-size: 18px;
  color: #555;
}

.why-popularis-section {
  padding: 30px 20px;
  background-color: #f1f1f1;
  text-align: center;
}

.popularis-features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Flexible grid for 2 or 3 columns */
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.feature-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.6s ease-out forwards;
}

.feature-icon {
  color: #00316f; /* Customize icon color */
  margin-bottom: 20px;
}

.feature-title {
  font-size: 24px;
  margin: 20px 0 10px;
  color: #333;
}

.feature-description {
  font-size: 16px;
  color: #666;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media only screen and (max-width: 768px) {
  .popularis-features-grid {
    grid-template-columns: 1fr; /* Stack the cards on smaller screens */
  }
}

.glanceIcons {
  height: 60px;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
  height: 380px;
  margin-bottom: 32px;
}

.testimonial-card {
  flex: 0 0 200px;
  opacity: 0.5;
  transition: transform 0.5s ease, opacity 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f1f1f1;
}

.testimonial-card.active {
  transform: scale(1.2);
  opacity: 1;
}

.testimonial-image-container {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-quote {
  font-size: 18px;
  margin: 10px 0;
}

.testimonial-name {
  font-size: 16px;
  color: #555;
}

@media only screen and (max-width: 768px) {
  .testimonial-card {
    flex: 0 0 150px;

  }

  .testimonial-card.active {
    transform: scale(1.1);
  }

  .testimonial-image-container {
    width: 80px;
    height: 80px;
  }
}


.testimonial-section {
  background-color: #f1f1f1;
  color: #00316f;
  text-align: center;
  padding: 30px 0;  overflow: visible;

}

.testimonial-container {
  max-width: 800px;
  margin: 0 auto;
  position: relative;
  overflow: visible;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
  position: relative;
  gap: 30px; /* Added for spacing between cards */
}

.testimonial-card {
  flex: 0 0 250px;
  opacity: 0.5;
  transition: transform 0.5s ease, opacity 0.5s ease, box-shadow 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #fefefe;
  border-radius: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.testimonial-card.active {
  transform: scale(1.15);
  opacity: 1;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.testimonial-image-container {
  width: 100px;
  height: 100px;
  margin-bottom: 20px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #00316f; /* Adds a border to enhance the profile image */
}

.testimonial-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-quote {
  font-size: 18px;
  margin: 10px 0;
  color: #333;
  font-style: italic;
}

.testimonial-name {
  font-size: 16px;
  color: #555;
}

@media only screen and (max-width: 768px) {
  .testimonial-card {
    flex: 0 0 200px;
    margin-left: 30px;
    margin-right: 30px;
  }

  .testimonial-card.active {
    transform: scale(1.1);
  }

  .testimonial-image-container {
    width: 80px;
    height: 80px;
  }

  .carousel {
    flex-direction: column;
    height: auto;
  }
}

.bg-dark {
  background-color: #00316f;
  color: #f1f1f1;
}

.security-section {
    font-size: 2rem;
    line-height: 1.08349;
    font-weight: 500;
    letter-spacing: -0.003em;
    text-align: left;
    margin-bottom: 50px;
    padding-bottom: 24px;
    border-bottom: 2px solid #ffffff;
    color: #ffffff;
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    height: 0;
    overflow: hidden;
    max-width: 100%;
    background: #000;
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .section-title {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 500;
    letter-spacing: -0.003em;
    text-align: left;
    margin-bottom: 50px;
    padding-bottom: 24px;
    border-bottom: 2px solid #333;
    color: #333;
  }

  
  .hero-section {
    background-color: #f1f1f1;
    color: #00316f;
    height: auto;
    position: relative;
    overflow: hidden;
    text-align: center;
  }

  .hero-container {
    max-width: 980px;
    margin: 0 auto;
    padding: 0 22px;
    position: relative;
    z-index: 1;
  }

  .hero-text {
    z-index: 1;
  }

  .hero-title {
    font-size: 72px;
    line-height: 1.05;
    font-weight: 500;
    letter-spacing: -0.015em;
    margin-bottom: 10px;
    margin-top: 10px;
  }


  .cta-buttons.hidden {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease, visibility 0.5s ease;
  }

  .cta-buttons.visible {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    animation: fadeIn 3.5s ease;
  }

  .cta-button {
    font-size: 18px;
    padding: 12px 30px;
    margin: 0 10px;
    background-color: #00316f;
    color: #fefefe;
    border: none;
    border-radius: 60px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  .cta-button:hover {
    background-color: #fefefe;
    color: #00316f;
  }

  .hero-disclaimer {
    font-size: 14px;
    color: inherit;
  }

  .landing-page {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Helvetica', 'Arial',
      sans-serif;
    color: #1d1d1f;
    background-color: #f1f1f1;
    -webkit-font-smoothing: antialiased;
  }

  .bg-white-white {
    background-color: #fafafa;
  }

  
  .hero-image-container {
    max-width: 70%;
    margin: 0 auto;
}

.hero-image {
    width: 100%;
    height: auto;
}

.icon-gradient {
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  height: 30px;    
  margin-right: 10px; /* Spacing between icon and text */
}

.allinone-section {
  background-color: #ffffff;
}

.allinone-container {
  padding-left: 5%;
  padding-top: 5%;
  padding-bottom: 10rem;
}

.section-title {
  font-size: 2rem;
  margin-bottom: 20px;
}

/* Full Desktop Content */
.allinone-content {
  display: flex;
  align-items: flex-start;
}

.allinone-list {
  flex: 1 1 auto;
  width: 40vw;
}

.allinone-video {
  position: sticky;
  top: 20%;
  width: 65vw;
}

.allinone-sticky-video {
  width: 100%;
  height: auto;
  border-radius: 8px;
}


/* Mobile-only Content */
.mobile-only {
  height: 400vh; /* Reduced height to push content off sooner */
  position: relative;
  text-align: center;
  padding-top: 3vh;
}

.mobile-only-title {
  position: sticky;
  top: 15%;
  font-size: 1.5rem;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding-left: 5%;
  padding-right: 5%;
}

.mobile-title-icon {
  font-size: 1.5rem;
  color: #333;
}

.mobile-description {
  position: sticky;
  top: 20%;
  font-size: 1ew;
  z-index: 1;
  display: flex;
  justify-content: center;
  gap: 8px;
  color: #666;
  padding-left: 5%;
  padding-right: 5%;
}

.mobile-feature-list {
  position: sticky;
  top: 66%;
  font-size: 1ew;
  z-index: 1;
  width: 80vw;
  display: flex;
  align-items: left;
  justify-content: center;
  gap: 20px;
  color: #666;
  padding-left: 5%;
  padding-right: 5%;
  text-align: left;
  list-style-type: none;
  flex-direction: column;
}

/* Video styling */
.mobile-only-video {
  position: sticky;
  top: 46%;
  transform: translateY(-50%);
  width: 100%;
  margin: 0 auto;
  border-radius: 8px;
  z-index: 0;
  margin-top: 20vh;
}

/* Hide desktop content on mobile screens */
@media (max-width: 599px) {
  .allinone-content {
    display: none;
  }

  .allinone-container {
    padding-left: 0%;
  }
}

@media (max-width: 400px) {
  .mobile-only-title {
    font-size: 1.25rem;
  }

  .allinone-container {
    padding-left: 0%;
  }

}

.paddedTitle {
  margin-bottom: 75vh; /* Adds extra padding after scrolling 200vh */
}

.paddedDescription{
  margin-bottom: 68vh;
}

.paddedVideo{
  margin-bottom: 27vh;
}

.paddedFeatures{
  margin-bottom: 10vh;
}

.mobile-feature-list li {
  font-size: 17px;
  line-height: 1.47059;
  font-weight: 400;
  letter-spacing: -.022em;
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  color: #1d1d1f;
}

.mobile-feature-list li:before {
  content: '✓';
  position: absolute;
  left: 0;
  color: #00316f;
  font-weight: 600;
}

.email-input-container {
  position: relative;
  width: 100%;
  max-width: 400px; /* Adjust as needed */
  margin-top: 20px;
}

.email-input {
  width: 100%;
  padding: 12px 0px 12px 12px; /* Add right padding to make space for the button */
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 36px;
  outline: none;
  margin: 0;
}

.start-now-button {
  position: absolute;
  top: 0px;
  right: -12px;
  bottom: 0px;
  margin: 5px;
  border-radius: 17px;
  padding: 6px 12px;
  font-size: 14px;
  background-color: transparent;
  color: #ffffff;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  border: none;
  cursor: pointer;
}

.start-now-button:hover {
  background-image: linear-gradient(30deg, #00316f, #0147df);
}

.hero-text-left {
  width: 80%;
}

.hero-section {
  position: relative;
  overflow: hidden;
  min-height: 600px;
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero-angle {
  position: absolute;
  left: 0;
  width: 50%;
  height: 150%; /* Extend the height beyond 100% */
  background-color: #00316f;
  transform: skewX(-12deg);
  transform-origin: top;
  z-index: 3;
}

.hero-wrapper {
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 64px 24px;
  display: flex;
  align-items: center;
}

.hero-content-left {
  width: 50%;
  padding-right: 48px;
  color: white;
  z-index: 3;
}

.hero-content-right {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hero-title {
  font-size: 48px; /* Adjusted size */
  font-weight: 700;
  margin-bottom: 24px;
  color: white;
  line-height: 1;  /* Tighter line height */
  letter-spacing: -0.5px;
}

.hero-description {
  font-size: 24px; /* Slightly smaller */
  line-height: 1.4;
  margin-bottom: 32px;
  color: white;
  font-weight: 400;      
  text-align: left;

}

/* Button styles remain the same */
.demo-btn {
  font-family: 'Space Grotesk', sans-serif;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #00316f;
  padding: 12px 28px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 32px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.demo-btn:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.demo-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.contactSales {
  display: none;
  cursor:pointer;
  margin-top: 10px;
}

.contactSales:hover {
  background-color: rgba(0, 123, 255, 0.1);
  border-radius: 4px;
}


@media only screen and (max-width: 734px) {

  .securityFootnote {
    padding-left: 5%;
    padding-right: 5%;
  }

  .hero-background {
    background-color: #f1f1f1;
  }

  .contactSales {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #000;
    margin-top: 20px;

  }
  .hero-section {
    text-align: center;
  }

  .videoHero {
    width: 90%;
  }

  .textLeft {
    text-align: center !important;
  }
  .hero-angle {
    z-index: 2;
  }

  .hero-text-left{
    width: auto;
  }

  .hero-description {
    text-align: center;
    max-width: fit-content;
  }

  .email-input-container {
    width: 94%;
    margin-left: 1%;
    max-width: none;
  }
  .hero-wrapper {
    flex-direction: column-reverse; 
    padding: 0px;
  }

  .hero-content-left,
  .hero-content-right {
    width: 100%;
    padding-right: 1%;
    padding-left: 1%;
    padding-top: 2%;  }

  .hero-angle {
    width: 100%;
    transform: skewX(0);
    height: 120%;
    background-color: #f1f1f1;
  }

  .hero-title {
    font-size: 32px; /* Smaller for mobile */
    white-space: normal; /* Allow wrapping on mobile */
    color: #000;
    background: #f1f1f1;
  }

  .hero-description {
    font-size: 20px;
    color: #000;
    background: #f1f1f1;
  }

  .animated-hero {
    margin: 32px auto;
  }

  .demo-btn {
    font-size: 14px;
    padding: 10px 24px;
  }
}

.animate {
  animation: fadeInUp 0.6s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.read-more-button {
  color: #fff;
  background-color: #00316f;
}

.textLeft {
  text-align: left;
}
.easy-upgrade-section {
  display: flex;
  align-items: center;
  gap: 40px;
}

.easy-upgrade-content-left {
  flex: 1;
}

.easy-upgrade-content-right {
  flex: 1;
  display: flex;
  justify-content: center;
}

.easy-upgrade-title {
  font-size: 28px;
  color: #00316f;
  margin-bottom: 40px;
}

.easy-upgrade-steps {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.easy-upgrade-step {
  display: flex;
  align-items: flex-start;
}

.easy-upgrade-icon {
  height: 32px;
  margin-right: 15px;
  color: #00316f;
  min-width: 40px;
}

.easy-upgrade-text {
  flex: 1;
}

.easy-upgrade-step-title {
  font-size: 20px;
  margin: 0;
}

.easy-upgrade-step-description {
  margin: 5px 0 0 0;
  font-size: 16px;
  color: #555;
}

.easy-upgrade-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 1, 0.3); /* Add this line */
}

/* Responsive adjustments */
@media only screen and (max-width: 734px) {
  .easy-upgrade-section {
    flex-direction: column;
  }

  .easy-upgrade-content-right {
    margin-top: 20px;
  }
}

/* General Section Styles */
.ui-grid-section {
  padding: 60px 20px;
}

.ui-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .ui-grid-container {
    grid-template-columns: 1fr;
  }
}
/* General Section Styles */
.ui-grid-section {
  padding: 60px 20px;
}

.ui-grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

@media only screen and (max-width: 600px) {
  .ui-grid-container {
    grid-template-columns: 1fr;
  }
}

/* Card Styles */
.ui-grid-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  border: 10px solid #fff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

}

.ui-grid-card:hover {
  transform: translateY(-2px);
}

/* Vertical Offset for Left Column Cards */
.ui-grid-card.offset-left {
  margin-top: 0px; /* Adjust the offset as needed */
}

/* Image Wrapper */
.ui-grid-card-image-wrapper {
  position: relative;
  width: 100%;
  height: 350px;
  overflow: hidden;
  background: radial-gradient(circle, #f1f1f1, #f1f1f1); /* Default circular gradient */
  transition: background 0.5s ease; /* Smooth transition */
}

/* Circular Gradient for Eligibility Card */
.card-eligibility:hover .ui-grid-card-image-wrapper {
  background: radial-gradient(circle, #0147df7c, #f1f1f1); /* Circular gradient on hover */
}

/* Circular Gradient for Custom Forms Card */
.card-custom-forms:hover .ui-grid-card-image-wrapper {
  background: radial-gradient(circle, #00306f85, #f1f1f1); /* Circular gradient on hover */
}

/* Circular Gradient for Billing Card */
.card-billing:hover .ui-grid-card-image-wrapper {
  background: radial-gradient(circle, #00306f83, #f1f1f1); /* Gradient switched */
}

/* Circular Gradient for Patient Cards */
.card-patient-cards:hover .ui-grid-card-image-wrapper {
  background: radial-gradient(circle, #0147df86, #f1f1f1); /* Gradient switched */
}

/* Image Styling */
.ui-grid-card-image {
  position: absolute;
  width: auto;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: transform 0.3s ease; /* Smooth upward translation on hover */
}

/* Image Translation on Hover */
.ui-grid-card:hover .ui-grid-card-image {
  transform: translate(-50%, -10px); /* Translate up slightly on hover */
}

/* Individual Control Over Image Position and Size */
.image-eligibility {
  left: 91%;
  height: 70%;
  top: 6%;
  border-radius: 10px;
}

.image-custom-forms {
  left: 54%;
  top: 5%;
  height: 100%;
  border-radius: 10px;
}

.image-billing {
  left: 46%;
  top: 15%;
  height: 90%;
  border-radius: 10px;
}

.image-patient-cards {
  left: 61%;
  border-radius: 10px;
  top: 5%;

}

/* Content Styles */
.ui-grid-card-content {
  padding: 20px;
}

/* Hide 'Learn more' link initially */
.ui-grid-card-hover-link {
  display: none;
}

/* Show 'Learn more' link on hover */
.ui-grid-card:hover .ui-grid-card-hover-link {
  display: block;
  padding: 0 20px 20px 20px;
}

.ui-grid-card-title {
  margin: 0 0 10px 0;
  font-size: 24px;
  color: #00316f;
}

.ui-grid-card-description {
  margin: 0;
  color: #555;
}

/* 'Learn more' Link Styling */
.ui-grid-card-link {
  color: #00316f;
  font-weight: bold;
  text-decoration: none;
}

.ui-grid-card-link:hover {
  text-decoration: underline;
}


/* Content wrapper */
.demo-content {
  padding: 2rem;
}

/* Wrapper for left and right sections */
.demo-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 1200px;
  margin: 0 auto;
}

/* Left Section - What to Expect */
.demo-left {
  flex: 1;
  margin-right: 2rem;
}

.demo-left h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.expect-items {
  display: flex;
  flex-direction: column;
}

.expect-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}

.expect-icon {
  margin-right: 1rem;
  color: #00316f; /* Adjust the color to match your theme */
  flex-shrink: 0;
}

.expect-text h4 {
  margin: 0;
  font-size: 1.25rem;
}

.expect-text p {
  margin: 0.5rem 0 0 0;
  font-size: 1rem;
  color: #555;
}

/* Right Section - Demo Form */
.demo-right {
  flex: 1;
}

.demoForm {
  background-color: #f9f9f9;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.demoForm h4 {
  font-weight: 600;
}


.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}

/* Submit Button */
.demoForm button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #00316f;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.demoForm button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.demoForm button:hover:not(:disabled) {
  background-color: #0056b3;
}

/* Responsive Design */
@media (max-width: 768px) {

  .demo-left {
    display: none;
  }

  .demo-wrapper {
    flex-direction: column;
  }

  .demo-left,
  .demo-right {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%;
  }

  .expect-item {
    align-items: flex-start;
  }

  .expect-icon {
    margin-bottom: 0.5rem;
  }
}

.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 750px; /* Adjust as needed */
  margin: 0 auto;
}

.video-thumbnail {
  position: relative;
  cursor: pointer;
}

.ipad-image {
  width: 100%;
  display: block;
  border-radius: 10px; /* Optional: Adds rounded corners */
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px; /* Adjust size as needed */
  height: 150px;
  transform: translate(-50%, -50%);
  opacity: 0.8;
  transition: opacity 0.3s ease;
}

.video-thumbnail:hover .play-button {
  opacity: 1;
}

.video-container iframe {
  width: 100%;
  border: none;
  border-radius: 10px; /* Optional: Adds rounded corners */
}

/* Enterprise Section */
.enterprise-section {
  padding: 6rem 0;
  text-align: center;
  color: #00316f;
}

.enterprise-features {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  flex-wrap: wrap;
  margin-top: 2rem;
}

.enterprise-feature {
  flex: 1 1 calc(33.33% - 2rem);
  min-width: 250px;
  text-align: center;
  background: #ffffff;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.enterprise-feature h4 {
  margin: 1rem 0 0.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.enterprise-feature p {
  font-size: 1rem;
  color: #555;
}

.enterprise-icon {
  height: 40px;
  color: #0147df;
}

.enterprise-button-wrapper {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.enterprise-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: 600;
  color: #ffffff;
  background: #00316f;
  border-radius: 20px;
  text-decoration: none;
  transition: background 0.3s;
}

.enterprise-button:hover {
  background: #0147df;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .enterprise-features {
    flex-direction: column;
    gap: 1.5rem;
  }

  .enterprise-feature {
    flex: 1 1 100%;
  }
}

.securityFootnote {
  text-align: center;
  padding-left: 15%;
  padding-right: 15%;
}
/* Styles for the Sandbox Section */
.sandbox-section {
  z-index: 1;
}

.sandbox-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px;
  overflow: visible;
}

.sandbox-text {
  flex: 1;
  padding-right: 2rem;
}

.sandbox-image {
  flex: 1;
  display: flex;
  justify-content: center;
}

.sandbox-image img {
  max-width: 100%;
  height: auto;
  
}

.sandbox-section p {
  font-size: 20px;
  font-weight: 600;
  color: #000000e8;
  display: flex;
  align-items: center;
}

.sandbox-button-wrapper {
  margin-top: 2rem;
}

.sandbox-button {
  background-color: #00316f;
  color: #fff;
  padding: 1rem 2rem;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.sandbox-button:hover {
  background-color: #00254d;
}

/* Animation classes */
.animate {
  animation: fadeInUp 0.6s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media only screen and (max-width: 734px) {
  .sandbox-section {
    padding: 2rem 1rem;
  }

  .sandbox-content {
    flex-direction: column;
  }

  .sandbox-text {
    padding-right: 0;
    text-align: center;
  }

  .sandbox-image {
    margin-top: 2rem;
  }

  .sandbox-section .section-title {
    font-size: 2rem;
  }

  .sandbox-section p {
    font-size: 1rem;
  }

  .sandbox-button {
    width: 100%;
  }
}

.sandbox-icon {
  height: 30px;
  margin-right: .5rem;
  color: #00316f;

}

/* Styles for the Data Integration Section */
.data-integration-section {
  padding: 4rem 0;
  z-index: 1;
}

.data-integration-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-integration-text {
  flex: 1;
  padding-right: 2rem;
}

.data-integration-image {
  flex: 1;
  display: flex;
  justify-content: center;
  padding-top: 1rem;
}

.data-integration-image img {
  max-width: 100%;
  height: auto;
  box-shadow: 0 4px 9px rgba(0, 0, 0, 0.3), 0 1px 3px rgba(0, 0, 0, 0.08);

}

.data-integration-section .section-title {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #00316f;
}


.feature-icon-integration {
  height: 40px;
  color: #00316f;
  margin-right: 1rem;
  margin-top: 0.3rem;
}

.feature-text-integration h3 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem;
  color: #000000e8;
}

.feature-text-integration p {
  font-size: 1rem;
  margin: 0;
  color: #333;
}

.animate {
  animation: fadeInUp 0.6s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media only screen and (max-width: 734px) {
  .data-integration-section {
    padding: 2rem 1rem;
  }

  .data-integration-content {
    flex-direction: column;
  }

  .data-integration-text {
    padding-right: 0;
    padding-top: 5rem;
  }

  .data-integration-image {
    margin-top: 2rem;
  }

  .data-integration-section {
    font-size: 2rem;
  }
}


.skewed-section {
  position: relative;
  z-index: 1;
  padding-bottom: 8rem;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

.skewed-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 115%;
  background: inherit;
  z-index: -1;
  transform: skewY(-3deg);
  transform-origin: top left;
}

.skewed-flag-section {
  position: relative;
  z-index: 1;
  padding-bottom: 8rem;
  margin-top: 0rem;
  margin-bottom: 3rem;
}

.skewed-flag-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 135%;
  background: inherit;
  z-index: -1;
  transform: skewY(-3deg);
  transform-origin: top left;
}

@media only screen and (max-width: 1070px) {
  .skewed-flag-section::before {
    height: 110%;
  }

}

.skewed-section > * {
  position: relative;
  z-index: 1;
}

.animate {
  animation: fadeInUp 0.6s ease-out forwards;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.stats-section {
  background-color: #f1f1f1;
  color: #00316f;
  text-align: center;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
}

.stat-item {
  opacity: 0;
  animation: fadeInUp 0.6s ease-out forwards;
}

.stat-value {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #00316f;
}

.stat-label {
  font-size: 24px;
  color: #1d1d1f;
}

.px-5vw {
  margin-left: 5vw;
  margin-right: 5vw;
}

.bg-dark {
  background: #00316f;
  color: white;
}

.bg-grey {
  background: #f1f1f1;
}

.bg-white {
  background: #fefefe;
}

.process-steps {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 2rem;
}

.step-3 {
  flex: 1;
  min-width: 225px;
  text-align: center;
  padding: 1.5rem;
}

.support-wrapper {
  display: flex;
  align-items: center;
  gap: 2rem;
}

@media only screen and (max-width: 734px) {
  .hero-title {
    font-size: 56px;
  }

  .heroImageContainer {
    display: none;
  }

  .hero-description {
    font-size: 24px;
    line-height: 36px;
    margin-left: 5%;
    margin-right: 5%;
  }

  .support-text {
    text-align: center;
  }

  .tile-content {
    padding: 0px;
  }

  .support-icon-features {
    margin-right: 0rem;
  }

  .support-wrapper {
    flex-direction: column;
    text-align: center;
  }

  .cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }

  .process-steps {
    flex-direction: column;
    align-items: center;
  }

  .step-3 {
    flex: 1 1 100%;
    max-width: 400px;
  }

  .stats-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .stat-value {
    font-size: 36px;
  }

  .stat-label {
    font-size: 16px;
  }

  .skewed-section {
    padding: 4rem 0;
  }

  .skewed-section::before {
    transform: skewY(-5deg);
  }

  .salesPageSignUp {
    width: auto !important;
    margin-left: 2vw !important;  }
}

.salesPageSignUp {
  margin-left: 0vw;
  width: 30vw;
min-width: 14rem;
}

.text-sales-container {
  color: #000;
}

.salesBlack {
  color: #000;
}

.section-sandbox-subtitle {
    font-size: 48px;
    line-height: 1.08349;
    font-weight: 500;
    letter-spacing: -0.003em;
    text-align: left;
    margin-bottom: 50px;
    padding-bottom: 24px;
    color: #333;
    font-size: 2rem;
  margin-bottom: 20px;
}

.fourlayout {
  min-width: 150px;
  padding: .25rem;
}

.AmericaContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.madeInAmerica {
  width: 250px;
}

.sandbox-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem; /* Adjust spacing between items as needed */
}

/* Fixed-width container for icons */
.icon-wrapper {
  width: 40px; /* Set a fixed width suitable for your icons */
  min-width: 40px; /* Ensure the container doesn't shrink */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.75rem; /* Space between icon and text */
}

/* Style for the icons */
.sandbox-icon {
  font-size: 1.5rem; /* Adjust as needed */
  /* Remove margin-right from icon since it's handled by icon-wrapper */
}

/* Optional: Style for the text */
.sandbox-item span {
  flex: 1; /* Allow text to take up remaining space */
}


/* Container Styling */
.landingPricing {
  text-align: center;
  padding: 2rem;
  padding-top: 5rem;
}

/* Subtitle Styling */
.pricing-subtitle {
  font-size: 1.5rem;
  color: #343a40; /* Dark gray color */
  margin-bottom: 0.5rem;
}

/* Title Styling */
.pricing-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

/* Style for the pricing period within the title */
.pricing-title .pricing-period {
  font-size: 0.5em; /* Makes the text smaller relative to the h2 */
  vertical-align: middle;
  color: #6c757d; /* Optional: A gray color for less emphasis */
  margin-left: 0.25rem; /* Space between the price and "per month" */
}


/* Link Styling */
.pricing-link {
  display: inline-block;
  font-size: 1rem;
  color: #007bff;
  cursor: pointer;
  margin-bottom: 2rem;
  text-decoration: none;
  transition: color 0.3s ease, text-decoration 0.3s ease;
}

.pricing-link:hover {
  text-decoration: underline;
  color: #0056b3; /* Darker blue on hover */
}

/* Image Styling */
.macbookPricing {
  max-width: 100%;
  height: auto;
  margin-top: 1rem;
  border-radius: 8px; /* Optional: Adds rounded corners to the image */
}

/* Responsive Design */
@media (max-width: 768px) {
  .landingPricing {
    padding: 1rem;
    padding-top: 7rem;

  }

  .pricing-subtitle {
    font-size: 1.25rem;
  }

  .pricing-title {
    font-size: 2rem;
  }

  .pricing-link {
    font-size: 0.9rem;
    margin-bottom: 1.5rem;
  }

  .macbookPricing {
    margin-top: 0.5rem;
  }
}

.made-in-usa-section {
  padding-bottom: 0rem;
  height: 130%;
}

.youtubeSkew {
  padding-bottom: 0rem;
}

.mobile-signin-button {
  display: none;
}

@media (max-width: 1200px) {

  .mobile-signin-button {
    position: fixed;
    top: -0.25rem;
    right: 0;
    z-index: 101;
    color: #f1f1f1;
    padding: 10px;
    border: none;
    align-items: center;
    justify-content: center;
    display: block;
    border-radius: 20px;
  }

  .mobile-signin-button a {
    color: #fff;
    font-weight: bold;
    text-decoration: none;
    background-color: #00316f;
    padding: 0.5rem 1rem;
    border-radius: 20px;
  }

  .mobile-signin-button a:hover {
    background-color: #0147df;
  }
}

.switcher-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  margin-top: 1rem;

}

.switcher-button {
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border: none;
  border-radius: 4px;
  background-color: #f0f0f0;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s;
}

.switcher-button.active {
  background-color: #0147df;
  color: #fff;
}

.switcher-button {
  background-color: #00316f;
  color: #d6d6d6;
}

.switcher-button:hover {
  background-color: #0147df;
  color: #fff;
}

.api-header {
  padding-top: 3rem;
}

.api-main-content {
  padding-left: 10vw;
  padding-right: 10vw;
}