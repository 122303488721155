.svg {
  position: fixed;
  top: 40%;
  left: 40%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}

.svg-inline--fa {
  height: 50px;
}

.hexagonButton h2 {
  font-size: 20px;
  margin: 20;
  bottom: 0px;
  font-weight: 600;
}

.hexagonButton p {
  font-size: 14px;
  margin: 0;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.hexagonButton {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fdfdff;
  font-weight: 700;
  cursor: pointer;
  font-size: 24px;
  font-family: 'Helvetica', 'SofiaPro', 'Avenir', sans-serif;
  height: 250px;
  width: 250px;
  position: relative;
  margin: -27px 5px;
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
  transition: all 0.3s ease-in-out;
  overflow: visible;
  z-index: 99;
}

/* Adjustments for screens up to 480px wide (typical mobile screen) */
@media (max-width: 480px) {
  .hexagonButton {
    font-size: 10px;
    height: 7rem;
    width: 7rem;
    margin: -0.8rem .1rem;
  }


}

/* Adjustments for screens between 481px and 768px wide (typical tablet screen) */
@media (min-width: 481px) and (max-width: 768px) {
  .hexagonButton {
    font-size: 10px;
    height: 10rem;
    width: 10rem;
    margin: -1.1rem .2rem;
  }
}

/* Adjustments for screens over 768px wide (typical desktop/laptop screen) */
@media (min-width: 769px) {
  .hexagonButton {
    font-size: 24px;
    height: 250px;
    width: 250px;
  }
}

/* Adjustments for screens with small vertical size */
@media (max-height: 600px) {
  .hexagonButton {
    font-size: 14px;
    height: 150px;
    width: 150px;
    margin: -1rem 0.2rem;
  }
}

.hexagonShadowWrapper {
  display: inline-block;
  position: relative;
  filter: drop-shadow(-5px 6px 3px rgba(0, 49, 111, 0.45));
}

.error {
  width: 50%;
}

.hexagonButton:hover {
  background-image: linear-gradient(150deg, #0147DF, #00316f);
}

