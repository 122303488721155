.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  color: rgba(0, 0, 0, 0.5);
  position: fixed;
  bottom: 0;
  margin-bottom: 2px;
}


.left a,
.right a {
  color: rgba(0, 0, 0, 0.5);
  text-decoration: none;
  margin-right: 4rem;
  margin-left: 4rem;
}

.middle p {
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 768px) {

  .left a,
.right a {
  margin-left: 1rem;
  margin-right: 1rem;
}

}