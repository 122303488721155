

html, body {
  font-family: 'Hevelica', 'SofiaPro', 'Avenir', sans-serif;

}
.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.container {
  margin-left: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 200px);
  position: relative;
  min-height: 100vh;
  font-family: 'Hevelica', 'SofiaPro', 'Avenir', sans-serif;

}


.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  min-height: 100vh;
}

  
  .content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-left: 200px; 
    flex-grow: 1;
  }
  
  .leftColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    margin-top: 20px;
  }
  
  .rightColumn {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    padding: 10px;
    margin-top: 20px;

  }
  
 
  .file-input {
    margin-top: 20px;
  }
  
  .file-input input {
    margin-bottom: 10px;
  }
  
  .progressBar {
    width: 100%;
    margin-top: 250px;
  }
  
  .outerbar {
    width: 100%;
    background-color: #ddd;
    border-radius: 10px;
  }
  
  .innerbar {
    background-color: #4CAF50;
    height: 20px;
    text-align: center;
    color: white;
  }
  .contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
  }
  
  .section {
    width: 80%;
    margin: 5px;
    align-items: center;
  }
  

  
.requestsContainer {
    max-height: 500px;
    overflow: auto;
  }
  
.totalRequestsContainer {
    display: fixed;
    justify-content: bottom;
    align-items: center;
    height: 50px;
    background-color: #f1f1f1;
    border: 1px solid #ccc;
    margin-top: 20px;
    padding: 10px;
    font-weight: bold;
    font-size: 18px;
  }
  button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #00316f;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px #888888;
  }
  
  .table {
    width: 100%;
    max-width: 800px;
    border-collapse: collapse;
  }
  
  .table th,
  .table td {
    border: 1px solid #e0e0e0;
    padding: 8px;
    text-align: left;
  }
  
  .table thead {
    background-color: #f2f2f2;
  }
  
  .table tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }

  .payerSelection label {
    margin-right: 10px;
  }

  .formContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .filesTable {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 700px;
    overflow-y: scroll;
    padding: 20px;
  }
  
  .filesTable th,
  .filesTable td {
    border: 1px solid #e0e0e0;
    padding: 8px;
    text-align: left;
    font-size: 14px; /* Adjust font size */
  }
  
  .filesTable thead {
    background-color: #f2f2f2;
  }
  
  .filesTable tbody tr:nth-child(even) {
    background-color: #f8f8f8;
  }
  

    
  .fileName {
    margin-left: 1rem;
  }

  .fileItem {
    display: grid;
    align-items: center;
  }
  
  .deleteBtn {
    padding: 7px;
    border-radius: 10px;
    background-color: #f30c0c;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px #888888;
    width: 100%;
    margin-bottom: 20px; /* Add margin-bottom to create space between buttons */

  }
  
  .downloadBtn {
    padding: 7px;
    border-radius: 10px;
    background-color: #00316f;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    box-shadow: 0px 1px 5px 0px #888888;
    margin-top: 20px; /* Add margin-top to create space between buttons */

  }
  
  .logoContainer {
    display: flex;
    align-items: center;
  }



  .StripeElement {
    width: 300px;
    padding: 15px;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  .StripeElement input {
    width: 300px;
  }

  .columns {
    display: flex;
  }
  
  .scrollableTable {
    height: 400px; /* Adjust the height according to your needs */
    overflow-y: auto;
  }
  
  .error-message {
    color: #ff4d4d; /* Red color for error text */
    font-size: 14px;
    font-weight: bold;
    margin-top: 10px;
    border: 1px solid #ff4d4d; /* Red border for the container */
    border-radius: 10px;
    padding: 10px;
    background-color: #ffe6e6; /* Light red background color */
    max-width: 400px;
    text-align: center;
  }

  .dashboardContainer {
    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
  }
  
  
  .apiRequestsSection,
  .logsSection {
    flex: 1;
    width: 100%;
    margin-left: 5%;
    margin-right: 5%;
    height: 100%;
  }
  
  .logsContainer {
    overflow-y: auto;
    height: 90%;
    width: 95%;
  }
  
  .hidden {
    display: none;
  }
  
  .toggleButtons {
    margin-bottom: 0px;
    margin-top: 10px;

  }
  

  

  

  .apiButton {
    position: fixed;  /* Fixed positioning */
    bottom: 20px;     /* 20px from the bottom of the viewport */
    right: 20px;      /* 20px from the right of the viewport */
    z-index: 1000;
  }

  .apiPage {
    width: 80%;  /* Full width of the page */
    margin-top: 2rem;
    padding: 20px;  /* Padding around the content */
    box-sizing: border-box;  /* Include padding in width calculation */
    display: flex;  /* Flexible box layout */
    flex-direction: column;  /* Stack elements vertically */
    align-items: center;  /* Center elements horizontally */
    background-color: #f4f4f4;  /* Light background color */
    overflow-y: auto;
  }
  
  
  .sidebar {
    width: 15rem; /* Adjust width as needed */
    height: 100vh; /* Full height of the viewport */
    position: fixed; /* Fixed position */
    left: 0; /* Align to the left side */
    background-color: #333; /* Dark background color */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    z-index: 1000; /* High z-index to ensure it's above other elements */
    padding-top: 60px; /* Padding at the top */
  }
  
  .sidebar-list {
    list-style-type: none; /* Remove default list styling */
    padding: 0; /* Remove default padding */
    margin: 0; /* Remove default margin */
  }
  
  .sidebar-item {
    padding: 20px 16px; /* Padding for each item */
    text-align: left; /* Left align text */
    border-bottom: 1px solid #555; /* Separator between items */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s; /* Smooth background transition */
    border-radius: .5rem;
  }
  
  .sidebar-item:hover {
    background-color: #e7e7e7; /* Slightly lighter background on hover */
  }
  
  .APIsidebarContent {
    margin-left: 17rem;
  }
  .ActivityContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 1rem;
    position: relative;
    margin-left: 15rem;
    margin-right: 10rem;
}

.Activities-buttons {
    max-width: 10rem;
}

.ActivityCard {
    background-color: #00316f;
    padding: 1rem;
    margin: 10px 0;
    margin-left: 2rem;
    width: 30rem;
    border-radius: 10px;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
}

.ActivityDate {
    position: absolute;
    left: -140px;
    width: 100px;
    color: black;
}

.ActivityTimelinePoint {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #333;
    position: absolute;
    left: -16.5px;
    border: 3px solid #fff;
}

.ActivityContent {
    margin-left: 20px;
}

.ActivityContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: -7.5px;
    bottom: 0;
    width: 2px;
    background-color: #333;
}

.ActivityIcon {
    display: flex;
    margin-right: .5rem;
    align-items: center;
    justify-content: center;
}

.ActivityDetails {
    display: flex;
}

.activityButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #00316f;
    background-color: #f1f1f1;
    box-shadow: none;
    margin: 0rem;
    padding: .5rem;
    margin-left: 1rem;
}

.activityButton:hover {
    background-color: #0147DF;
    color: #f1f1f1;
}

/* Mobile optimization */
@media only screen and (max-width: 768px) {
    .ActivityContainer {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .ActivityCard {
        width: 100%;
        margin-left: 0;
    }

    .ActivityDate {
        position: relative;
        left: 0;
        margin-top: 0.5rem;
        color: #fff;
    }

    .ActivityTimelinePoint {
        left: 0;
        margin-top: 0.5rem;
    }

    .ActivityContainer::before {
        display: none;
    }

    .Activities-buttons {
        max-width: 100%;
    }
}

  

.MessageContainer {
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
  height: calc(100vh - 60px); /* Adjust 60px to your Topbar's height */
  box-sizing: border-box; /* Include padding in the height calculation */
}

.messagesList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  padding: 1rem 3rem;
  margin-bottom: 3.2rem;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutCenter {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  100% {
    opacity: 0;
    transform: scale(0);
  }
}

@keyframes message-wipe {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}

.message {
  display: flex;
  flex-direction: column; /* Stack children vertically */
  align-items: flex-start;
  background-color: #0147DF; /* Your messages color */
  color: #fff;
  border-radius: 20px;
  padding: 10px 15px;
  margin-bottom: 10px;
  word-wrap: break-word;
  max-width: 75%;
  opacity: 0; /* Start fully transparent */
  animation: fadeInUp 400ms ease-out forwards; /* Use forwards to maintain the final state */
}

.senderName, .content {
  align-self: flex-start; /* Ensure these are aligned to the start (left) */
  width: 100%; /* Take up full width to avoid squeezing by the timestamp */
  opacity: 75%;
}

.timestamp {
  align-self: flex-end; /* Align the timestamp to the end (right) */
  margin-top: auto; /* Push it to the bottom if there's vertical space */
  font-size: 0.75rem; /* Adjust as needed */
  color: #c0bfbf; /* Lighter color for visibility or match your design */
}

.message.sent {
  align-self: flex-end; /* Align your messages to the right */
  background-color: #0147DF; /* Or any color you prefer for your messages */
}

.message.received {
  align-self: flex-start; /* Align other users' messages to the left */
  background-color: #00316f; /* A lighter color for received messages */
}

.message-messageText {
  position: relative;
  display: inline-block;
  overflow: hidden;
  font-family: 'Courier New', Courier, monospace; /* Monospace font for equal character spacing */
}

.message-fadeOut {
  animation: fadeOutCenter 0.5s forwards; /* Quick fade-out animation */
}

.message-wipe {
  position: relative;
  white-space: wrap; /* Prevent text from wrapping */
  overflow: hidden;
  display: inline-block;
}

.message-wipe::before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background: inherit;
  z-index: 1;
  color: #fff; /* Color of the decrypted message text */
  overflow: hidden;
  white-space: nowrap;
  animation: message-wipe 1s forwards;
}

.message-lockIcon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 16px;
}

.message-senderName {
  font-weight: bold;
}

.message-timestamp {
  font-size: 0.8em;
  color: rgb(219, 219, 219);
}


  .textarea, .generateButton {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    box-sizing: border-box; /* Include padding in the width calculation */
  }
  
  .textarea {
    resize: none; /* Disable manual resize */
    border: 1px solid #ccc;
    border-radius: 20px;
    height: 3rem;
  }

  .sendButton {
    position: absolute;
    right: 1.25rem;
    margin-top: .75rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 1rem;
    background-color: #00316f;
    box-shadow: none;
  }

  .sendButton:hover {
    background-color: #0147DF;
    color: #fff;
  }
  
  
  /* Fixed position input area */
  .inputArea {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0rem;
    padding-bottom: 0rem;
    background-color: white; /* Match your theme */
    box-shadow: 0 -2px 5px rgba(0,0,0,0.1); /* Optional: adds a shadow for better separation */
    box-sizing: border-box;
    margin-bottom: 0;
  }
  
  .QueuePageContainer {
    width: 100vw;
    overflow: auto;
    margin-top: 3rem;
    text-align: center;
  }

  .textAreaAPI {
    height: 30vh;
  }