/* IntakeView Layout */
.intakeViewLayout {
    display: flex;
    height: 100vh;
  }
  
  .intakeViewContent {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin-top: 3rem;
    margin-left: 23%;
  }
  
  /* Patients List */
  .patientsList {
    flex-grow: 1;
    overflow-y: auto;
    height: 95vh;
    border: 1px solid #ddd;
    background-color: #fff;
  }
  
  .patientsTable {
    width: 100%;
    table-layout: fixed; /* Ensures fixed column widths */
    border-collapse: collapse; /* Consistent borders */
  }
  
  .patientsTable td {
    padding: 0;
    padding-left: 10px;
    padding-right: 10px;
    overflow: hidden; /* Prevent content overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflowing content */
    white-space: nowrap; /* Prevent line wrapping */
    border-bottom: 1px solid #ddd; /* Add a bottom border */
  }
  
  .patientCheckbox {
    width: 5%; /* Fixed width for the checkbox column */
    text-align: center; /* Center-align checkbox */
  }
  
  .patientRow {
    width: 35%; /* Fixed width for patient name */
    text-align: left;
    padding-left: 0.5rem; /* Add padding to separate from checkbox */
  }
  
  .patientIcons {
    text-align: center; /* Center content horizontally */
    vertical-align: middle; /* Center content vertically */
    color: #0147DF;
  }
  
  .patientIcons svg {
    display: inline-block;
    margin-right: 10px; /* Adjust spacing between icons */
  }
  
  .patientIcons svg:last-child {
    margin-right: 0; /* Remove margin for the last icon */
  }
  
  
  .patientDate {
    width: 40%; /* Fixed width for date */
    text-align: right;
  }
  
  
  
  .patientsTable tr {
    border-bottom: 1px solid #ddd;
  }
  
  .patientsTable tr:hover {
    background-color: #f0f0f0;
    cursor: pointer;
  }
  
  /* Loading State */
  .loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: #666;
  }
  
  /* Intake Toolbar */
  .intakeToolbar {
    margin-bottom: .5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
  }
  
  /* Sidebar Styles */
  .sidebarContainer {
    width: 20%;
    background-color: #f8f9fa;
    padding: 1rem;
    border-right: 1px solid #dee2e6;
  }
  
  .backButton {
    font-size: 1rem;
    font-weight: bold;
    color: #0147DF;
    cursor: pointer;
    margin-bottom: 1rem;
  }
  
  .backButton:hover {
    text-decoration: underline;
  }
  
  .clinicList {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .clinicItem {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    border-radius: 15px;
    transition: background-color 0.3s ease;
  }
  
  .clinicItem:hover {
    background-color: #e9ecef;
  }
  
  .selectedClinic {
    background-color: #0147DF;
    color: white;
  }
  
  .selectedClinic .clinicIcon {
    color: white;
  }
  
  .clinicIcon {
    margin-right: 0.5rem;
    color: #6c757d;
  }
  

/* Custom checkbox style */
.intake-checkbox {
    position: relative;
    min-width: 1rem;
    width: 1.75rem;
    height: 1.75rem;
    cursor: pointer;
    /* Override -webkit-appearance */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* Apply gradient effect when pressed */
    background: linear-gradient(to bottom, #e6e6e6 0%, #f9f9f9 100%);
    border-radius: 10px;
    border: 1px solid #ccc;
  }
  
  .intake-checkbox:checked {
    background: linear-gradient(135deg, #00316f, #0147df);
  }
  
  /* Add a checkmark when the checkbox is checked */
  .intake-checkbox:checked::before {
    content: "\2713";
    position: absolute;
    top: 3px;
    left: 5px;
    font-size: 12px;
    color: #ffffff;
  }
  
  /* Hide the default checkbox */
  .intake-checkbox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  
  /* Add a custom focus style */
  .intake-checkbox:focus {
    outline: none;
    box-shadow: 0 0 0 2px #6c9eeb;
    /* Adjust color as needed */
  }
  
  .intakeview-search-container {
    display: flex;
    align-items: center;
    margin-right: 1rem;
    position: relative;
  }
  
  .intakeview-search-bar {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 0.5rem;
    background-color: #f9f9f9;
    width: 100%;
    max-width: 300px;
    box-shadow: none;
    transition: background-color 0.3s ease, border-color 0.3s ease;
  }

  .intakeview-search-focused,
  .intakeview-search-bar.intakeview-search-focused {
    background-color: #fff;
    border-color: #00316f; /* Optional: Add a border highlight */
  }
  
  .intakeview-search-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem;
    background: none;
    box-shadow: none;
  }
  
  .intakeview-search-icon {
    margin-right: 0.5rem;
    color: #888;
    width: 30px;
    height: 30px;
  }
  
  .intakeview-filter-icon {
    margin-left: 0.5rem;
    cursor: pointer;
    color: #888;
    transition: color 0.2s ease;
    width: 30px;
    height: 30px;

  }
  
  .intakeview-filter-icon:hover {
    color: #00316f;
  }
  
  .intakeview-filter-item {
    margin-bottom: 1rem;
  }
  
  .intakeview-filter-input {
    margin-left: 0.5rem;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .intakeview-filter-checkbox {
    margin-right: 0.5rem;
  }
  
  .intakeview-filter-actions {
    display: flex;
    justify-content: space-between;
  }
  
  .intakeview-filter-button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 5px;
    background-color: #00316f;
    color: white;
    cursor: pointer;
    transition: background-color 0.2s ease;
  }
  
  .intakeview-filter-button:hover {
    background-color: #0056b3;
  }
  
  /* Toolbar Layout */
.intakeview-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #ddd;
  }
  
  /* Left Section */
  .intakeview-toolbar-left {
    display: flex;
    align-items: center;
  }
  
  .intake-checkbox {
    margin-right: 0.5rem;
  }
  
  .intakeview-refresh-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    font-size: 1.2rem;
    transition: color 0.2s ease;
    box-shadow: none;
  }
  
  .intakeview-refresh-button:hover {
    color: #0147df;
  }
  
  /* Center Section */
  .intakeview-toolbar-center {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
  
  /* Right Section */
  .intakeview-toolbar-right {
    display: flex;
    align-items: center;
    gap: 0.5rem; /* Space between buttons */
  }
  
  .patientDetails {
    padding-left: 20%;
    padding-right: 20%;
  }