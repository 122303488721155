.FormEditorContainer {
  display: flex;
  width: 100%;
  min-height: 500px;
}

.PaletteContainer {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  height: calc(100% - 00px);
  min-height: 300px;
  position: fixed;
  right: 25px;
  top: 90px;
  width: 180px;
}

.PaletteContainerPreview {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  height: calc(100% - 90px);
  min-height: 260px;
  right: 25px;
  top: 90px;
  width: 180px;
}

.PaletteDroppable {
  height: calc(100% - 55px);
  overflow: scroll;
}

.PaletteDroppablePreivew {
  height: calc(100% - 55px);
  overflow: scroll;
}

.FormCanvasContainer {
  height: calc(100vh - 80px);
  margin-top: 10px;
  margin-right: 10px;
  width: calc(100% - 240px);
}

@media screen and (min-width: 1026px) {
  .FormCanvasContainer {
    width: calc(100% - 200px);
  }
}

@media screen and (min-width: 1440px) {
  .FormCanvasContainer {
    width: calc(100% - 180px);
  }
}

@media screen and (min-width: 1820px) {
  .FormCanvasContainer {
    width: 100%;
  }
}

.PaletteFormElementCard {
  margin: 10px;
  background-color: rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  border-radius: 10px;
  text-align: center;
}

.DragItem {
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  margin: 0 0 20px 0;
  display: grid;
  grid-template-columns: calc(100% - 60px) 60px;
}

.MainContainer {
  margin-top: 27px;
  margin-bottom: auto;
}

.SideContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0 8px 8px 0;
}

.TrashButton {
  background-color: rgba(255, 0, 0, 0.7);
}

.TrashButton:hover {
  background-color: rgba(255, 0, 0, 0.7);
  transform: scale(1.1);
}

.ExpandableButton:hover {
  transform: scale(1.1);
}

.ExpandableArea {
  position: absolute;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0s ease;
}

.ExpandableArea.open {
  position: relative;
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 1.8s ease;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #ededed;
}

.Preview {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 15px;
}

.border-buttom {
  border-bottom: 1px solid #ccc;
}

.FormElementsContainer {
  padding-bottom: 32px;
  display: grid;
  flex-wrap: wrap;
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 1px;
  padding-bottom: 25px;
  padding-top: 25px;
}

.FormElementsContainer > * {
  margin-right: 10px;
  margin-bottom: 10px;
}

.SaveButton {
  width: 160px;
  position: absolute;
  bottom: 0;
}

.SaveButtonPreview {
  width: 160px;
  bottom: 0;
}

.PaletteFormElementCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.iconContainer {
  margin-left: 10px;
}

.RequiredMark {
  color: rgba(255, 0, 0, 0.7);
  font-size: 16px;
  font-weight: bolder;
}

.headerForm {
  font-weight: bolder;
  margin-left: 8px;
  margin-bottom: 2px !important;
}

hr {
  border-top: 1px solid #ccc;
  width: 100%;
  margin: 0;
  padding: 0;
  height: 0;
}

textarea {
  border: none;
  font-family: inherit;
  color: inherit;
  resize: none;
  width: calc(100% - 10px) !important;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 18px;
  margin-right: 10px;
  margin-bottom: 10px;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background: #00316f;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background: #00316f;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"] {
  height: 10px;
  border-radius: 50px;
}

.OptionInput {
  width: calc(100% - 95px);
}

.ChoiceOption {
  display: flex;
  margin: 16px;
}

.TitleInput {
  font-size: 28px;
  opacity: 90%;
  font-weight: 100;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  width: 200px;
}

.FormCanvasDroppable {
  position: relative;
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 256px;
  min-height: calc(100vh - 300px);
}

.FormCanvasDroppablePreview {
  position: relative;
  border: 2px dashed #ccc;
  padding: 20px;
  border-radius: 16px;
  margin-bottom: 20px;
  min-height: calc(100vh - 300px);
  width: calc(100% - 90px);
}

.FormCanvasDroppable.dragOver {
  border-color: #0147df;
}

.emptyCanvas {
  height: 200px;
}

.emptyCanvasMessage {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #999; 
  font-size: 24px;
  text-align: center;
}

.FormRow {
  display: block;
}

.FormElement {
  width: 33vw;
  margin-left: auto;
  margin-right: auto;
}

.h-250px {
  height: 250px;
}

.w-100px {
  width: 100px;
}

/* Option Card styling without the border */
.customOptionCard {
  background-color: #fff;
  /* Border removed as requested */
  border-radius: 8px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding-bottom: 8px;
}

.customCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #eee;
}

.customCardBody {
  padding: 0.75rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.customFieldGroup {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.customOptionInput {
  flex: 1;
  padding: 0.5rem;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 6px;
}

.icd10Container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.icd10Badge {
  display: inline-flex;
  align-items: center;
  background-color: #f1f1f1;
  border-radius: 16px;
  padding: 0.3rem 0.75rem;
  font-size: 14px;
}

.removeBadge {
  background: none;
  border: none;
  margin-left: 0.4rem;
  cursor: pointer;
  color: #888;
  font-size: 14px;
  transition: color 0.2s ease;
}

.removeBadge:hover {
  color: #e63946;
}

.searchContainer {
  position: relative;
}

.searchResults {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background-color: #f1f1f1;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  z-index: 1000;
}

.searchResultItem {
  padding: 0.5rem;
  cursor: pointer;
}

.searchResultItem:hover {
  background-color: #ddd;
}

.searchInputWrapper {
  position: relative;
  width: 100%;
}

.searchInput {
  box-sizing: border-box;
  width: 100%;
  padding-right: 40px; /* increased padding to the right for the icon */
}

.searchIconContainer {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #6c757d;
  pointer-events: none;
}

.searchIcon {
  font-size: 14px;
}

.spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.resultCode {
  font-weight: bold;
  margin-right: 8px;
  display: inline-block;
}

.resultDescription {
  display: inline-block;
}

.noResults {
  padding: 8px;
  color: #6c757d;
  text-align: center;
  background: #f8f9fa;
  border-radius: 4px;
  margin-top: 4px;
}

/* Updated Add Option Button styling with extra rounding */
.customAddOptionButton {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  border: none;
  background-color: #00316f;
  color: #fff;
  border-radius: 10px; /* increased rounding */
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s ease;
  display: inline-flex;
  align-items: center;
}

.customAddOptionButton:hover {
  background-color: #0147df;
}

.optionCard {
  background: white;
  border-radius: 12px;
  padding: 12px;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.optionCard:hover {
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.optionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.optionInput:focus {
  border-color: #007AFF;
}

.trashButton:hover {
  transform: scale(1.1);
}

.icd10Badge {
  background: #F6F6F6;
  border-radius: 20px;
  padding: 6px 12px;
  display: inline-flex;
  align-items: center;
}

.removeBadge {
  border: none;
  background: none;
  color: #888;
  margin-left: 6px;
}

.removeBadge:hover {
  color: red;
}
