.api-guides-page {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    background-color: #ffffff;
}

.guide-layout {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.back-button {
    align-self: flex-start;
    margin-bottom: 1rem;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    color: #ffffff;
    background-color: #00316f;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.back-button:hover {
    background-color: #0147df;
    color: #ffffff;
}

.guide-content {
    width: 100%;
    padding: 2rem;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.guide-list h2 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.guide-list ul {
    list-style: none;
    padding: 0;
}

.guide-list li {
    margin: 1rem 0;
}

.guide-link {
    display: inline-block;
    padding: 0.5rem 1rem;
    font-size: 1.1rem;
    color: #0147df;
    background: none;
    border: none;
    text-decoration: underline;
    cursor: pointer;
    transition: color 0.2s;
}

.guide-link:hover {
    color: #0147df;
    color: #fff;
}

.guide-page {
    margin-left: 10vw;
    margin-right: 10vw;

}

.swagger-ui .microlight, 
.swagger-ui pre, 
.swagger-ui code {
    text-align: left !important; /* Force left alignment */
}

.guides-container {
    max-width: 800px;
    display: block;
    justify-content: center;
    align-items: center;
    margin-left: 10vw;
}

.postman-button-container {
    margin-top: 20px;
    text-align: left;
}

.postman-button {
    display: flex;
    align-items: center;
    background-color: #ff6c37; /* Postman orange */
    color: white;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: background-color 0.3s ease;
}

.postman-button:hover {
    background-color: #e85c2c; /* Darker Postman orange for hover */
}

.postman-logo {
    height: 20px;
    margin-left: 10px;
}

.play-icon {
    height: 20px;
    margin-right: 10px;
}
