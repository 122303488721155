/* Static Background Setup */
.gradient-skewed-bottom,
.gradient-skewed-top {
  background: linear-gradient(
    45deg,
    #00316f,
    #0147df,
    #eb2222,
    #EBD63D,
    #00316f
  );
  background-size: 400% 400%;
  animation: gradient-move 20s ease-in-out infinite both;
}

/* Keyframes for background position animation */
@keyframes gradient-move {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Bottom Gradient */
.gradient-skewed-bottom {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150vw;
  height: 150vh;
  transform: translate(-50%, -50%);
  clip-path: polygon(
    0% 80%,
    100% 30%,
    100% 100%,
    0% 100%
  );
  z-index: -2;
}

/* Top Gradient */
.gradient-skewed-top {
  position: fixed;
  top: 0;
  left: 0;
  width: 120vw;
  height: 120vh;
  transform: rotate(180deg) scaleX(-1);
  clip-path: polygon(
    0% 60%,
    100% 95%,
    100% 100%,
    0% 100%
  );
  z-index: -1;
}

/* Pseudo-elements for Blur Effects */
.gradient-skewed-top::before,
.gradient-skewed-bottom::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: inherit;
  filter: blur(30px);
  opacity: 0.3;
  z-index: -1;
  pointer-events: none;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .gradient-skewed-bottom,
  .gradient-skewed-top {
    width: 160vw;
    height: 160vh;
  }
  .gradient-skewed-bottom {
    clip-path: polygon(0 85%, 100% 35%, 100% 100%, 0% 100%);
  }
  .gradient-skewed-top {
    clip-path: polygon(0% 60%, 100% 95%, 100% 100%, 0% 100%);
  }
}
