.background {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-size: cover;
  min-height: 100vh;
  width: 100%;
}

.container {
  margin-left: 64px;
  margin-right: 64px;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 200px);
  position: relative;
}

.page-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
  width: 100%;
  min-height: 100vh;
}

.content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-left: 200px;
  flex-grow: 1;
  opacity: 100%;
}

.folderControlsContainer {
  position: fixed;
  left: 0;
  margin-top: 2.5rem;
  height: 95%;
  width: 13%;
  background-color: rgb(243, 243, 245);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  z-index: 0;
  overflow-y: auto;
}

.folderList {
  list-style-type: none;
  padding-left: 0px;
  padding-right: 5%;
  margin-top: 10px;
}

.folder {
  margin-bottom: 10px;
}

.folderIcon {
  margin-right: .25rem;
  margin-left: .25rem;
}

.titleBox {
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 4%;
}

.firstRow {
  display: fixed;
  align-items: center;
  width: 100%;
}

.secondRow {
  display: fixed;
  align-items: center;
  width: 100%;
}

/* Base (desktop) */
.patientViewBar {
  position: absolute;
  top: 2.75rem;
  /* The offset from the top, matching your margin-top usage */
  left: 13%;
  width: 20%;
  /* Use the full viewport height minus your top offset, so it doesn't overflow */
  height: calc(100vh - 2.75rem);
  overflow-y: auto;
  background-color: white;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.patientWindow {
  position: absolute;
  right: 0px;
  top: 0;
  width: 67%;
  height: calc(100vh - 2.75rem);
  margin-top: 2.8rem;
  background-color: white;
  overflow: auto;
}

.clinicWindow {
  position: fixed;
  right: 0px;
  bottom: 0rem;
  width: 67%;
  height: calc(100vh - 2.75rem);
  background-color: white;
  overflow: auto;
}

/* Only show buttons on mobile view */
@media (max-width: 709px) {

  .clinicWindow {
    width: 100% !important;
    margin-top: 3rem;
  }

  .ClaimContainer {
    width: 100% !important;

  }

}


.actionContainer {
  position: relative;
  overflow-y: scroll;
  height: 95%;
  bottom: 0;
}

.ClaimContainer {
  position: fixed;
  right: 0px;
  width: 67%;
  height: 95%;
  top: 0;
  background-color: var(--color-background);
  color: var(--color-text);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2.8rem;
  z-index: 4;
}



.EligibilityContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.collectionsContainer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: 95%;
  overflow: hidden;
}

.leftColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  margin-top: 20px;
}

.rightColumn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  margin-top: 20px;

}


.file-input {
  margin-top: 20px;
}

.file-input input {
  margin-bottom: 10px;
}

.progressBar {
  width: 100%;
  margin-top: 250px;
}

.outerbar {
  width: 100%;
  background-color: #ddd;
  border-radius: 10px;
}

.innerbar {
  background-color: #4CAF50;
  height: 20px;
  text-align: center;
  color: white;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.section {
  width: 80%;
  margin: 5px;
  align-items: center;
}



.requestsContainer {
  max-height: 500px;
  overflow: auto;
}

.totalRequestsContainer {
  display: fixed;
  justify-content: bottom;
  align-items: center;
  height: 50px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px;
  font-weight: bold;
  font-size: 18px;
}

button {
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  margin: 10px;
}

.button-favorite {
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  margin: 10px;
  display: flex;
  align-items: center;
  gap: 10px
}

.table {
  width: 100%;
  max-width: 800px;
  border-collapse: collapse;
}

.table th,
.table td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
}

.table thead {
  background-color: #f2f2f2;
}

.table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

.payerSelection label {
  margin-right: 10px;
}

.formContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.filesTable {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 700px;
  overflow-y: scroll;
  padding: 20px;
}

.filesTable th,
.filesTable td {
  border: 1px solid #e0e0e0;
  padding: 8px;
  text-align: left;
  font-size: 14px;
  /* Adjust font size */
}

.filesTable thead {
  background-color: #f2f2f2;
}

.filesTable tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}



.fileName {
  margin-left: 1rem;
}

.fileItem {
  display: grid;
  align-items: center;
}

.deleteBtn {
  padding: 7px;
  border-radius: 10px;
  background-color: #f30c0c;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  width: 100%;
  margin-bottom: 20px;
  /* Add margin-bottom to create space between buttons */

}

.downloadBtn {
  padding: 7px;
  border-radius: 5px;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  margin-top: 20px;
  /* Add margin-top to create space between buttons */

}



.columns {
  display: flex;
}

.scrollingTable {
  height: 100%;
  overflow-x: hidden;
  margin-top: 12px;
  overflow-y: hidden;
}

table {
  overflow-y: auto;
  text-align: center;
}

table td {
  border-left: 0 !important;
  border-right: 0 !important;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.error-message {
  color: #ff4d4d;
  /* Red color for error text */
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  border: 1px solid #ff4d4d;
  /* Red border for the container */
  border-radius: 10px;
  padding: 10px;
  background-color: #ffe6e6;
  /* Light red background color */
  max-width: 400px;
  text-align: center;
}

.actions {
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}

.patientFiles {
  padding-left: 8px;
  padding-right: 8px;
  padding-top: 6px;
}

.patientDataContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-top: -3rem;
}

.action-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}


.folderSelectContainer {
  padding: 20px;

}

.patientDataContainer .patientDataTitle .initialsCircleContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.patientDataContainer .patientDataTitle .initialsCircle {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: white;
  font-size: 50px;
  text-align: center;
  line-height: 100px;
  margin-bottom: 1rem;
  display: block;
  margin-left: 0;
  margin-right: 0;
}

.patientDataContainer .patientDataTitle .name {
  margin-top: .5rem;
}

.dobandgender {
  text-align: center;
  margin-top: -1rem;
}

.actions .action-button-container button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 30vw;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  max-width: 150px;
}

.actions .action-button-container button.selected {
  background-image: none;
  background-color: #0147DF;
  /* Change as needed */
  color: white;
  /* Change as needed */
}



.icon-container {
  margin-bottom: 10px;
}

.singleClaimContainer {
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
  margin-top: 40px;
}

.search-input-patients {
  width: calc(100% - 25px) !important;
  min-width: 100px;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 10px;
}


.selected-patient {
  background-color: rgba(0, 0, 0, 0.1);
}

.selectedFolder {
  background-color: rgba(0, 0, 0, 0.1);
  padding: .5rem;
  border-radius: 10px;
}

/* Style the checkbox container */
.checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkbox {
  position: absolute;
  top: -1.2rem;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.selectAllCheckbox {
  top: -0.5rem !important;
  left: .6rem !important;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkbox {
  background-color: #00316f;
  border: 1px solid #00316f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark {
  position: relative;
  top: 5px;
  left: 9px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkbox .checkmark {
  display: block;
}

.trashButton {
  background: transparent;
  box-shadow: none;
  color: #f30c0c;
  margin-top: 0rem;
}

.downloadButton {
  background: transparent;
  box-shadow: none;
  color: #00316f;
  margin-top: 0rem;
}


.AddProviderModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  outline: none;
  width: 80%;
  height: 70%;
  max-width: 800px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.selectedSortButton {
  background-color: #00316f;
  /* Darker shade for selected state */
}

.checkoutPatients {
  padding-left: 1rem;
  padding-right: 2rem;
  padding-top: 3rem;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 95%;
}

.invalid-row {
  background-color: #ffb3b3;
  /* for example, a light red to indicate error */
}

.inline-error-message {
  margin: 0;
  /* Remove default margins */
  padding: 5px 10px;
  /* Add some padding for visual space */
  display: inline-flex;
  /* Make sure the error messages act like inline elements */
}

.selectAllButton {
  border: none;
  align-items: center;
  cursor: pointer;

}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px;
  width: 80%;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
}

.payerSearchTable {
  height: 30rem;
  overflow-y: scroll;
}

.payerMatchSearch {
  padding: 1rem;
}

.payerSearchTable li {
  list-style: none;
  padding: 5px;
  cursor: pointer;
}

table tr:last-child {
  border-bottom: none;
  /* Remove the border for the last row */
}

.payerNameForm {
  padding-top: 1rem;
}

.payerMatchContent {
  max-height: 40rem;
}

.quickSearchHelper {
  margin-top: 5rem;
}


.scrollToTopButton {
  position: fixed;
  top: 4rem;
  left: 20%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  visibility: visible;
  /* Hide the button initially */
  opacity: 0;
  /* Start with 0 opacity */
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  /* Add transition for visibility */
}

.scrollToTopButton.visible {
  visibility: visible;
  /* Show the button when .visible is added */
  opacity: 1;
  /* Full opacity when the .visible class is added */
}

.patientControls {
  display: flex;
}

.multiControls {
  display: flex;
}

.patientListName {
  display: flex;
  align-items: center;
  /* Ensure vertical center alignment */
  justify-content: space-between;
  /* Align items on opposite ends */
  text-align: left;
  hyphens: auto;
  overflow-wrap: break-word;
  word-break: break-word;
}

.patient-name {
  white-space: nowrap;
}

.patient-flag {
  display: flex;
  align-items: center;
  /* Align flag icon vertically */
}



.unbilledIndicator {
  color: green;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  /* This is to vertically center the $ symbol */
}


.EncountersContainer {
  padding: 2rem;
  margin-top: 2rem;

}

.encounterCardContent {
  padding: .5rem;
}

.largerIcon svg-inline--fa {
  height: 44px;
  /* Adjust the size as needed */
}

.modalContent {
  padding: 1rem;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timerContainer {
  display: flex;
  align-items: center;
  color: #0147DF;
  padding-left: .5rem;
  height: 0rem;
}

.timerText {
  margin-left: 10px;
  padding: 0;
  /* Set padding to 0 */
}

@media (max-width: 708px) {

  .patientControls {
    display: flex;
    grid-template-columns: repeat(4, 1fr);
    /* Creates two columns */
    grid-template-rows: repeat(2, auto);
  }

  .ClinicsButton {
    display: block !important;
    padding-top: 5px;
    padding-bottom: 12px;
  }
}

.ClinicsButton {
  display: none;
}

@media (max-width: 708px) {


  .fullWidthSideBar {
    width: 100% !important;
  }

  .content {
    margin-left: 0 !important;
  }

  .patientWindow {
    position: absolute;
    right: 0px;
    top: 0;
    width: 100%;
    height: 95%;
    margin-top: 2.8rem;
    background-color: white;
    overflow: auto;
  }


  .folderControlsContainer {
    width: 0%;
    display: none;
  }

  .patientViewBar {
    left: 0%;
    width: 0%;
  }

  .patientDataContainer {
    width: 100%;
    padding-left: 2px;
    padding-right: 2px;
  }

  .action-button-container {
    /* flex-direction: column; */
  }

  .scrollToTopButton {
    left: 8%;
  }
}

.matchedSearchText {
  display: block;
  width: 100%;
  text-align: right;
  padding-right: 2px;
}

@media (max-width: 900px) {


  .patientListName {
    display: flex;
  }

  .patient-name {
    white-space: nowrap;
  }
}

@media (max-width: 500px) {
  .patient-name {
    white-space: nowrap;
  }
}

.removeSecondaryPayerButton {
  color: #0147DF;
  text-decoration: underline;
  background: none;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
}

.removeSecondaryPayerButton:hover {
  color: #002a80;
  /* Darker blue for hover effect */
}


/* Dropdown container */
.patientSelect-dropdown {
  position: absolute;
  display: inline;
  margin-top: -.4rem;
  margin-left: -.5rem;
  z-index: 10;
}

/* Dropdown toggle button with carrot icon */
.patientSelect-dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  transition: transform 0.2s;
  box-shadow: none;
}

.patientSelect-dropdown-container {
  margin-right: 1rem;
}

.patientSelect-dropdown-toggle:hover {
  background-color: #ffffff;
  color: #0147DF;
}

/* Dropdown menu */
.patientSelect-dropdown-menu {
  position: absolute;
  top: 110%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  margin-top: 5px;
  min-width: 180px;
  overflow: hidden;
  animation: patientSelect-fadeIn 0.2s ease-in-out;
  z-index: 5;
}

@keyframes patientSelect-fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Dropdown items */
.patientSelect-dropdown-item {
  padding: 10px 15px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.patientSelect-dropdown-item:hover {
  background-color: #f5f5f5;
}

.patientSelect-dropdown-item.active {
  background-color: #e0f7fa;
  font-weight: bold;
}

/* Active dropdown item on hover */
.patientSelect-dropdown-item.active:hover {
  background-color: #d4eaf7;
}

.cancelButtonSelect {
  margin-left: 1rem;
}

/* Base style for toggle buttons hugging the screen edge, thinner width */
.toggleSidebarBtn {
  position: fixed;
  left: 0;
  width: 30px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00316f;
  color: #fff;
  border: none;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
  font-size: 18px;
  /* Remove any default margin/padding to avoid gaps */
  margin: 0;
  padding: 0;
}

/* Stacking buttons vertically */
.folderBtn {
  top: 10%;
  border-radius: 0 5px 5px 0;
}

.patientBtn {
  top: 18%;
  border-radius: 0 5px 5px 0;
}

/* When active, move buttons to the right edge and adjust border radius */
.folderBtn.active,
.patientBtn.active {
  left: auto;
  right: 0;
  /* Round the opposite (left) side when on the right edge */
  border-radius: 5px 0 0 5px;
}

/* Hide sidebars by default on mobile view */
.folderControlsContainer.hidden,
.patientViewBar.hidden {
  display: none;
}

/* Ensure sidebars with slide-out animation remain visible */
.folderControlsContainer.hidden.slide-out,
.patientViewBar.hidden.slide-out {
  display: block !important;
}

/* Show sidebar with slide-in animation */
.folderControlsContainer.visible,
.patientViewBar.visible {
  display: block;
  animation: slideIn 0.3s forwards;
}

/* Slide-in animation keyframes */
@keyframes slideIn {
  from {
    transform: translateX(-100%);
  }

  to {
    transform: translateX(0);
  }
}

/* Slide-out animation keyframes */
@keyframes slideOut {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
  }
}

/* Apply slide-out animation */
.folderControlsContainer.slide-out,
.patientViewBar.slide-out {
  animation: slideOut 0.3s forwards;
}

/* Only show buttons on mobile view */
@media (min-width: 709px) {
  .toggleSidebarBtn {
    display: none;
  }

  /* Always display sidebars on full screen/desktop regardless of .hidden class */
  .folderControlsContainer,
  .patientViewBar {
    display: block !important;
  }
}

/* Ensure sidebars can animate smoothly */
.fullWidthSideBar {
  transition: transform 0.3s ease, visibility 0.3s ease;
}

/* Visible sidebar with slide-in animation */
.fullWidthSideBar.visible {
  animation: slideIn 0.3s forwards;
}

/* Sliding out sidebar */
.fullWidthSideBar.slide-out {
  animation: slideOut 0.3s forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }
  to {
    transform: translateX(-100%);
    opacity: 0;
  }
}