.doctors-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    padding-bottom: 0;
}

.add-note {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
}

.note-title {
    width: 100%;
    margin-left: 0;
}

.add-note h2 {
    margin-bottom: 20px;
    color: #333;
}

textarea {
    border: none;
    font-family: inherit;
    color: inherit;
    resize: none;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    font-size: 18px;
}

.submit-button {
    padding: 10px 20px !important;
    border: none !important;
    background-color: #00316f !important;
    color: #fff !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    margin: auto;
}

.submit-button:hover {
    background-image: linear-gradient(135deg, #00316f, #0147DF);
}

.doctors-note .response {
    width: 60vw;
    color: #333;
    white-space: pre-wrap;
    word-wrap: break-word;
}

.doctors-note .response pre {
    margin-top: 20px;
    width: 100%;
    color: #333;
    white-space: pre-wrap;
    word-wrap: break-word;
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 10px;
    background-color: #f9f9f9;
}

.response-edit {
    width: 100%;
    height: 220px !important;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    overflow-y: auto;
    font-size: 18px;
}

.highlight {
    background-color: #00316f;
    ;
}

.button-group {
    display: flex;
    justify-content: space-between;
}

.rm-220 {
    margin-right: 220px;
}

.next-button {
    background-color: rgb(0, 49, 111);
    color: white;
}

.back-button {
    background-color: white;
    color: #010101;
}

.next-button:hover {
    background-color: white;
    color: #010101;
}

.back-button:hover {
    background-color: rgb(0, 49, 111);
    color: white;
}

.congratulations-message {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.congratulations-message svg {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0 auto;
}

.congratulations-message h2 {
    color: rgb(0, 49, 111);
    margin-top: 10px;
}

.congratulations-message p {
    font-size: 16px;
    color: #333;
    margin: 8px 0;
}

.success-message {
    text-align: center;
    font-size: 18px;
    color: rgb(0, 49, 111);
    margin-top: 10px;
}

.m-b {
    margin-bottom: 20px;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.greyButton {
    border-radius: 32px;
    border: 1px;
    background-color: #00000000;
    border-color: #010101;
    color: #010101;
}

.selectedButton {
    border-radius: 32px;
    border: 1px;
    background-color: rgb(0, 49, 111);
    border-color: #010101;
    color: #fff;
}

.buttonsContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
}

.default-text {
    color: transparent;
    border: none;
    background-color: transparent;
    outline: none;
    resize: none;
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.greyButton {
    border-radius: 32px;
    border: 1px;
    background-color: #00000000;
    border-color: #010101;
    color: #010101;
}

.selectedButton {
    border-radius: 32px;
    border: 1px;
    background-color: rgb(0, 49, 111);
    border-color: #010101;
    color: #fff;
}

.deleteNoteButton {
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(255, 0, 0, 0.3);
    margin-left: 10px;
    box-shadow: none;
}

.deleteNoteButton:hover {
    color: rgba(255, 0, 0, 0.7);
}

.noteItem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
}

.noteItem:hover {
    background-color: #f9f7ff !important;
    cursor: pointer;
}

.noteContent {
    flex: 1;
    margin-right: 10px;
}

.fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.deleteFileButton {
    background: none;
    border: none;
    cursor: pointer;
    color: rgba(255, 0, 0, 0.3);
    box-shadow: none;
}

.deleteFileButton:hover {
    color: rgba(255, 0, 0, 0.7);
}

.notesContainer {
    padding: 4px 32px 0px 32px;
    border-radius: 8px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.notes {
    width: 100%;
    max-width: 600px;
    margin-top: 20px;
}

.notes ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.notes li {
    margin-bottom: 10px;
    border-color: #ccc;
    border-radius: 8px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
    transition: transform 0.2s ease, opacity 0.2s ease;
}

.notes li:hover {
    border-color: rgb(0, 49, 111);
    /* transform: scale(1.05);  */
    opacity: 0.9;
}


.error-message {
    margin: auto;
    margin-bottom: 20px;

}

.notePreview {
    margin-top: 5px;
    color:#575757;
}

.noteActions {
    display: flex;
}

.expandedNoteContent {
    margin-top: 10px;
}

.confirmationDialog {
    width: 162px;
    margin-right: 20px;
}

.confirmationDialog p {
    margin-bottom: 6px;
}

.notesContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  
  .noteCard.collapsed .notePreview {
    max-height: 50px; /* Adjust based on your preference */
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .noteCard.expanded .notePreview {
    max-height: none;
    overflow: visible;
  }
  
  .noteCard.expanded {
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  }
  
  .noteCard {
    background-color: #00316f;
    /* This is a shade of blue */
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    margin: 10px 0;
    border-radius: 10px;
    color: white;
    /* Makes the text inside the card white */
    cursor: pointer;
    /* Indicates to users that the card is clickable */
    transition: background-color 0.3s;
    /* Smooth transition for hover effect */
  }


@media (max-width: 500px) {
    .noteItem{
        display: inline-block;
    }
};
