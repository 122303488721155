.single-article-container {
    margin-top: 50px;
    max-width: 980px;
    display: flex;
    flex-direction: column;
    margin: auto;
    min-height: 90vh;
}

@media (max-width: 1100px) {
    .single-article-container {
        max-width: fit-content;
        padding-left: 3%;
        padding-right: 3%;
    }

}


.article-title {
    margin-top: 20px;
    font-size: 30px;
    margin-bottom: 20px;
    color: #00316f;
}

.article-content {
    font-size: 18px;
    line-height: 1.6;
}

.article-content a {
    color: #3498db;
    text-decoration: none;
}

.article-content a:hover {
    text-decoration: underline;
}

.article-content img {
    max-width: 100%;
    height: auto;
    max-height: 30rem;
    justify-content: center;
}

.helper-container {
    display: flex;
    align-items: normal;
    text-align: left;
    flex-direction: column;
    margin-top: 1rem;
    width: 100%;
}

.need-help {
    color: #00316f;
    cursor: pointer;
}

.help-article-wide {
    align-items: center;
    justify-content: left;
    padding-bottom: 3rem;
    padding-left: 3rem;
}