.main {
    justify-content: space-between;
    margin-top: 3rem;
    right: 0;
  }

  p {
    font-size: 16px;
  }
  
  .header {
    width: auto;
  }
  
  .header p {
    text-align: center;
  }
  
  .cloudform {
    position: relative;
    width: 80%;
  }
  
  .textareaai {
    position: relative;
    resize: none;
    font-size: 1.1rem;
    padding: 1rem 2rem 1rem 2rem;
    width:  100%;
    border-radius: 0.5rem;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    background: #ffffff;
    color: #000;
    outline: none;
    border-color: #ffffff;
    height: 20px;
  }

  .cloud {
    width: 100%;
    height: 80vh;
    background: #ffffff;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-right: .5rem;
  }



  @media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) {
    .cloud {
      height: 65vh; /* Adjust the height as needed for landscape iPad */
    }
  }
  

  
      .cloudSpecial {
        height: 50vh;
      }

      

  
  
  .textarea:disabled {
    opacity: 0.5;
  }
  
  .textarea:focus {
    outline: none;
    border-color: #6b7280;
    box-shadow: 0 0 0 3px rgba(156, 163, 175, 0.5);
  }
  
  .textarea::placeholder {
    color: #6b7280;
  }
  
  .generatebutton {
    position: absolute;
    top: 0.87rem;
    right: -1rem;
    color: rgb(165, 162, 162);
    background: none;
    padding: 0.3rem;
    border: none;
    display: flex;
    margin: 0;
    box-shadow: none;
  }

  
  
  .loadingwheel {
    position: absolute;
    top: 0.2rem;
    right: 0.25rem;
  }
  
  .svgicon {
    transform: rotate(90deg);
    width: 1.2em;
    height: 1.2em;
    fill: currentColor;
  }
  
  .generatebutton:hover {
    background: #e4575726;
    border-radius: 0.2rem;
  }
  
  .generatebutton:disabled {
    opacity: 0.9;
    cursor: not-allowed;
    background: none;
  }
  
  .messagelist {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    border-radius: 0.5rem;
  }
  
  .messagelistloading {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .usermessage {
    background: #ffffff;
    padding: 1.5rem;
    color: #000;
  }
  
  .usermessagewaiting {
    padding: 1.5rem;
    color: #000;
    background: linear-gradient(to left, #07080938, #1a1c2024, #07080936);
    background-size: 200% 200%;
    background-position: -100% 0;
    animation: loading-gradient 2s ease-in-out infinite;
    animation-direction: alternate;
    animation-name: loading-gradient;
  }
  
  @keyframes loading-gradient {
    0% {
      background-position: -100% 0;
    }
    100% {
      background-position: 100% 0;
    }
  }
  
  .apimessage {
    background: #f9fafb;
    padding: 1.5rem;
    color: #000;
    animation: fadein 0.5s;
  }
  
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .typingAnimation {
    overflow: hidden;
    white-space: nowrap;
    border-right: .15em solid;
    animation: typing 14s steps(30, end), blink-caret .5s step-end infinite;
  }
  
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
  
  @keyframes blink-caret {
    from, to {
      border-color: transparent;
    }
    50% {
      border-color: black;
    }
  }
  
  
  .apimessage,
  .usermessage,
  .usermessagewaiting {
    display: flex;
    padding-left: 15%;
    padding-right: 20%;
  }
  
  .markdownanswer {
    line-height: 1.75;
    margin-top: -1rem;
  }
  
  .markdownanswer a:hover {
    opacity: 0.8;
  }
  
  .markdownanswer a {
    color: #b13a3a;
    font-weight: 500;
  }
  
  .markdownanswer code {
    color: #15cb19;
    font-weight: 500;
    white-space: pre-wrap !important;
  }
  
  .markdownanswer ol,
  .markdownanswer ul {
    margin: 1rem;
  }
  
  .boticon,
  .usericon {
    margin-right: 2rem;
    border-radius: 0.1rem;
    height: 3rem;
    width: auto;
  }
  
  .markdownanswer h1,
  .markdownanswer h2,
  .markdownanswer h3 {
    font-size: inherit;
  }
  
  .center {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding-top: 1rem;
    flex-direction: column;
    right: 0;
  }
  
  
  
  .pointsnormal {
    width: 90%;
    height: 90%;
  }
  
  .pointsdim {
    width: 90%;
    height: 90%;
    opacity: 0.25;
  }
  
  .footer {
    color: #5f6368;
    font-size: 0.8rem;
    margin: 1.5rem;
  }
  
  .footer a {
    font-weight: 500;
    color: #7a7d81;
  }
  
  .footer a:hover {
    opacity: 0.8;
  }
  
  /* Mobile optimization */
  @media (max-width: 600px) {
    .main {
      padding: .5rem;
    }
  
    .cloud {
      width: 100%;
    }
    .textarea {
      width: 22rem;
    }
    .topnav {
      border: 1px solid black;
      align-items: center;
      padding: 0.85rem 0.75rem 0.85rem 0.75rem;
    }
  
    .navlogo {
      font-size: 1.25rem;
      width: 20rem;
    }
  
    .markdownanswer code {
      white-space: pre-wrap !important;
    }
  
    .footer {
      font-size: 0.7rem;
      width: 100%;
      text-align: center;
    }

    .apimessage,
  .usermessage,
  .usermessagewaiting {
    display: flex;
    padding-left: 1%;
    padding-right: 2%;
  }

  .textarea {
    padding: 1rem 2rem 1rem 2rem;
    width:  100%;
  }

  .boticon {
    margin-right: 1rem;
    margin-left: .7rem;
  }

  .text-center h3{
    font-size: 18px;
  }

  }

  @media (max-width: 1000px) {
    .text-center {
      font-size: 18px;
    }

    .cloud {
      height: 40vh;

    }
  
  }
  