.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.AUTH-container {
  padding: 20px;
  margin-top: 3%;
  border-radius: 20px;
  backdrop-filter: blur(2px);
  height: auto;
  width: 30rem; /* Example value, assuming the width is based on the Golden Ratio of the viewport's width */
  background-color: #ffffff;
  box-shadow: 0px 0px 25px 0px #888888a4;
}

.signup-container {
  display: block;
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px #888888a4;
  width: 30vw;
  background-color: #ffffff;
  margin-left: 10vw;
  margin-top: 3rem;
  height: fit-content;
}

.sign-up-background{
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}

.signup-text-container {
  display: block;
  padding: 20px;
  border-radius: 20px;
  width: 700px;
  margin-top: 2%;
  margin-right: 25%;
}


.signup-points-parent-container {
  display: block;
}


error {
  width: 50%;
}



button:active {
  transform: translateY(1px);
}

@media (max-width: 768px) {
  .sign-up-container {
    margin: 20px auto;
    max-width: 300px;
  }
  .signup-text-container {
    display: none;
  }
}

button[type="submit"] {
  padding: 10px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
}



.logo {
  width: 200px;
}

.signup-link,
.forgot-password-link {
  color: #543f86;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;

}
.signInBtn {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
}


.popupCard {
  width: 62.8vw; 
  height: 80vh;/* Example value, assuming the width is based on the Golden Ratio of the viewport's width */
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px #888888a4;
  z-index: 500;
  overflow-y: auto;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 400;
}

.payment-form-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.plan-details-list {
  padding: 10px;
  margin-bottom: 5px;
}

.payment-form__detail-item {
  font-size: 14px;
  margin-bottom: 8px;
}


.heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.subheading {
  font-size: 18px;
  font-weight: normal;
  margin-bottom: 20px;
}

.cancel {
  font-size: 14px;
  color: #888888;
}

.input-field {
  margin-bottom: 20px;
}

.signup-btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #0147DF;
  color: #fff;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border-radius: 10px;
  cursor: pointer;

}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.powered-by-stripe {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.stripe-logo {
  width: 50px;
  height: auto;
  margin-right: 5px;
}



.pricing-table h1 {
  text-align: center;
  margin-bottom: 20px;
}


.pricing-table th,
.pricing-table td {
  border: 0px solid rgba(204, 204, 204, 0.5);
  padding: 15px;
}

.thread {
  background-color: #007bff00;
}



.pricing-table p {
  text-align: center;
  font-size: 24px;
  margin-bottom: 10px;
  color: #ffffff;

}

.pricing-table h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #ffffff;
}

.pricing-table table {
  width: 100%;
  border-collapse: collapse;
  text-align: center;
  border-spacing: 0;
  border: none 
}

.pricing-table thead th {
  font-weight: bold;
  
}

.pricing-table button {
  background-color: transparent;
  border: 2px solid #0147DF;
  color: #0147DF;
  padding: 8px 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 50px;
  margin-top: 20px;
  box-shadow: none;
}

.pricing-table button:hover {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: white;
  text-decoration: none;
}

.pricing-table thread td:first-child,
.pricing-table tbody td:first-child {
  font-weight: bold;
  text-align: right;
}

.pricing-table thead th p {
  font-size: 14px;
  font-weight: 500;
  display: inline;
  margin-bottom: 10px;
}

/* Infill the button in column 3 */
.pricing-table thead th:nth-child(3) button {
  background-color: #0147DF;
  color: white;
}

.pricing-table thead th:nth-child(3) button:hover {
  background-color: #ffffff;
  color: #0147DF;
}

.pricing-table .table-container {
  overflow: hidden;
  position: relative;
}

.pricing-table .price {
  font-size: 50px;
  font-weight: 250;
  margin-top: 10px;
  justify-content: left;
}

.pricing-table .month {
  font-size: 14px;
  opacity: 0.5;
  margin-left: 5px;
}

.pricing-table .price-container {
  margin-top: 1rem;
}

.AUTH-container-PRICING {
  display: flex;
  padding: 4%;
  border-radius: 20px;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  box-shadow: 0px 0px 5px 0px #888888;
  margin-left: 5%;
  margin-right: 5%;
}

.pricing-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
}

.plan-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.plan-card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 10px 0 rgba(0,0,0,0.6);
  margin: .5rem;
  padding: .5rem;
  width: calc(100%/2 - 1rem);
  text-align: center;
  transition: transform 0.3s ease-in-out;
}

.plan-description {
  height: 7rem; 
  overflow: hidden; 
}

.plan-card:hover {
  transform: scale(1.02);
}


.plan-card ul {
  list-style: none;
  padding: 0;
  margin-bottom: 2rem;
}

.plan-card ul li {
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 0.9rem;
}

.price-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.price {
  font-size: 2rem;
  color: #333;
}

.month {
  font-size: 1.2rem;
  color: #d4d4d4;
}




.pricingContainerAuth{
  width: 90%;
  top:0;
  padding-top: 2%;
  overflow-y: auto;
}

.StripeElement {
  min-width: 200px;
  width: 35%;  padding: 15px;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.StripeElement input {
    min-width: 200px;
    width: 35%;
}

.h2 {
  font-size: 18px;
  opacity: 0.5;
  margin-bottom: 30px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.hexagon {
  width: 30px;
  height: 17px;
  position: relative;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.hexagon:before,
.hexagon:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-style: solid;
}

.hexagon:before {
  border-bottom: 8px solid #3498db;
  top: -8px;
}

.hexagon:after {
  border-top: 8px solid #3498db;
  bottom: -8px;
}
.spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  z-index: 1002;
}
.logo {
  position: absolute;
  left: auto;
  height: 50px;
  width: auto;
  margin-top: auto;
}

.logoWrapper {
  position: relative;
  width: 100%;
  margin-left: 25%;
  margin-top: 0.25rem;
}

.inputDivider {
  margin: .25rem;
}

@media screen and (max-width: 992px) {
  .plan-container {
    flex-direction: column;
    width: 61.8%;
  }

  .plan-card {
    width: 100%;
    margin-bottom: 2rem;
  }
}

@media screen and (max-width: 800px) {

  .signup-left {
    display: none;
  }

  .AUTH-container {
    top: 20%;
    width: 20rem;
    left: 3%;
    right: 3%;
  }
  .AUTH-container-PRICING {
    padding: 2%;
    margin-left: .2rem;
    margin-right: .2rem;
  }


  
  .pricing-table {
    padding: 1rem;
  }

  .plan-card {
    padding: 0rem;
    width: 95%;
  }

  .price-container {
    flex-direction: column;
  }

  .month {
    margin-top: 0.5rem;
  }

  .StripeElement, .StripeElement input {
    width: 100%;
  }

  .signup-container {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 0%;
    margin-top: 10%;
  }
}

/* CSS to include in your sign-in and sign-up pages */
.page-show-recaptcha .grecaptcha-badge { 
  visibility: visible;
}

.signup-points-container {
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
}

.signup-points-icon {
  color: #0147DF;
}

.signup-points-text {
  margin-left: 1rem;
  width: 29vw;
  max-width: 60%;
}

.signup-points-headline {
  font-weight: 600;
  margin-top: 0px;
}

.sign-up-legal-disclaimer {
  font-size: 12px;
  margin-left: 2rem;
  margin-right: 2rem;
  max-width: 25rem;
}

.handshake-icon {
  color: #0147DF;
  height: 18px;
  margin-top: .5rem;
}

.links-signup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-left: 2.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 14px;
}

.verification-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
}

.verification-message, .verification-image {
  flex: 1;
}

.verification-image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.dog-image {
  width: 20rem;
}

.dog-text {
  align-items: bottom;
  margin-top: 10rem;
}

.dog-h2 {
  margin-bottom: 0px;
}

.auth-margin-top {
  margin-top: 5rem;
}

.eye-slash {
  color: #0147DF;
  display: flex;
}

.eye-slash-container {
  display: flex;
  align-items: right;
  justify-content: right;
  margin-top: -1.75rem;
  margin-left: 15rem;
  margin-right: 1rem;
}

.dark-text {
  color: black;
}

.resetPassword {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.code-input {
  width: 1rem;
  height: 2rem;
  margin: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border 0.3s;
  min-width: 13rem;
}

.code-input:focus {
  border: 2px solid #007bff; /* Highlight the input box when it is focused */
  outline: none; /* Remove default focus outline */
}

.input-codes-signin {
  display: flex;
}

.authMessage {
  font-size: 14px;
  color: #7c7c7c;
  text-align: center;
}

.input-group-code {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}


.signInGoogle-button{
  background-color: #fff;
  color: black;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 25px;
  font-weight: 600;
  stroke: #000000;
  border: 2px solid grey;
  box-shadow: none; 
}

.signInGoogle-button:hover {
  background-color: #000000; /* Light gray on hover */
  color: #ffffff;
  border: 2px solid rgb(0, 0, 0);
}

.google-logo {
  width: 30px; /* Adjust the width of the Google logo */
  height: 30px; /* Adjust the height of the Google logo */
  margin-right: 10px; /* Space between the logo and text */
}

/* Divider wrapper styles */
.divider-wrapper {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  border: none;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  padding: 24px 0 0;
  align-items: center;
  justify-content: center;
  width: 320px;
  vertical-align: baseline;
  margin-bottom: 10px;
}

/* Divider line styles */
.divider-line {
  flex: 1;
  border: none;
  border-top: 1px solid #ddd;
  margin: 0 10px;
}

/* Divider text styles */
.divider-text {
  text-align: center;
  flex: 0 0 auto;
  margin: 0;
  padding: 0 10px;
  color: #333;
}

.signupHIPAA {
  margin-left: 10vw;
}

.customsignup-grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.customsignup-grid-item {
  display: flex;
  flex-direction: column;
}

.customsignup-grid label{
  margin-bottom: 10px;
  font-size: 0.8rem;
  color: #010101;
}

@media (max-width: 975px) {
  .customsignup-grid {
    grid-template-columns: 1fr;
  }
}

.signup-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
}

.signup-left {
  flex: 1;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 2rem;
  box-shadow: 0px 0px 25px 0px #888888a4;
  margin-top: 3rem;
}

.signup-left h4 {
  font-weight: 600;
  margin: 0;
  font-size: 1.25rem;
}

.signup-left p {
  font-size: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.signup-container {
  flex: 1;
  padding: 2rem;
}

@media (max-width: 768px) {
  .signup-wrapper {
    flex-direction: column;
    padding-left: 2.5%;
    padding-right: 2.5%;
  }

  .signup-left,
  .signup-container {
    padding: 1rem;
  }
}

/* Flex container for each signup feature */
.signup-feature {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1.5rem;
}

/* Styling for the icons */
.signup-icon {
  margin-right: 1rem;
  color: #00316f; /* Adjust the color to match your theme */
  flex-shrink: 0; /* Prevents the icon from shrinking */
}

/* Container for the text */
.signup-text {
  display: flex;
  flex-direction: column;
}

/* Styling for the headings */
.signup-text h4 {
  font-weight: 600;
  margin: 0 0 0.5rem 0;
  font-size: 1.25rem;
}

/* Styling for the paragraphs */
.signup-text p {
  font-size: 1rem;
  color: #555;
  margin: 0;
}

/* Responsive adjustments (if not already present) */
@media (max-width: 768px) {
  .signup-feature {
    flex-direction: column;
    align-items: flex-start;
  }

  .signup-icon {
    margin-bottom: 0.5rem;
  }
}

.auth-background-overflow {
  overflow-y: auto;
}

.forgot-password-container {
    padding: 20px;
    margin-top: 3%;
    border-radius: 20px;
    backdrop-filter: blur(2px);
    height: auto;
    width: 80vw; /* Example value, assuming the width is based on the Golden Ratio of the viewport's width */
    background-color: #ffffff;
    box-shadow: 0px 0px 25px 0px #888888a4;
}

@media (max-width: 950px) {

  .dog-image {
    display: none;
  }

  .dog-text {
    display: none;
  }
}


.dogs-side-by-side {
  display: flex;
  flex-direction: row;
}


.callOfDuty {
  border-radius: 20px;
  padding: 5px;
}