/* Add this style for the dark mode */
.dark-mode {
  background-color: #222;
  color: #fff;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
}

.mainContent {
  margin-top: 5rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  width: 100%;
}

.authDetails {
  position: fixed;
  top: 0;
  right: 0;
}

.title {
  display: block;
  text-align: center;
  font-size: 30px;
  margin-top: 10vh;
  padding-bottom: 5.5vh; /* Separate top and bottom margins */
  font-weight: 600;
}

.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.logoContainer {
  display: flex;
  align-items: left;
  margin-right: 20px;
  position: relative;
}

.logo img {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
}


.background {
  background-color: var(--color-background);
  color: var(--color-text);
}

.title {
  color: var(--color-text);
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70vh;
}

/* Positioning the button container at the bottom right of the page */
.addPatientButtonContainer {
  position: fixed; /* Fixed positioning relative to the viewport */
  bottom: 40px; /* 20px from the bottom of the viewport */
  right: 20px; /* 20px from the right of the viewport */
  z-index: 1000; /* High z-index to ensure it's above other content */
}

/* Styling the button */
.addPatientButton{
  border: none;
  border-radius: 50%; /* Circular shape */
  cursor: pointer; /* Cursor indicates it's clickable */
  padding: 20px 18px; /* Padding to increase the button size */
  display: flex; /* Enables center alignment of the icon */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
}

/* Hover effect for the button */
.addPatientButton:hover {
  background-color: #0147DF; /* Slight transparency on hover for visual feedback */
}

.HexagonContainer {
  display: flex;
  justify-content: center;
}


.hexIcons {
  height: 3rem;
  margin-bottom: .5rem;
}

@media (max-width: 480px) {
  .hexIcons {
    height: 2rem;
    margin-bottom: .5rem;
  }
}