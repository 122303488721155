
.topbar {
  background-color: #eeeeee80;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 2.75rem;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0,0,0,.1);
  backdrop-filter: saturate(180%) blur(20px);
  transition: background 0.5s cubic-bezier(0.28, 0.11, 0.32, 1);
}

.logo {
  position: relative;
  left: 0px;
  align-items: center;
  width: auto;
  margin-bottom: -0.25rem;
  height: 2.75rem;
}

.appTitleContainer{
  display: flex;
  align-items: center;
}

.appTitle {
  margin-left: 5px;
  letter-spacing: 2px;
  align-items: center;
}


.leftNav {
  display: flex;
  align-items: center;
  padding-left: 10px;
}

.rightNav {
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin-bottom: 5px;

}

.nav {
  display: flex;
  align-items: center;
}

.navItem {
  margin: 0 15px;
}

.navLink {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
}

.navLink:hover {
  color: #000;
}

.navLinkText {
  margin-top: 5px;
}

.dropdownButton {
  background-color: #eeeeee00;
  border: none;
  cursor: pointer;
  outline: none;
  color: #00000096;
  font-size: 5px;
  margin-top: 1rem;
  box-shadow: none;
}

.dropdownButton:hover {
  background-color: #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}


.dropdown {
  position: absolute;
  top: 100%;
  right: 4px;
  margin-right: 10px;
  min-width: 200px;
  background-color: #00316f;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0,0,0,.3);
  z-index: 999;
}

/* Add rounded corners to the first and last items on hover */
.dropdown .dropdownLink:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdown .dropdownLink:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.dropdownLink {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease-in-out;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
}

.dropdownLink:hover {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
}

/* Add the following to create a line between Settings and Usage */
.dropdownLink + .dropdownLink {
  border-top: 1px solid #ccc;
}


.dropdownLinkText {
  margin-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* Add rounded corners to the first and last items on hover */
.dropdownLink:first-child:hover {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.dropdownLink:last-child:hover {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}


.authText {
  background-color: #0147DF;
  padding: 10px;
  border-radius: 10px 10px 0 0; 
  color: #fff;
}

.dropdownsignout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ccc;
  padding-top: 5px;
}

.dropdownsignout:hover {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  
}


.svg-inline--fa {
  height: 20px;
  justify-content: center;
}

.nameBold {
  font-weight: bold;
}

.emailVerificationFlag {
  background-color:  rgba(255, 0, 0, 0.7);
  color: #e6e6e6;
  opacity: 100%;
  padding: .25rem;
  border-radius: .5rem;
  margin-top: .25rem;
  cursor: pointer;
  margin-left: 16px;
  margin-right: 16px;
  padding-left: 16px;
  padding-right: 16px;
}

.emailVerificationFlag:hover {
  background-color: #ff0810;
}

.organization-logo-placeholder {
  height: 30px;
  border: 2px solid #242424d9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: bold;
  border-radius: 5px;
  background-color: #f9f9f9;
  padding-left: 10px;
  padding-right: 10px;
  color: linear-gradient(135deg, #00316f, #0147df);

}
.logo {
  height: 30px; /* Default height for the Popularis logo */
}
