
.IPA-form {
  width: 61.803vw;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px #888888a4;
}

.IPA-background {
  background-color: #f5f5f5;
  height: 100%;
  width: 100%;
  min-height: 100vh;
}


.IPA-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #f5f5f5;

}

.provider-type-cards {
  display: flex;
  width: 1040px;
}

.provider-type-cards-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Creates 5 columns */
  gap: 20px; /* Adjust the gap size as needed */
  width: 62.8vw; /* Example value, assuming the width is based on the Golden Ratio of the viewport's width */
  margin: auto; /* Center the grid container if desired */
}



.checkbox-group {
    flex-direction: column;
    display: flex;
}

.provider-type-selection-form {
    text-align: center;
  }
  
  .provider-type-cards {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
  }
  
  .provider-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 12vw;
    cursor: pointer;
    transition: transform 0.2s;
  }
  
  .provider-card:hover {
    transform: scale(1.05);
  }
  
  .provider-card h3 {
    margin-top: 10px;
  }

  .location-containers {
    background: #f5f5f7;
    padding: 2rem;
    border-radius: 20px;
    margin: 2rem;
  }
  
  .ipa-row {
    justify-content: left;
  }

  .yes-no-questions-section {
    margin-left: 20%;
    display: block;
  }

  .ipa-button-add {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .form-navigation {
    display: flex;
    flex-direction: row;
  }

  .yes-no-center-step9 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .affirmation-release {
    font-family: Arial, sans-serif;
    padding: 20px;
    line-height: 1.5;
  }
  
  .affirmation-release h2 {
    color: #333;
  }
  
  .sigCanvas {
    border: 2px solid #ccc;
    margin-top: 20px;
    width: 20rem;
    height: 15rem;
  }
