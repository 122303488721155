.spinner-container{
    display:flex;
    justify-content:center;
    align-items:center;
    height: 100px;
    transform: scale(0.75);
  }
  
  .spinner-div{
    position:absolute;
    width:90px;height:51px;
  }
  
  .spinner-div:nth-of-type(2){transform:rotate(60deg)}
  .spinner-div:nth-of-type(3){transform:rotate(-60deg)}
  
  
  .spinner-div div{width:100%;height:100%;position:relative}
  
  .spinner-div div span{
    position:absolute;
    width:4px;height:0%;
    background:#00316f;z-index:999999;
  }

  
  .h1{left:0;
    animation: load1 7.2s ease infinite;
  }
  .h2{right:0;
    animation: load2 7.2s ease .6s infinite;
    font-size: 0;
    opacity: 100;
    margin-bottom: auto;
  }
  .h3{right:0;
    animation: load3 7.2s ease 1.2s infinite;
  }
  .h4{right:0;
    animation: load4 7.2s ease 1.8s infinite;
  }
  .h5{left:0;
    animation: load5 7.2s ease 2.4s infinite;
  }
  .h6{left:0;
    animation: load6 7.2s ease 3s infinite;
  }
  
  @keyframes load1 {
    0%{bottom:0;height:0}
    6.944444444%{bottom:0;height:100%}
    50%{top:0;height:100%}
    59.944444433%{top:0;height:0}
  /*   91.6666667%{top:0;height:0%;} */
  }
  
  @keyframes load2 {
    0%{top:0;height:0}
    6.944444444%{top:0;height:100%}
    50%{bottom:0;height:100%}
    59.944444433%{bottom:0;height:0}
  /*   91.6666667%{bottom:0;height:0%} */
  }
  
  @keyframes load3 {
    0%{top:0;height:0}
    6.944444444%{top:0;height:100%}
    50%{bottom:0;height:100%}
    59.94444443%{bottom:0;height:0}
  /*   91.6666667%{bottom:0;height:0%;} */
  }
  
  @keyframes load4 {
    0%{top:0;height:0}
    6.944444444%{top:0;height:100%}
    50%{bottom:0;height:100%}
    59.94444443%{bottom:0;height:0}
  /*   91.6666667%{bottom:0;height:0%;} */
  }
  
  @keyframes load5 {
    0%{bottom:0;height:0}
    6.944444444%{bottom:0;height:100%}
    50%{top:0;height:100%}
    59.94444443%{top:0;height:0}
  /*   91.6666667%{top:0;height:0%;} */
  }
  
  @keyframes load6 {
    0%{bottom:0;height:0}
    6.944444444%{bottom:0;height:100%}
    50%{top:0;height:100%}
    59.94444443%{top:0;height:0}
  /*   91.6666667%{top:0;height:0%;} */
  }