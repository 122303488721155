.popupContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 61%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 900;
  box-sizing: border-box;

}

.popupContent {
  background-color: white;
  width: 100%;
  height: 95%;
  top: 0;
  right:0;
  margin-top: 2.8rem;
  position: relative;
  z-index: 900;
}

.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-button {
  width: 200px;
  height: 40px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
}

.cancel-button {
  width: 80px;
  max-width: 100%;
  border: none;
  border-radius: 10px;
  font-size: 16px;
  cursor: pointer;
  display: block;
  margin-right: 0px;
  text-align: center;
  box-sizing: border-box;

}

#quicksearchButton {
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #0650c9;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  min-width: 2000px;
  
}


.dataContainer {
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
}


@media (max-width: 768px) {
  .BenefitsInfo {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .BenefitDetails {
    grid-template-columns: 1fr; /* Single column layout on smaller screens */
  }
}


.BenefitsCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 2rem;
  margin-top: 2rem;
}

.BenefitName {
  width: 25%; /* Make the header take the full width of its container */
}

/* Responsive adjustments (if needed) */
@media (max-width: 768px) {
  .BenefitName {
  }
}


/* Adjust the table margin */
.BenefitsTable {
  margin-left: 1rem; /* Add margin to the left of the table */
}

.addressInfo div {
  display: block;
  margin-bottom: 4px; /* Adjust based on your spacing preference */
}

.addressInfo span {
  display: inline-block;
  margin-right: 8px; /* Adds space between elements, adjust as needed */
}
