
.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; /* Adjust to full width for mobile */
  max-width: 1200px;
  padding: 1rem;
  margin-top: 80px;
  border-radius: 20px;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.8); /* Improved visibility */
  box-shadow: 0px 0px 5px 0px #888888;
}

.settings-content {
  width: 100%; /* Full width for smaller screens */
  overflow-y: auto;
  padding: 1rem; /* Add padding for better spacing */
}

.leftColumn,
.rightColumn {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem; /* Add spacing for stacked layout */
}

.section {
  margin-bottom: 2rem;
}


.bigName {
  font-size: 1.8rem; /* Adjust size for mobile */
  font-weight: bold;
  margin-bottom: 2rem;
  text-align: center; /* Center align for smaller screens */
}



.apiButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.upload-box {
  border: 2px dotted #0147DF;
  padding: 1rem; /* Adjust padding for mobile */
  text-align: center;
  cursor: pointer;
  color: #0147DF;
  margin-bottom: 20px;
  border-radius: 10px; /* Rounded for modern design */
}

.upload-box p {
  margin: 10px 0 0 0;
  font-size: 1rem; /* Adjust font size for readability */
}

.company-logo {
  max-width: 100%; /* Adjust to fit mobile screen */
  height: auto;
  display: block;
  margin: 0 auto;
}


/* Media Query for Small Screens */
@media (max-width: 768px) {
  .bigName {
    font-size: 1.5rem;
  }


  .settings-content {
    padding: 1rem;
  }

  .upload-box {
    padding: 1rem;
  }
}

/* Media Query for Extra Small Screens */
@media (max-width: 480px) {
  .bigName {
    font-size: 1.2rem;
  }

  .upload-box {
    padding: 0.8rem;
  }
}
