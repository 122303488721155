.background {
  background-color: var(--color-background);
  color: var(--color-text);
}

.title {
  color: var(--color-text);
}

.Claims-inner-container {
  position: fixed;
  right: 0px;
  bottom: 0px;
  width: 80%;
  height: 95%;
  background-color: white;
  flex: 1;
  z-index: 11;
  overflow-y: scroll;
  /* padding: 50px; */
}

.Claims-inner-content {
  padding: 4px 32px 12px 32px;
  border-radius: 8px;
  max-width: 80vw;
  min-width: 60vw;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  margin-bottom: 50px;
}

.DashboardContainer {
  position: fixed;
  right: 0px;
  top: 0;
  width: 79%;
  height: 95%;
  overflow-y: auto;
  margin-top: 3rem;
  z-index: -1;
}

.checkout {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.batchListContainer {
  position: fixed;
  left: 20%;
  height: 96%;
  top: 0;
  width: 20%;
  min-height: 200px;
  background-color: #f1f1f1;
  z-index: 3;
  margin-top: 2.75rem;
  overflow-y: auto;
}

/* .batchListContent {} */


.claimsListContainer {
  position: fixed;
  left: 0;
  height: 96%;
  top: 0;
  width: 20%;
  min-height: 200px;
  background-color: #f1f1f1;
  margin-top: 2.75rem;
  overflow-y: auto;
}


.file-browser-container {
  position: fixed;
  left: 15%;
  width: 24%;
  height: 95%;
  overflow-y: auto;
  background-color: white;
  margin-top: 45px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  margin-left: 0px;
}

.folder-list,
.file-list {
  overflow-y: auto;
  padding: 10px;
  list-style: none;
}

.folder-list li,
.file-list li {
  cursor: pointer;
  padding: 8px;
  margin-bottom: 4px;
  border-radius: 10px;
  right: 0;
  width: auto;
}

.cw-pane-container {
  display: flex;
  height: 100vh;
}

.split-view {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tri-page {
  display: flex;
  height: 100%;
  flex-direction: row;
}



#title {
  display: flex;
  color: #194d85de;
  left: auto;
  text-align: left;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.checkoutformContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
}

.form label {
  font-size: 1.2rem;
}

.form input[type="text"],
.form input[type="file"] {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.form button[type="button"],
.form button[type="submit"] {
  width: 100%;
  max-width: 500px;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 10px;
  border: none;
  background-color: #0077c2;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.form button[type="button"]:hover,
.form button[type="submit"]:hover {
  background-color: #005fa3;
}

.outerbar {
  width: 100%;
  max-width: 500px;
  height: 20px;
  border-radius: 10px;
  background-color: #e6e6e6;
  overflow: hidden;
}

.innerbar {
  height: 100%;
  background-color: #0077c2;
  transition: width 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
}

input[type="file"] {
  display: none;
}

#batchName {
  margin-top: 20px;
}

#templateButton {
  margin-top: 18px;
  left: 42%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  min-width: 200px;
}

.button-container {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  display: block;
}

.button-spacing {
  width: 20px;
  height: 20px;
}

.error-table-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
}

.selected-diagnosis-code {
  background-color: rgba(0, 0, 0, 0.1);
}

.selected-codes-container {
  display: flex;
  flex-wrap: wrap;
}

.selected-code {
  margin-right: 10px;
}

.batch-summary-container {
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  gap: 2rem;
  align-items: center;
  text-align: center;
  justify-content: center;
}

.diagnosis-selector,
.select-payer {
  width: 48%;
}

.nextButtonContainer {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.collapsibleSection .sectionHeader {
  display: flex;
  align-items: center;
  font-size: 26px;
  justify-content: flex-start;
  width: 100%;
}

.collapsibleSection .sectionNumber {
  font-weight: bold;
  margin-right: 5px;
}

.CollapsibleSection_ .sectiontitle {
  text-align: left;
  cursor: pointer;

}

.batch-popup-container {
  position: absolute;
  top: 0;
  height: 100%;
  /* z-index: 3; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.batch-popup {
  position: fixed;
  right: 0px;
  width: 80%;
  height: 100%;
  background-color: white;
  overflow: hidden;
  text-align: left;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 2rem;
}

.batch-popup-title {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.batch-popup-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  font-size: 0.9rem;
}

.batch-popup-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: #00316f;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
}

.batch-popup-button:hover {
  background-color: #1874bf69;
}

.batch-popup-button:focus {
  outline: none;
}

@keyframes slideIn {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    /* transform: translateY(0); */
    top: 0;
    opacity: 1;
    z-index: 1;
  }
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }

  100% {
    background-position: 1000px 0;
  }
}

.batch-placeholder {
  animation: shimmer 2s infinite;
  background: linear-gradient(to right, #e0e0e0 4%, #fff 25%, #e0e0e0 36%);
  background-size: 1000px 100%;
  height: 90px;
  width: 90%;
  margin: 6px 12px;
  border-radius: 8px;
}

.batch-wrapper {
  margin: 6px 12px;
  padding-top: .5rem;
  padding-left: .5rem;
  padding-right: .5rem;
  padding-bottom: .2rem;
  background-color: #fff;
  border-radius: 8px;

}

.batch-wrapper:hover,
.batch-wrapper.batch-selected {
  /* Your hover styles go here, for example: */
  cursor: pointer;
  background-color: #ccc;
}

.batch-wrapper h3 {
  margin: 0;
  font-size: 18px;
  padding-bottom: 5px;
}

.batch-wrapper .batch-meta {
  margin-left: 37px;
  color: #999;
  font-size: 14px;
}

.batch-wrapper .batchlist {
  margin: 10px 0;
  padding-left: 0;
}

.batch-wrapper .batch {
  margin-bottom: 5px;
  list-style: none;
}

.batch-header {
  display: flex;
  align-items: center;
  gap: 10px
}

.batch-header-trading {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-left: 2rem;
}

.batch-header-claimsList {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.batch-info-claimsList {
  display: flex;
  align-items: center;
}

.batch-icon {
  margin-right: 8px;
  /* Adjust spacing as needed */
}

.medical-icon {
  margin-right: 5px;
}

.instructions {
  display: none;
}

#templateButton:focus+.instructions,
#templateButton:active+.instructions {
  display: block;
}

.popupCloseButton {
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.selected-billing-code {
  background-color: #f0f0f0;
  cursor: pointer;
}


/* Dropdown container */
.dropdown-container {
  position: relative;
  display: inline-block;
}

/* Dropdown label */
.dropdown-label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

/* Style the select element */
.dropdown-select {
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23777' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 8px;
  min-width: 200px;
  cursor: pointer;
  outline: none;
}

/* Add a hover effect to the dropdown */
.dropdown-select:hover {
  border-color: #888;
}

.add-billing-code-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 30rem;
  overflow-y: scroll;
  padding-left: 1rem;
  padding-right: 1rem;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  border-radius: 20px;
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;
}

.MainActionButton.selected {
  background-color: #0147DF;
}


th,
td {
  padding: 10px;
  border: 0px;
}

.payertableHeader thread {
  color: #00316f;
}

li span {
  align-items: center;
  text-align: left;
}

li span button {
  margin-left: 0;
}

.provider-selection-container tbody tr:hover {
  cursor: pointer;
}

.provider-selection-container tbody tr:hover {
  cursor: pointer;
}

/* Style for the table rows */
.table tr {
  cursor: pointer;
}

/* Style for the table rows */
tbody tr {
  cursor: pointer;
}

/* Style for the table rows on hover */
.billingCodesContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
}

/* Responsive breakpoints */
@media (min-width: 500px) {
  .billingCodesContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 900px) {
  .billingCodesContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1200px) {
  .billingCodesContainer {
    grid-template-columns: repeat(4, 1fr);
  }
}

.container-flex {
  display: flex;
}

.search-bar-ICD10 {
  margin: 0 auto;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0.5em 0 0.5em 0.5em;
  font-size: 16px;
  width: 500px;
  border-radius: 10px;
  outline: none;
  flex-grow: 1;
  height: 25px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 0;
}

.search-icon {
  /* margin-right: 0.5em; */
  padding: 0;
}

.search-input-ICD10 {
  border: none;
  padding: 0.5em;
  width: 430px;
  outline: none;
  flex-grow: 1;
  height: 25px;
  box-shadow: none;
}

.searching-input {
  border-radius: 10px 10px 0 0;
}

.searching-button {
  border-radius: 0 10px 0 0 !important;
}

.search-button-ICD10 {
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  /* font-size: 16px; */
  border-radius: 0 10px 10px 0;
  outline: none;
  background-color: #ccc;
  color: white;
  height: 43px !important;
  width: 120px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
  margin: 0;
}

.results-container {
  max-height: 15rem;
  overflow-y: auto;
  border: 1px solid #ccc;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 3px 0px #888888;
  margin: 0;
  width: auto;
  z-index: 99;
  border-top: none;
  display: block !important;
  border-radius: 0 0 10px 10px;
}

.claimToolsButtons {
  display: flex;
  min-height: 3rem;

}

.MainActionButton {
  padding: 10px;
  border-radius: 5px;
  border: none;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  display: flex;
  width: 90%;
  align-items: center;
}


.buttons-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.submit-button {
  width: 200px;
  height: 40px;
  background-color: green;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.cancelButton {
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 0px;
  text-align: center;
  box-sizing: border-box;
  position: absolute;
  top: 10px;
  left: 10px;
}

.ChartTitle {
  display: flex;
  align-items: center;
  justify-items: center;
}

.Charts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 20px;
}

.SingleChart {
  width: 50%;
}

.frequencyTables {
  display: flex;
  justify-content: space-around;
  align-items: top;
  padding: 20px;
}

.Summary {
  display: flex;
  justify-content: space-around;
  align-items: top;
  padding: 20px;
}


/* Example media query for smaller screens */
@media (max-width: 768px) {
  .Charts {
    flex-direction: column;
  }
}

.FrequencyTableContainer {
  display: block;
}

.status-summary-item {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.summary-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.summary-item p {
  margin: 0;
  font-size: 1rem;
}

.summary-item h3 {
  margin: 0;
  font-size: 1.5rem;
}

.filesCloseButtonClaimsSingleView {
  position: relative;
  top: -20px;
  left: -10px;
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.claimsTableContainer {
  overflow-x: hidden;
  align-items: center;
  justify-items: center;
  width: 100%;
  margin-left: 1rem;
  margin-top: 1rem;
}

.claimsDataTable {
  width: 100%;
  text-align: center;
}

.claimsDataHeader,
.claimsDataCell {
  padding: 8px;
}

td {
  text-align: center;
}

.claimsMenus {
  align-items: center;
  padding: 2rem;
}

.scrollButtonContainer {
  display: flex;
  align-items: center;
  padding-left: 10rem;
}



.scrollToTopButtonClaims {
  position: fixed;
  top: 4rem;
  left: 27%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  visibility: hidden;
  /* Hide the button initially */
  opacity: 0;
  /* Start with 0 opacity */
  transition: opacity 0.2s ease-out, visibility 0.2s ease-out;
  /* Add transition for visibility */
}

.scrollToTopButtonClaims.visible {
  visibility: visible;
  /* Show the button when .visible is added */
  opacity: 1;
  /* Full opacity when the .visible class is added */
}

.claims-search-bar {
  justify-content: left;
  margin-left: -1rem;
  z-index: 9;
  min-width: 40%;
}

.claims-search-bar-content {
  display: flex;
  width: 90%;
  margin-left: 1rem;
  margin: 0 auto;
}


.search-results {
  width: auto;
  display: flex;
  display: grid;
  background-color: #f1f1f1;
  color: black;
  margin-top: -1.5rem;
  z-index: 8;
  border-radius: 10px;
}

.search-results-container {
  height: 80%;
  z-index: 8;
}

.dropdownClaims {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  min-width: 200px;
  max-height: 0;
  /* Initially set max-height to 0 for scroll-down effect */
  overflow: hidden;
  /* Hide overflow to achieve scroll-down effect */
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, .3);
  background-color: #5050508a;
  z-index: 999;
  opacity: 0;
  animation: fadeInAndSlide 1s ease forwards;
}

@keyframes fadeInAndSlide {
  from {
    opacity: 0;
    max-height: 0;
  }

  to {
    opacity: 1;
    max-height: 100%;
  }
}

/* .dropdownClaims > * {
  padding: 10px;
  transition: background-color 0.3s ease;
} */

.dropbtn {
  display: flex;
  flex-direction: row;
}

.toolsBar {
  display: flex;
}

.StatusandEligibility {
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* This creates two columns of equal width */
  gap: 20px;
  /* Optional: adds some space between the columns */
  /* Add any additional styling as needed */
}

.iconClaimsList {
  width: 3rem;
}

.select-dropdown {
  width: 100%;
  /* Adjust the width as needed */
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-dropdown option {
  white-space: normal;
  /* This allows text to wrap */
  overflow: hidden;
  text-overflow: ellipsis;
}

.popupClaimsPopup {
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  z-index: 4;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* width: 100%; */
  padding-left: 1rem;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 350px;
}

.popupClaimsPopupWithList {
  margin-left: 25%;
}

.white-text {
  color: white;
}

.initial-questions-card {
  width: 32.8vw; /* Example value, assuming the width is based on the Golden Ratio of the viewport's width */
  margin-top: 8vh;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  box-shadow: 0px 0px 25px 0px #888888a4;
}

/* Calendar.css */

.calendarContainer {
  justify-content: center;
  align-items: flex-start;
  padding: 2rem;
  margin-top: 1rem;
  margin: 50px;
}

.eventDetails {
  flex: 1;
  padding: 20px;
  border-right: 2px solid #ccc;
  margin-right: 20px;
}

.calendarWrapper {
  flex: 3;
}

.eventDetails h4 {
  margin-bottom: 10px;
  color: #333;
  font-size: 20px;
}

.eventDetails p {
  margin: 5px 0;
  color: #666;
}

/* Additional styling for the calendar itself */
.react-big-calendar {
  font-family: 'Arial', sans-serif;
}

/* Customize the appearance of events on the calendar */
.rbc-event {
  background-color: #3174ad;
  color: white;
  border-radius: 10px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rbc-event:hover {
  background-color: #265d8b;
}

.EventDetailsContainer {
  margin-left: 2 rem;
  padding: 1rem;
  flex: 1;
  z-index: 12;
  
}

.showByDropdown {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  display: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  position: relative;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
}

.contentPopup {
  display: flex;
  margin-top: 60px;
  padding-left: 20px;   
  padding-right: 20px; 
  overflow-y: scroll;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.inputGroup input[type="text"] {
  padding: 10px;
  margin: 0 10px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button[type="quick"] {
  padding: 10px;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 10px;
  background-color: #2c3e50;
  color: #fff;
  cursor: pointer;
  width: 30%;
}

button[type="quick"]:hover {
  background-color: #4e6e8d;
}

.patientInfo {
  display: flex;
  justify-content: space-between;
}

.patientInfo > div {
  flex-basis: 48%;
}

.genderSelect {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: -.6rem;
  font-size: 16px;
  box-shadow: 0px 0px 5px 0px #888888;
}

.payerSelect {
  display: block;
  width: 100%;
  height: 400px;
  padding: 10px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: -15px;
  font-size: 16px;
  box-shadow: 0px 0px 5px 0px #888888;
}

.select-container {
  display: fixed; /* use flexbox to align items */
  justify-content: center; 
  
}

.select-container select {
  width: 80px; /* increase the width of the select element */
  padding: 5px; /* add some padding to the select element */
  font-size: 16px; /* increase the font size */
  border-radius: 15px;

}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
  color: #888;
}

.inputGroup input,
.inputGroup select {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-right: 20px;
}

.inputGroup input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
}

.patientGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
  align-items: right;
  margin: -20px 0px ;
    overflow: scroll;
  }
  
  .patientGridContainer > * {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .payerSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px;
  }
  
  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 20px;
    border-radius: 15px;
  }
  
  .table td,
  .table th {
    border: none;
    border-bottom: 1px solid #ccc;

  }
  
  .table th {
    text-align: left;
    border-bottom: 2px solid #ccc;
    min-width: 200px;
  }
  
  .table thead {
    background-color: #f2f2f2;

  }
  
  .table tbody tr:nth-child(even) {
    background-color: #1874bf09;
  }
  
  .patientNotFoundMessage {
    color: red;
    font-weight: bold;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

  }
  .payerErrorMessage {
    color: red;
    font-weight: bold;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .SingleClaimContainer {
    height: 75%;
  }

  .searchButtonContainer {
    margin: 0 auto;
  }

  .claimContainer {
    height: 100rem;
    margin-bottom: 128px;
  }
  
  .status-box {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .payerSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 300px;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 15px;
  }

  .button-spinner-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .collapsibleSection .sectionHeader {
    display: flex;
    align-items: center;
    font-size: 26px;
    justify-content: flex-start;
    width: 100%; 
  }
  
  .collapsibleSection .sectionNumber {
    font-weight: bold;
    margin-right: 5px;
  }

  .CollapsibleSection_ .sectiontitle {
    text-align: left;
    cursor: pointer;
  }

  .selectors-container {
    display: flex;
    justify-content: space-between;
  }
  
  .singleclaim{

    min-height: 400px;
    max-height: 900px;
    padding-left: 20px;   
    padding-right: 20px; 
    overflow-y: scroll;
  }

  .dependentPopup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  margin-left: 20rem;
  right: 0;
  bottom: 0;
  background: rgb(255, 255, 255);
  z-index: 9999;
  padding: 20px;
  box-sizing: border-box;
}

.codesTable {
cursor:alias;
}

.errors2Section{
display: flex;
flex-direction: column;

}

.error-text {
margin: 1rem;
display: block;
}

.buttons-container {
display: flex;
justify-content: space-between;
align-items: center;
}

.submit-button {
width: 200px;
height: 40px;
background-color: green;
color: white;
border: none;
border-radius: 10px;
font-size: 16px;
cursor: pointer;
}

.cancelButton {
border-radius: 10px;
font-size: 16px;
cursor: pointer;
display: flex;
margin-right: 0px;
text-align: center;
box-sizing: border-box;
position: absolute;
top: 10px;
left: 10px;
}


#SingleClaimButton {
padding: 10px;
border-radius: 10px;
border: none;
background-image: linear-gradient(135deg, #00316f, #0147DF);
color: #fff;
font-weight: bold;
cursor: pointer;
box-shadow: 0px 1px 5px 0px #888888;
min-width: 2000px;
}

.padded-top {
  margin-top: 32px; /* adjust as needed */
}

.selected-mode {
  background-color: #0147DF; /* Bootstrap primary blue, change as fits your design */
  color: white;
  border-color: #0147DF;
}

.field-selection {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.fields-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.batch-date {
  font-size: 14px;
  color: #7c7c7c;
}

@media (max-width: 768px) {
  .calendarContainer {
    padding: 1rem;
    margin: 0;
  }
  .showByDropdown {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .showByDropdown select {
    width: 100%;
  }
  .rbc-calendar {
    font-size: 12px; /* Smaller font for mobile */
  }
  .rbc-toolbar {
    flex-wrap: wrap;
    gap: 0.5rem;
    text-align: center;
  }
  .rbc-event {
    font-size: 10px; /* Smaller font for event titles */
    padding: 2px;
  }
  .calendarModal {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: none;
    padding: 1rem;
  }
  button.filesCloseButton {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}

.diagnosis-code-selector {
}