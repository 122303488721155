/* Global file browser container */
.file-browser {
    padding: 20px;
    position: relative;
    margin-top: 2.75rem;
  }
  
  /* Grid layout for files and folders */
  .finder-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Wider for better visibility */
    gap: 15px;
    padding: 15px;
  }
  
  /* Finder-style item (file or folder) */
  .finder-item {
    text-align: center;
    cursor: pointer;
    padding: 12px;
    border-radius: 8px;
    transition: transform 0.2s, background 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  /* Hover effect */
  .finder-item:hover {
    background: #f0f0f0;
    transform: scale(1.05);
  }
  
  /* Selected file/folder */
  .finder-item.selected {
    background: #007bff;
    color: white;
    transform: scale(1.08);
  }
  
  /* Icon above text */
  .finder-item span {
    margin-top: 6px;
    font-size: 14px;
    font-weight: bold;
     white-space: normal;
    word-break: break-word;
    overflow-wrap: break-word;
  }
  
  /* Floating context menu */
  .context-menu {
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    padding: 13.6px;
    padding-right: 13.6px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
    border-radius: 22.3px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    padding-right: 30px;
  }
  
  /* Context menu buttons */
  .context-menu button {
    padding: 8px 12px;
    cursor: pointer;
    text-align: left;
    width: 100%;
  }
  
  /* Context menu hover */
  .context-menu button:hover {
    background: #f5f5f5;
  }
  
  /* Back button styling */
  .back-button {
    background: none;
    border: none;
    color: blue;
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 1.2em;
    display: flex;
    align-items: center;
  }
  
  /* Floating action bar */
  .action-bar {
    display: flex;
    gap: 10px;
    padding: 10px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom: 1px solid #ddd;
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    border-radius: 8px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  /* Action bar buttons */
  .action-bar button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px 15px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .action-bar button:hover {
    background: #0056b3;
  }
  
  /* Folder list (only for directory navigation) */
  .folder-list {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px 0;
  }
  
  /* Folder item */
  .folder-item {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background: #f8f8f8;
    border-radius: 5px;
    transition: background 0.2s;
  }
  
  .folder-item:hover {
    background: #ddd;
  }
  
  /* File item */
  .file-item {
    display: flex;
    align-items: center;
    padding: 10px;
    justify-content: space-between;
  }
  
  .file-item a {
    margin-right: auto;
    text-decoration: none;
    color: black;
  }
  
  /* Delete button */
  .delete-button {
    background: none;
    border: none;
    color: red;
    cursor: pointer;
    font-size: 14px;
  }
  
  .delete-button:hover {
    color: darkred;
  }
  
  /* Folder modal */
  .finder-modal {
    position: fixed;
    top: 10%;
    left: 10%;
    width: 80%;
    height: 80%;
    background: white;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    overflow-y: auto;
    border-radius: 10px;
  }
  