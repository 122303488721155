.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(148, 232, 243);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

:root {
  --color-background: #ffffff;
  --color-text: #000000;
  /* Add other variables as needed */
}

.dark-mode {
  --color-background: #1c1c1c;
  --color-text: #f1f1f1;
  /* Override other variables for dark mode */
}

/******** Global Styles *********/

/** Margin & Padding **/
.m-0 {
  margin: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.px-2 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.px-3 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.px-4 {
  padding-left: 64px !important;
  padding-right: 64px !important;
}

.px-5 {
  padding-left: 128px !important;
  padding-right: 128px !important;
}

.py-0 {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

.py-2 {
  padding-bottom: 8px !important;
  padding-top: 8px !important;
}


.pl-0 {
  padding-left: 0px !important;
}

.pl-1 {
  padding-left: 2px;
}

.pl-2 {
  padding-left: 4px;
}

.pl-3 {
  padding-left: 8px;
}

.pl-4 {
  padding-left: 16px;
}

.pl-5 {
  padding-left: 32px;
}


.pr-0 {
  padding-right: 0px !important;
}

.pr-1 {
  padding-right: 2px;
}

.pr-2 {
  padding-right: 4px;
}

.pr-3 {
  padding-right: 8px;
}

.pr-4 {
  padding-right: 16px;
}

.pr-5 {
  padding-right: 32px;
}


.pt-0 {
  padding-top: 0px !important;
}

.pt-1 {
  padding-top: 2px;
}

.pt-2 {
  padding-top: 4px;
}

.pt-3 {
  padding-top: 8px;
}

.pt-4 {
  padding-top: 16px;
}

.pt-5 {
  padding-top: 32px;
}

.pb-0 {
  padding-bottom: 0px !important;
}

.pb-1 {
  padding-bottom: 2px !important;
}

.pb-2 {
  padding-bottom: 4px !important;
}

.pb-3 {
  padding-bottom: 8px !important;
}

.pb-4 {
  padding-bottom: 16px !important;
}

.pb-5 {
  padding-bottom: 32px !important;
}

.m-3 {
  margin: 8px;
}

.mt-3 {
  margin-top: 8px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 2px;
}

.mb-2 {
  margin-bottom: 4px;
}

.mb-3 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px;
}

.mb-5 {
  margin-bottom: 32px;
}

.mr-0 {
  margin-right: 0;
}

.mr-1 {
  margin-right: 2px;
}

.mr-2 {
  margin-right: 4px;
}

.mr-3 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px;
}

.mr-5 {
  margin-right: 32px;
}

.ml-1 {
  margin-left: 2px;
}

.ml-2 {
  margin-left: 4px;
}

.ml-3 {
  margin-left: 8px;
}

.ml-4 {
  margin-left: 16px;
}

.ml-5 {
  margin-left: 32px;
}

.my-3 {
  margin-top: 8px;
  margin-bottom: 8px;
}

.my-4 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.my-5 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 2px;
}

.mb-2 {
  margin-bottom: 4px;
}

.mb-3 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 16px !important;
}

.mb-5 {
  margin-bottom: 32px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mx-4 {
  margin-left: 16px;
  margin-right: 16px;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.py-3 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.py-4 {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
}

.py-5 {
  padding-top: 64px !important;
  padding-bottom: 64px !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 16px !important;
}

.mt-4 {
  margin-top: 32px !important;
}

.mt-5 {
  margin-top: 64px !important;
}

.mb-4 {
  margin-bottom: 32px !important;
}

.mt-5 {
  padding: 64px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 2px;
}

.p-2 {
  padding: 4px;
}

.p-3 {
  padding: 8px;
}

.p-4 {
  padding: 32px !important;
}

.p-5 {
  padding: 64px !important;
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

/** Contatiners **/
/* .container {
  margin-left: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 200px);
  position: relative;
} */

.flex-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.w-100 {
  width: 100%;
}

.w-90 {
  width: 90%;
}

.h-100 {
  height: 100%;
}

.vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-center {
  display: flex;
  justify-content: center;
}

.content-center h5 {
  margin: 4px;
  text-align: center;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-grid {
  display: grid;
}

.display-inline {
  display: inline;
}

/** Buttons **/

button:hover {
  background-color: #0147df;
}

button {
  background: none;
  border: none;
  cursor: pointer;
  color: #f1f1f1;
  background-color: #00316f;
  margin-left: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.primary {
  background: none;
  border: none;
  cursor: pointer;
  color: #f1f1f1;
  background-color: #00316f;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primary:hover {
  background-color: #0147df;
}

.secondary {
  background: none;
  border: none;
  cursor: pointer;
  color: #00316f;
  background-color: #f1f1f1;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.secondary:hover {
  background-color: #0147df;
  color: #f1f1f1;
}

.link {
  background: none;
  border: none;
  cursor: pointer;
  color: #00316f;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.link:hover {
  color: #0147df;
}

.delete {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.3);
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete:hover {
  color: rgba(255, 0, 0, 0.7);
}

.danger {
  background: none;
  border: none;
  cursor: pointer;
  background-color: rgba(255, 0, 0, 0.7);
  color: #fff;
  margin-left: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.danger:hover {
  background-color: rgba(255, 0, 0, 0.3);
}

.deleteText {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  font-weight: 400px;
  margin: 10px;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.preview-text {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.1) !important;
  top: -7px;
  cursor: grab
}

.filesCloseButton {
  position: sticky;
  top: 0px; /* Sticks 10px below the top of the container */
  left: 0; /* Aligns to the left edge of the container */
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%; /* Makes it circular */
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 4;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  margin: 0px;
}

.closeButtonContainer {
  position: sticky; /* Ensures sticky positioning */
  overflow-y: auto; /* Allows scrolling */
  padding: 10px;
  background: #fff;
  backdrop-filter: blur(20px); /* Adds the blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari compatibility */
  top: 0px;
  z-index: 9;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Optional: Adds a subtle shadow */
  display: flex;
  flex-direction: row;
}

.closeBarNav {
  font-size: 22px;
  padding: 0px;
  margin-top: 2px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.filesCloseButtonFixed {
  position: fixed;
  top: 54px;
  left: calc(33.5vw + 10px);
  width: 24px;
  height: 24px;
  background-color: #ccc;
  border: none;
  border-radius: 50%;
  color: white;
  font-size: 14px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1001;
}


.filesCloseButton:hover {
  background-color: rgb(0, 49, 111);
  color: #f1f1f1;
  /* background-color: #999; */
}

/** Annomations **/
.slide-in {
  animation: slideIn 0.5s ease forwards;
  position: relative;
  z-index: 0;
}

/** Containers **/
.cancelButton {
  /* position: inherit; */
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  margin-right: 0px;
  text-align: center;
  box-sizing: border-box;
  top: 10px;
  left: 10px;
}

.grid-container-sm {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.grid-container-md {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.grid-container-lg {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  /* adjust as necessary for spacing between cards */
  padding-top: 1rem;
  padding-bottom: 1rem;
}

/* Tablet view: Three column layout */
@media (max-width: 1200px) {

  /* 1200px is a common breakpoint for tablets. Adjust as needed. */
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Mobile view: Single column layout */
@media (max-width: 830px) {
  /* 768px is a common breakpoint for mobile devices. Adjust as needed. */
  .grid-container {
    grid-template-columns: 1fr;
  }

  .grid-container-md {
    grid-template-columns: 1fr;
    gap: 16px;
  }

  .main-form {
    width: 95vw !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .main-form .input[type="date"] {
    width: calc(85% - 20px) !important;
    position: relative;
    right: 9px;
  }

  .main-form input {
    width: 85% !important;
  }

  .main-form .input-field {
    width: 85% !important;
  }

  .main-form .gender-buttons {
    width: 85% !important;
  }

  /* .main-form .stateSelector {
    width: 100% !important;

  } */
}

/** Text **/

.error-text {
  color: rgba(255, 0, 0, 0.7);
}

/** Cards **/

.primary-card {
  background-color: #00316f;
  padding: 8px;
  margin: 8px;
  border-radius: 8px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-card:hover {
  background-color: #0147df;
}

.white-card {
  background-color: rgb(243, 243, 245);
  box-shadow: 0px 1px 5px 0px #d3d3d3;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: transform 0.5s ease-in-out;
}

.white-card:hover {
  background-color: #00316f;
  color: rgb(243, 243, 245);
  transform: scale(1.02);
}

.grey-card {
  background-color: rgb(243, 243, 245);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.blue-card {
  background-color: #00316f;
  color: rgb(243, 243, 245);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.info-card {
  width: 520px;
  align-content: center;
  background: #f1f1f1;
  margin: auto;
  border-radius: 10px;
  padding-top: 15px;
  margin-bottom: 20px;
  text-align: center;
}

.main-form {
  width: 61.803vw;
  margin: auto;
  /* margin-right: auto; */
  background-color: #fefefe;
  padding-left: 5rem;
  padding-right: 5rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  border-radius: 20px;
  /* box-shadow: 0px 0px 25px 0px #888888a4; */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.main-form .payerFaintHeart {
  display: none !important;
}

.Card {
  background-color: #00316f;
  /* This is a shade of blue */
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  margin: 10px 0;
  border-radius: 20px;
  color: white;
  /* Makes the text inside the card white */
  cursor: pointer;
  /* Indicates to users that the card is clickable */
  transition: background-color 0.3s;
  /* Smooth transition for hover effect */
}

.card {
  transition: transform 0.2s ease-in-out;
}

.card-selected {
  transform: scale(1.05);
  background: linear-gradient(135deg, #00316f, #0147df);
}

.add-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  margin-top: 3rem;
  border-radius: 0;
  height: 95%;
}

.over-add-popup {
  position: absolute;
  top: -3rem;
  z-index: 99999999999;
  background: white;
  height: 100%;
}

.add-form {
  border-radius: 0px;
  justify-content: center;
  padding: 3rem;
  width: 100%;
  height: 100%;
  bottom: 0;
}

.input-field {
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
}

h1 {
  text-align: center;
  color: #0c0c0c;
  font-size: 56px;
  font-weight: 600;
  margin-top: 0rem;
  margin-bottom: 0rem;
}

h2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.125;
  letter-spacing: 0.004em;
  margin-top: 10px;
  margin-bottom: 10px;
}

h3 {
  font-size: 28px;
  opacity: 90%;
  font-weight: 100;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

h4 {
  font-size: 18px;
  opacity: 90%;
  font-weight: 100;
  margin-top: 4px;
  margin-bottom: 4px;
}

p {
  font-size: 18px;
}

/* Custom checkbox style */
.custom-checkbox {
  position: relative;
  min-width: 1rem;
  width: 1.75rem;
  height: 1.75rem;
  cursor: pointer;
  margin-bottom: -0.5rem;
  margin-top: 1rem;
  padding-top: 1rem;
  padding-bottom: 0rem;
  /* Override -webkit-appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Apply gradient effect when pressed */
  background: linear-gradient(to bottom, #e6e6e6 0%, #f9f9f9 100%);
  border-radius: 10px;
  border: 1px solid #ccc;
}

.custom-checkbox:checked {
  background: linear-gradient(135deg, #00316f, #0147df);
}

/* Add a checkmark when the checkbox is checked */
.custom-checkbox:checked::before {
  content: "\2713";
  position: absolute;
  top: 3px;
  left: 5px;
  font-size: 12px;
  color: #ffffff;
}

/* Hide the default checkbox */
.custom-checkbox::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* Add a custom focus style */
.custom-checkbox:focus {
  outline: none;
  box-shadow: 0 0 0 2px #6c9eeb;
  /* Adjust color as needed */
}

.popupContainer {
  position: fixed;
  top: 44px;
  right: 0;
  height: calc(100% - 44px);
  width: 66.7vw;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  background-color: white;
  z-index: 4;
  overflow-x: hidden;
  overflow-y: scroll;
}

@media (max-width: 708px) {
  .popupContainer {
    width: 100vw;

  }

  .popupContentAddPatient {
    width: 100vw;
  }
}

.popupContent {
  height: 100%;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: white;
  z-index: 4;
  /* overflow-y: scroll;
  overflow-x: hidden; */
  /* width: 100%; */
  /* padding-left: 1rem;
  padding-right: 1rem; */
  padding-top: 3rem;
  padding-bottom: 2rem;
}

.topBar {
  margin-bottom: 45px;
  padding-top: 1rem;
  margin-left: 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* Align items to the start of the container */
  gap: 5px;
  /* Adjust the space between items */
}

.editText {
  color: #00316f;
  cursor: pointer;
  font-size: 20px;
}

.downloadText {
  color: #00316f;
  cursor: pointer;
  font-size: 20px;
  margin-left: 1rem;
}

table {
  width: 100%;
}

.selected {
  background: linear-gradient(135deg, #00316f, #0147df);
  color: #ffffff;
}

.stepsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.dropbox {
  width: 100%;
  max-width: 500px;
  height: 315px;
  border-radius: 10px;
  border: 2px dashed #00316f;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  color: #00316f;
  font-weight: bold;
  padding: 1rem;
  flex-direction: column;
}

.dropbox:hover {
  background-color: #f2f2f2;
}

.dropbox.dragover {
  background-color: #e6e6e6;
}

input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding: 10px;
  border-radius: 10px;
  border: none;
  box-shadow: 0px 0px 5px 0px #888888;
  min-width: 8rem;
  -webkit-appearance: none;
  font-size: 16px;
}

.results {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  z-index: 101;
  color: black;
  top: 3rem;
  left: 5rem;
  right: 5rem;
  overflow-y: scroll;
}

.resultsContainer {
  overflow-y: scroll;
  width: 90%;
  height: 80%;
  background-color: rgb(221, 212, 233);
  z-index: 101;
  padding: 20px;
  padding-top: 4rem;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

.errorContainer {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 500px;
  z-index: 1001;
  background-color: #fff;
}

.collapsibleSection .sectionHeader {
  display: flex;
  align-items: center;
  font-size: 26px;
}

.collapsibleSection .sectionNumber {
  font-weight: bold;
  margin-right: 5px;
}

.collapsibleSection .sectionTitle {
  flex-grow: 1;
  cursor: pointer;
}

#templateButton {
  margin-top: 18px;
  left: 42%;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #00316f;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 1px 5px 0px #888888;
  min-width: 200px;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.button-spacing {
  width: 20px;
  height: 20px;
}

.error-table-container {
  max-height: 200px;
  overflow-y: auto;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

table {
  border-collapse: collapse;
}

.payerContainer {
  display: fixed;
  width: auto;
  min-width: 300px;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  background-image: linear-gradient(135deg, #00316f, #0147df);
  border-radius: 20px;
  color: white;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  max-height: 20rem;
  text-align: center;
  margin: auto;
  padding: 20px;
  background: linear-gradient(to right, #00316f, #0147df);
}

#payerTable {
  height: 80px;
}

.payerSearch {
  /* width: 61.803398874%; */
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.stateSelector {
  width: 38.196601126%;
}

.stateSelector select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background: #f2fcfe;
  min-width: 155px;
  position: relative;
  right: -5px;
}

.grecaptcha-badge {
  visibility: hidden;
}

.w-400px {
  /* width: 520px; */
  width: 400px;
}

.w-520px {
  width: 520px;
  margin: auto;
}

.w-540px {
  width: 540px;
  margin: auto;
}

textarea {
  margin-left: 10px;
  margin-right: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(55, 55, 55, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999 !important;
}

.modalBox {
  background-color: #f1f1f1;
  padding-top: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  min-height: 61vh;
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 16px;
  max-width: 80vw;
  min-width: 75vw;
  z-index: 9999999999 !important;
  ;
}

.modalContainer {
  padding: 4px 32px 0px 32px;
  border-radius: 8px;
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.clear {
  background: #ffffffff;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #0147df;
}

input:checked+.slider:before {
  transform: translateX(26px);
}

.switchTestModeContainer {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  align-items: center;
  padding-left: 0rem;
}


.confirmModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  outline: none;
  width: 80%;
  max-width: 50vw;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 16px;
  max-height: 61.8033vh;
  overflow-y: auto;
  z-index: 999;
}

.calendarModal {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  outline: none;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  max-height: 34vh;
  overflow-y: auto;
  z-index: 999;
}

.collapsed {
  display: none !important;
}

.subheader-container {
  text-align: center;
  padding-left: 2rem;
  padding-right: 2rem;
}

.signature-canvas-form-white {
  color: #fff;
}

.formLine {
  border: 0;
}

.colorInput {
  width: 100%;
  height: 50px;
}

#dashboardContent {
  margin-bottom: 200px;
}

.SideContainer {
  display: flex;
  align-items: center;
}

.SidebarButton {
  position: relative;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  /* padding: 5px; */
  transition: width 0.3s ease;
  overflow: hidden;
  width: 36px;
  height: 38px;
  margin-right: 10px;
}

.SidebarButton.expanded {
  width: 90px;
  position: relative;
}

.SidebarButton .hover-text {
  position: absolute;
  left: 34px;
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none;
}

.SidebarButton.expanded .hover-text {
  opacity: 1;
}

.SidebarButton svg {
  position: absolute;
  left: 9px;
}

.TrashButton {
  color: #f1f1f1;
  background-color: #ff0000;
}

.TrashButton:hover {
  color: #ff0000;
  color: #f1f1f1;
}

.ExpandableButton {
  color: #f1f1f1;
  background-color: #00316f;
}

.ExpandableButton:hover {
  color: #f1f1f1;
  background-color: #0147df;
}

.item {
  margin-right: 10px;
}

.stickyContainer {
  position: sticky;
  top: 0;
  z-index: 1;
  /* Optional: Ensure the sticky element stays on top */
  padding: 0rem;
  /* Optional: Add some padding */
  background: linear-gradient(to right, #00316f, #0147df);
  border-bottom: 1px solid #ddd; /* Optional: Add a border for visual separation */
}

.RecoverPatientsButton {
  background-color: red;
  border-radius: 20px;
}

.DeletePatientsButton{
  background-color: red;
  background: red;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.editRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.small-spinner {
  position: relative; /* Ensure it's positioned relative to the container */
  width: 20px; /* Increased size for better visibility */
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5; /* Keep it above other elements */
  background-color: rgba(255, 255, 255, 0); /* Optional: Make it debug-friendly */
}

.small-spinner-circle {
  width: 16px; /* Match the container size with slight padding */
  height: 16px;
  border: 5px solid transparent;
  border-top-color: #0147df; /* Primary color */
  border-radius: 50%;
  animation: small-spin 0.8s linear infinite;
  display: block; /* Ensure it's displayed */
}

@keyframes small-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loaderContainerRouter {
  display: flex;
  align-items: center;
  justify-content: center;
}