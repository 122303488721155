.container {
  height: 100%;
  width: fit-content;
  margin-top: 300px;
}

.sidebar {
  width: 100%;
  height: 100%;
  padding: 20px;
  background-color: #f1f1f1;
}

.content {
  flex-grow: 1;
  height: 100%;
  padding: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.patient-view {
  width: 70%;
  height: 100%;
  margin-left: 20px;
  padding: 20px;
  background-color: #fff;
  backdrop-filter: blur(5px);
}

.collapsibleContainer {
  flex-grow: 1;
  overflow-y: auto;
  width: 100%;
}


.patientInfocontainer {
  padding: 1rem;
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  top: 0;
}


thead {
  background-color: #f1f1f1;
}

th,
td {
  padding: 10px;
}


.resultsContainer {
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 20px;
  background-color: #f5f5f5;
  overflow-y: scroll;

}

.patientGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}

.patientDataPopupContainer {
  position: fixed;
  width: 80%;
  height: auto;
  padding: 40px;
  margin: 20px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.patientDataPopupTitle {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.patientDataPopupRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.patientDataPopupLabel {
  font-size: 1rem;
  font-weight: bold;
}

.patientDataPopupValue {
  font-size: 1rem;
}

.patientDataTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
  text-align: center;
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

.patientDataTitle span {
  margin-left: auto;
  margin-left: 40px;
  margin-right: 40px;
}


.actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  align-items: center;
}


.button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-container button {
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  right: 0;
}

.button-container button:hover {
  background-color: #0069d9;
}



.flexContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

}


.payerSummary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
}

.insuranceName,
.insuranceStatus {
  font-size: 24px;
  margin: 0;
  margin-right: 50px;
}

.memberId {
  font-size: 14px;
  margin: 0;

}

.topBar {
  margin-bottom: 45px;
  /* padding-left: 1rem; */
  padding-top: 1rem;
  margin-left: 0rem;
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the start of the container */
  gap: 5px; /* Adjust the space between items */
}

.editText {
  color: #00316f;
  cursor: pointer;
  font-size: 20px;
}

.downloadText {
  color: #00316f;
  cursor: pointer;
  font-size: 20px;
  margin-left: 1rem;
}

.downloadOptions {
  position: absolute;
  background: #fff;
  border: 1px solid #ccc;
  padding: 10px;
  width: 100px;
  z-index: 100;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.downloadOptions button {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  cursor: pointer;
}

.deleteButtonContainer {
  position: fixed;
  bottom: 0;
  width: 67%;
  height: 50px;
  right: 0;
  background-color: white;
  display: flex;
  justify-content: space-between;
  transition: transform 0.3s;
  z-index: 600;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.doneText {
  color: #00316f;
  cursor: pointer;
  font-size: 20px;
  font-weight: 100px;
  margin: 10px;
}


.slideOut {
  transform: translateY(100%);
}

.slideIn {
  transform: translateY(0);
}

.collapsible-section {
  border-bottom: 1px solid #ccc;
  margin-bottom: 16px;
  padding-bottom: 16px;
}

.collapsible-section:last-of-type {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.opacity-75 {
  opacity: .75;
}

.opacity-100 {
  opacity: 1;
}

.gender-buttons {
  display: flex;
  gap: 5px;
}

.gender-button {
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.gender-button.selected {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
}

.closeButton {
  background-color: #dc3545;
  color: #fff;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  left: 10px;
}

.closeButton:hover {
  background-color: #c82333;
}

.filesContainer {
  position: flex;
  width: 80%;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
}


.files {
  margin-bottom: 20px;
}

.files h2 {
  margin-bottom: 10px;
}

.files ul {
  list-style-type: none;
  padding: 0;
}

.files li {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropboxDragover {
  background-color: #e6e6e6;
}

.uploadForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.uploadForm input[type="file"] {
  margin-bottom: 10px;
}


.zoomcontent {
  padding: 60px;
  overflow-y: auto;
  position: flex;
  height: fit-content;
}

.zoomInput {
  display: block;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-size: 16px;
  max-width: 200px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.zoomInput:focus {
  outline: none;
  border-color: #4caf50;
}

.zoomcontent .zoomInput {
  width: 100%;
}

.input-group-row label {
  margin-bottom: 10px;
}

.input-group-row label {
  font-size: 0.8rem;
  color: #888;
}

.input-group label {
  font-size: 0.8rem;
  color: #888;
  margin-left: .5rem;
}

.input-group textarea {
  height: 15%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: vertical;
  font-size: 18px;
  box-shadow: 0px 0px 5px 0px #888888;

}

.deleteNoteButton {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.3);
  margin-left: 10px;
  box-shadow: none;
}

.deleteNoteButton:hover {
  color: rgba(255, 0, 0, 0.7);
}

.noteItem {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.noteContent {
  flex: 1;
  margin-right: 10px;
  padding: 10px;
}

.fileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.deleteFileButton {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.3);
  box-shadow: none;
}

.deleteFileButton:hover {
  color: rgba(255, 0, 0, 0.7);
}

/* styles.css */
.contentContainer {
  padding: 4px 32px 128px 32px;
  border-radius: 8px;
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .contentContainer {
  padding: 4px;
  border-radius: 8px;
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  } 
}

/* .default-text {
  color: transparent;
  border: none;
  background-color: transparent;
  outline: none;
  resize: none;
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  overflow: hidden;
} */

.claimsButtonsLeft {
  /* This container will hold the text and will take the needed space */
  flex-grow: 1; /* Allows the container to grow and take up any available space */
}

.claimsButtonsRight {
  /* This container will hold the action buttons */
  display: flex; /* Aligns the buttons in a row */
  flex-direction: column; /* Stacks the buttons vertically */
  align-items: flex-end; /* Aligns the buttons to the end (right) of the flex container */
  width: 15rem;
}

.claimsTopView {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.transparentTable {
  background-color: transparent; /* This makes the background transparent */
  /* Add any additional styling for the table here */
}

.patientViewCard {
  background-color: #00316f;  /* This is a shade of blue */
  padding-top: 1px;
  padding-bottom: .01rem;
  padding-left: .5rem;
  padding-right: .5rem;
  margin: 10px 0;
  border-radius: 10px;
  max-width: 40rem;
  color: white; /* Makes the text inside the card white */
  cursor: pointer; /* Indicates to users that the card is clickable */
  transition: background-color 0.3s; /* Smooth transition for hover effect */
}

.medicalHistoryContainer {
  padding: 4px 32px 0px 32px;
  border-radius: 8px;
  max-width: 650px;
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.medicalHistoryHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CountBadge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0 5px;
  font-size: 1rem;
  position: absolute;
  margin-left: 9rem;
  margin-bottom: 2.5rem;
}


/* Dropdown Container */
.flagContainer {
  position: fixed;
  margin-top: rem;
  margin-left: 2rem;
}

/* Dropdown Menu */
.flagDropdown {
  list-style-type: none;
  padding: 0;
  margin-top: 3rem;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  width: 200px; /* Adjust width as needed */
  z-index: 1000; /* Ensure dropdown appears above other content */
}

/* Dropdown Items */
.flagDropdown li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  gap: .5rem;
  align-items: center; /* Aligns icon and text */
}

.flagDropdown li:not(:last-child) {
  border-bottom: 1px solid #eee;
}

.flagDropdown li:hover {
  background-color: #f7f7f7;
}

/* Icon Style */
.flagDropdown li .svg-inline--fa {
  margin-right: 8px;
}

.fileImagePreview {
  max-width: 75px; /* Adjust the maximum width as needed */
  max-height: 75px; /* Adjust the maximum height as needed */
  object-fit: contain; /* This will ensure the image keeps its aspect ratio */
  margin-right: 10px; /* Add some space between the image and the file name */
  border-radius: 5px; /* Optional: for rounded corners */
}

/* You may also want to style the PDF preview icon */
.filePdfPreview {
  font-size: 24px; /* Adjust the size of the PDF icon */
  margin-right: 10px; /* Space between the icon and file name */
}

.filesLayout {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
  gap: 2rem;
}

.filesList {
  flex: 1; /* Adjust as needed */
  align-items: left;
  justify-content: left;
}

.uploadBox {
  flex: 1; /* Adjust as needed */
  text-align: center;
}

/* Inside PatientView.module.css */

.card-color-FamilyHistory {
  background-image: linear-gradient(135deg, #F0A094, #f0a09469);
}

.card-color-ImmunizationsAllergies {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
}

.card-color-LabAndTest {
  background-image: linear-gradient(135deg, #EBD63D, #ebd73d8a);
}

.card-color-Medications {
  background-image: linear-gradient(135deg, #eb2222, #eb222298);
}

.card-color-Vitals {
  background-image: linear-gradient(135deg, #0147DF, #0147df57);
}

.card-color-PatientHistory {
  background-image: linear-gradient(135deg, #00316f, #0147DF);
}

/* Files Layout */
.filesLayout {
  display: flex;
  margin-top: 3rem;
  justify-content: space-between;
  gap: 2rem;
}

/* Upload Box */
.uploadBox {
  flex: 1;
}

/* Files List */
.filesList {
  flex: 1;
}

/* Dropbox */
.dropbox {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}

.dropbox:hover {
  background-color: #f9f9f9;
}

.dropboxDragover {
  background-color: #e6e6e6;
}

/* Upload Button */
.uploadButton {
  padding: 0.75rem 1.5rem;
  background-image: linear-gradient(135deg, #00316f, #0147DF);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.uploadButton:hover {
  background-image: linear-gradient(135deg, #0147DF, #00316f);
}

/* Camera Button */
.cameraButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-image: linear-gradient(135deg, #28a745, #218838);
  color: #fff;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.cameraButton:hover {
  background-image: linear-gradient(135deg, #218838, #28a745);
}

/* File Item */
.fileItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.fileContent {
  display: flex;
  align-items: center;
}

.fileImagePreview {
  max-width: 75px;
  max-height: 75px;
  object-fit: contain;
  margin-right: 10px;
  border-radius: 5px;
}

.filePdfPreview {
  font-size: 24px;
  margin-right: 10px;
}

/* Delete Button */
.deleteFileButton {
  background: none;
  border: none;
  cursor: pointer;
  color: rgba(255, 0, 0, 0.3);
}

.deleteFileButton:hover {
  color: rgba(255, 0, 0, 0.7);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .filesLayout {
    flex-direction: column;
  }

  .uploadBox,
  .filesList {
    width: 100%;
  }
}

.uploadFileButton {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  color: white;
  background-color: #0147DF;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
}

.uploadFileButton:hover {
  background-color: #0056b3;
}


.editButtonContainer {
  text-align: left;
  margin-top: 0px;
  margin-bottom: 20px;
}

.editButton {
  background-color: #007bff00;
  color:#0147DF;
  font-weight: 100;
  border: none;
  font-size: 16px;
  box-shadow: none;
  cursor: pointer;
  padding: 0px;
  margin: 0px;
}

/* Patient View Eligibility Button */
.patientViewEligibilityButton {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  border: 2px solid #007bff;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.patientViewEligibilityButton:disabled {
  background-color: #0056b3; /* Darker blue for disabled button */
  color: #aaa; /* Gray text for disabled state */
  cursor: not-allowed;
}

/* Tooltip container */
.patientViewEligibilityButton[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 100%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 15px;
  font-size: 18px; /* Larger font size */
  border-radius: 6px;
  white-space: nowrap;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease;
}

/* Show tooltip on hover */
.patientViewEligibilityButton:hover[data-tooltip]::after {
  opacity: 1;
}
