.background {
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.eligibilitySearchContainer {
  align-items: center;
   top: 0;
   background-color: white;
   overflow-y: auto;
   margin-top: 50px;
   margin-left: auto;
   margin-right: auto;
}

.container {
  display: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 100vh;
  position: relative;
  overflow: auto; /* add overflow property with auto value */
}

.authDetails {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}


.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: top;
  height: 80vh;
}

.quickSearchForm {
  flex-direction: column;
  padding-left: 2rem;
  padding-right: 2rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin-bottom: 3rem;
}

.inputGroup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.inputGroup input[type="text"] {
  padding: 10px;
  margin: 0 10px;
  font-size: 16px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 10px;
}

button[type="quick"] {
  padding: 10px;
  margin: 20px 0;
  font-size: 16px;
  border-radius: 10px;
  background-color: #2c3e50;
  color: #fff;
  cursor: pointer;
  width: 30%;
}

button[type="quick"]:hover {
  background-color: #4e6e8d;
}

.patientInfo {
  display: flex;
  justify-content: space-between;
}

.patientInfo > div {
  flex-basis: 48%;
}

.genderSelect {
  display: block;
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  font-size: 16px;
  box-shadow: 0px 0px 5px 0px #888888;
}

.memberid {
  display: flex;
}

.payerSelect {
  display: block;
  width: 100%;
  height: 400px;
  padding: 10px;
  margin-right: 20px;
  margin-left: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: -15px;
  font-size: 16px;
  box-shadow: 0px 0px 5px 0px #888888;
}

.select-container {
  display: fixed; /* use flexbox to align items */
  justify-content: center; 
  
}

.select-container select {
  width: 80px; /* increase the width of the select element */
  padding: 5px; /* add some padding to the select element */
  font-size: 16px; /* increase the font size */
  border-radius: 15px;

}

.inputGroup label {
  display: block;
  margin-bottom: 5px;
  font-size: 0.8rem;
  color: #888;
}

.inputGroup input,
.inputGroup select {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
  margin-right: 20px;
}

.inputGroup input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 20px;
}

.patientGridContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 60px;
  align-items: right;
  margin-top: 60px ;
  height: 50rem;
  overflow-y: scroll;
  overflow-x: hidden;
  color: black;
  z-index: 101;
  }
  
  .patientGridContainer > * {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .payerSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-height: 500px;
    overflow-y: scroll;
    padding: 20px;
  }
  
  .table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 20px;
    border-radius: 15px;
  }
  
  .table td,
  .table th {
    border: none;
    border-bottom: 1px solid #ccc;

  }
  
  .table th {
    text-align: left;
    border-bottom: 2px solid #00316f;
    min-width: 200px;
  }
  
  .table thead {
    background-color: #00316f;

  }
  
  .table tbody tr:nth-child(even) {
    background-color: #1874bf09;
  }
  
  .patientNotFoundMessage {
    color: red;
    font-weight: bold;
    margin-top: 1rem;
    display: flex;
    justify-content: center;

  }
  .payerErrorMessage {
    color: red;
    font-weight: bold;
    margin-top: 1rem;
    display: flex;
    justify-content: center;
  }
  
  .quicksearchContainer {
    height: 50%;
  }

  .searchButtonContainer {
    margin: 0 auto;
  }
  
  .status-box {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
  }
  
  .payerSelection {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 300px;
    overflow-y: scroll;
    padding: 20px;
    border-radius: 15px;
  }

  .button-spinner-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  
  .serviceTypeDropdown {
    position: flex;

  }
  
  .dropdownInput, .searchInput {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
  }
  
  .dropdownContent {
    position: absolute;
    background-color: #f9f9f9;
    width: 35%;
    padding: 1rem;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    min-height: 10rem;    
    overflow-y: scroll;    
    max-height: 25rem;

  }
  
  .optionsContainer {
    max-height: 200px; /* Adjust height as needed */
    overflow-y: auto;
    overflow-x: hidden;
  }  

