/* Accordion.module.css */
.item {
    flex-direction: column;
    border-bottom: 1px solid var(--color-slate-200);
    height: auto;
    margin: -2.5rem;
  }
  
  .trigger {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 0;
    font-weight: 500;
    transition: all 0.3s;
    box-shadow: none;
    background: none;
    margin: 0;
    color: black;
  }
  
  .trigger:hover {
    text-decoration: underline;
  }
  
  .trigger[data-state=open] > svg {
    transform: rotate(180deg);
  }
  
  .content {
    overflow: hidden;
    font-size: 0.875rem;
    transition: all 0.3s;
  }
  
  .content div {
    padding: 0 0 1rem;
  }
  